import { useParams } from 'react-router-dom';
import SantimCancelPayment from './SantimCancelPayment';
import SantimFailedPayment from './SantimFailedPayment';
import SantimSuccessPayment from './SantimSuccessPayment';

const SantimPaymentStatus = () => {
    const { status } = useParams();

    return (
        <>
            {status === 'success' && <SantimSuccessPayment />}
            {status === 'failed' && <SantimFailedPayment />}
            {status === 'cancel' && <SantimCancelPayment />}
        </>
    );
};

export default SantimPaymentStatus;
