import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import { Accordion, AccordionDetails, Box, CircularProgress, IconButton, Typography } from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import React, { useEffect, useState, Suspense, lazy, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    checkboxsCheckSelection,
    clearAllCheckboxValues,
    setCheckboxValue,
    setCurrentPath,
    setSportbooks,
    updateboxsCheckSelection,
} from '../../../redux/actions';
import { getSportIcon, isObjectEmpty } from '../../../services/helper';
import { setTimeFilterForSportBook } from '../../../redux/SportBook/actions';
// import SidebarSubSection from './SidebarSubSection';
const SidebarSubSection = lazy(() => import('./SidebarSubSection'));

const SidebarSportbook = ({
    sportbook,
    loading,
    sportbookCheckboxValues,
    setSportbookCheckboxValues,
    clearAllCheckboxValues,
    setCurrentPath,
    sportsList,
    updatedLocation,
    updatedLeague,
    setTimeFilterForSportBook,
    filter,
    sportsBookFilter
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [expanded, setExpanded] = useState(null);
    const [tempUpDatedLocation, setTempUpDatedLocation] = useState([]);

    useEffect(() => {
        const isAnyCheckboxSelected = Object.values(sportbookCheckboxValues).some((value) => value === true);
        const hasSubitemCheckbox = Object.keys(sportbookCheckboxValues).some((item) => item.includes('subitem'));

        if (location.pathname === '/selections') {
            // Allow navigation but don't clear checkbox selections
            window.history.pushState(null, null, '/selections');
        } else if (location.pathname !== '/selections') {
            // Allow coming back to /selections and show checkbox selections
            window.history.pushState(null, null, location.pathname);
        } else if (isAnyCheckboxSelected && hasSubitemCheckbox) {
            // If any checkbox is checked, navigate to /selections
            window.history.pushState(null, null, '/selections');
        }
    }, [sportbookCheckboxValues, dispatch, navigate, setCurrentPath, location.pathname]);

    const accordianHeaderStyle = {
        display: 'flex',
        alignItems: 'center',
    };
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary expandIcon={<ChevronRightIcon />} onClick={() => setShow(!show)} {...props} />
    ))(({ theme }) => ({
        flexDirection: 'row',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
    }));

    const backToHome = () => {
        navigate('/');
    };

    const handleChange = id => (_, isExpanded) => {
        // if expanded, set id to open/expand, close it otherwise 
        setExpanded(isExpanded ? id: null);
    };

    const getLocationBySportId = (activeTabSportId) => {
        let tempLoc = updatedLocation[activeTabSportId].length > 0 ? updatedLocation[activeTabSportId]?.filter((locationDetail) => locationDetail?.sport_id.includes(activeTabSportId)) : []

        tempLoc.map((tempLocData) => {
            return tempLocData.Leagues = updatedLeague?.[activeTabSportId].filter((tempData) => tempData.location_id === tempLocData.id) || []
        })
        setTempUpDatedLocation(tempLoc)
    }

    useEffect(() => {
        if(expanded && updatedLocation && updatedLocation?.[expanded] && updatedLocation[expanded]){
          getLocationBySportId(expanded)
        }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        expanded,
        updatedLocation, 
        updatedLeague, 
        filter,
        sportsBookFilter
    ])

    
    // Check if the sportbook prop is undefined or not an array
    if (!Array.isArray(sportsList)) {
        return null; // or return some default content or loading indicator
    }
    
    const GetSportsName = ({sportName}) => useMemo(() => getSportIcon(sportName), [sportName])

    return (
        <>
            <Box
                className="SportsBookBx"
                sx={{
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    p: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography
                        sx={{
                            fontSize: '16px;',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                        }}
                    >
                        Sports Book
                        {isObjectEmpty(sportbookCheckboxValues)
                            ? `(${
                                  Object.keys(sportbookCheckboxValues).filter((item) => {
                                      if (item.includes('subitem')) {
                                          return sportbookCheckboxValues[item] === true;
                                      }
                                      return false;
                                  }).length
                              })`
                            : ''}
                    </Typography>
                    <IconButton style={{display: isObjectEmpty(sportbookCheckboxValues) ? 'unset' : 'none' }} aria-label="delete" color="black">
                        <DeleteIcon
                            onClick={() => {
                                backToHome();
                                clearAllCheckboxValues();
                                setTimeFilterForSportBook()
                            }}
                        />
                    </IconButton>

                </Stack>

                {/* content */}
                <Box className="noMrg spotBookAccor">
                    {loading ? (
                        <CircularProgress disableShrink />
                    ) : <></>}

                    {
                        sportsList && sportsList.length > 0 && sportsList?.map((sport) => (
                            <Accordion
                                TransitionProps={{unmountOnExit: true}}
                                key={sport.id}
                                onChange={handleChange(sport.id)}
                                expanded={expanded === sport.id}
                            >
                                <AccordionSummary
                                    sx={{ borderBottom: '1px solid', borderColor: 'grey.300' }}
                                    expandIcon={<ChevronRightIcon />}
                                    aria-controls={sport.name}
                                    // id="panel1a-header"
                                >
                                    <Typography className="imgMrg" style={accordianHeaderStyle}>
                                        <GetSportsName sportName={sport.name} />
                                        {sport.name}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Suspense fallback={<CircularProgress disableShrink />}>
                                        {
                                            (tempUpDatedLocation && tempUpDatedLocation.length > 0)
                                            ?
                                            <Typography>
                                                <SidebarSubSection
                                                    key={sport.id}
                                                    menuItems={tempUpDatedLocation}
                                                    sportId={sport.id}
                                                />
                                            </Typography>
                                            :
                                            <Box display="flex" justifyContent='center' ><p>No match available</p></Box>
                                        }
                                    </Suspense>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </Box>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        sportbookCheckboxValues: state.data.sportbookCheckboxValues,
        sportbook: state.data.sportbook,
        sportsList: state.sportsBook.sports,
        updatedLocation: state.sportsBook.updatedLocation,
        updatedLeague: state.sportsBook.updatedLeague,
        filter: state.data.filter,
        sportsBookFilter: state.sportsBook.filter
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setSportbook: (data) => dispatch(setSportbooks(data)),
        setSportbookCheckboxValues: (checkboxName, value) => dispatch(setCheckboxValue(checkboxName, value)),
        checkSelection: (sportbookCheckboxValues) => dispatch(checkboxsCheckSelection(sportbookCheckboxValues)),
        clearAllCheckboxValues: () => dispatch(clearAllCheckboxValues()),
        setTimeFilterForSportBook: (value) => dispatch(setTimeFilterForSportBook(value)),
        updateboxsCheckSelection: (sportbookCheckboxValues) =>
            dispatch(updateboxsCheckSelection(sportbookCheckboxValues)),
        setCurrentPath: (path) => dispatch(setCurrentPath(path)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarSportbook);
