import { Icon } from '@iconify/react';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { postData } from '../../../services/api';
import { ToastError, convertToUTC, formatDate } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';
import TicketDetails from './TicketDetails';

const MiddleTicketsTab = () => {
    const { user, systemSetting } = useContext(UserContext);
    const [tickets, setTickets] = useState([]);
    const [extra_data, setExtraData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [selectStatus, setSelectStatus] = useState('');
    const [selectType, setSelectType] = useState('');

    const defaultData = {
        items: [],
        totalOdds: 0.0,
        winningAmount: 0.0,
        betAmount: 0.0,
        vatAmount: 0.0,
        actualBetAmount: 0.0,
        status: 1,
    };
    const [modalData, setModalData] = useState(defaultData);
    const [date, setDate] = useState(['', '']);

    const Type = [
        {
            id: 1,
            label: 'Single',
        },
        {
            id: 2,
            label: 'Combi',
        },
    ];
    const Status = [
        {
            id: 0,
            label: 'Open',
        },
        {
            id: 1,
            label: 'Lost',
        },
        {
            id: 2,
            label: 'Win',
        },
        {
            id: 3,
            label: 'Refund',
        },
    ];
    const min_date = new Date();
    const minDate = dayjs(min_date);

    const fetchTickets = async (page, pageSize, date, type, status) => {
        let data = {
            user_token: user.token,
            data: {
                start_date: convertToUTC(date).start_date,
                end_date: convertToUTC(date).end_date,
                type: type,
                status: status,
            },
        };
        try {
            setLoading(true);
            const response = await postData(`/user/tickets?page=${page + 1}&size=${pageSize}`, data);
            setTickets(response.data);
            setExtraData(response.extra_data);
            setLoading(false);
        } catch (error) {
            console.log('error :>> ', error);
            ToastError(error.response.data.message);
        }
    };
    useEffect(() => {
        toast.dismiss();
        fetchTickets(page, rowsPerPage, '', '', '');
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchTickets(newPage, rowsPerPage, date, selectType, selectStatus);
    };

    const handleChangeRowsPerPage = (event) => {
        // setPage(0);
        setRowsPerPage(event.target.value);
        console.log('event.target.value', event.target.value);
        fetchTickets(page, event.target.value, date, selectType, selectStatus);
    };

    const getBetStatus = (status) => {
        let betstatus = null;
        switch (status) {
            case 2:
                betstatus = 'status-win';
                break;
            case 1:
                betstatus = 'status-lost';
                break;
            case 3:
                betstatus = 'status-cancelled';
                break;
            default:
                betstatus = 'status-open';
                break;
        }
        return betstatus;
    };

    const handleClose = () => {
        // Close the modal and reset the data
        setIsOpen(false);
        setModalData(defaultData);
    };

    const getTicketDetail = async (BetId) => {
        const pbet_uid = BetId.slice(2);
        let data = {
            user_token: user.token,
            data: {
                pbet_uid: pbet_uid,
            },
        };
        const response = await postData(`/user/ticket/detail`, data);
        setIsOpen(true);
        const ticketObject = {
            items: response.data[0].Fixtures,
            totalOdds: response.data[0].total_odds,
            winningAmount: response.data[0].win_amount,
            betAmount: response.data[0].stake_amount,
            vatAmount: response.data[0].vat_amount,
            taxAmount: response.data[0].tax_amount,
            actualBetAmount: response.data[0].total_amount,
            status: response.data[0].playerbet_status,
            incomeTaxPercentage: Number(systemSetting['Income Tax (%)']),
            vatPercentage: Number(systemSetting['VAT (%)']),
        };
        setModalData(ticketObject);
    };

    const handleDateChange = (newValue) => {
        setDate(newValue);
    };

    const onchangeStatus = (event) => {
        setSelectStatus(event.target.value);
    };
    const onchangeType = (event) => {
        setSelectType(event.target.value);
    };

    const onSearch = async () => {
        if (selectStatus !== '' || selectType !== '' || date[0] !== '' || date[1] !== '')
            fetchTickets(page, rowsPerPage, date, selectType, selectStatus);
    };

    const clearAll = () => {
        if (selectStatus !== '' || selectType !== '' || date[0] !== '' || date[1] !== '') {
            setDate(['', '']);
            setSelectStatus('');
            setSelectType('');
            fetchTickets(0, rowsPerPage, '', '', '');
        }
    };
    return (
        <>
            <TicketDetails isOpen={isOpen} onClose={handleClose} data={modalData} />
            <Box
                sx={{
                    padding: '10px',
                    minHeight: '300px',
                    paddingBottom: '20px',
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Box>
                    <Stack direction="row" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="center">
                            <ConfirmationNumberOutlinedIcon color="black" />{' '}
                            <Typography
                                color="black"
                                ml={1}
                                variant="h6"
                                sx={{
                                    fontSize: '16px;',
                                    fontWeight: 'bold',

                                    textTransform: 'uppercase',
                                }}
                            >
                                Tickets
                            </Typography>
                        </Stack>
                        <IconButton
                            onClick={() => {
                                fetchTickets(page, rowsPerPage, '', '', '');
                            }}
                        >
                            <Icon icon="jam:refresh" />
                        </IconButton>
                    </Stack>
                </Box>

                <Box mt={2}>
                    <Stack marginLeft={2} flexDirection="row" justifyContent={'space-between'}>
                        <Box width="50%">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DesktopDateRangePicker']}>
                                    <DemoItem component="DesktopDateRangePicker">
                                        <DesktopDateRangePicker
                                            localeText={{ start: 'Start Date', end: 'End Date' }}
                                            className=""
                                            size="small"
                                            maxDate={minDate}
                                            value={date}
                                            onChange={handleDateChange}
                                            calendars={1}
                                            format="DD-MM-YYYY"
                                            slotProps={{
                                                textField: {
                                                    error: false,
                                                },
                                            }}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                        <Box padding={1} sx={{ flexBasis: '30%' }} marginLeft={4} className="slectInput">
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-label">Search All Status </InputLabel>
                                <Select name="selectStatus" value={selectStatus} onChange={onchangeStatus}>
                                    <MenuItem key={''} value={' '}>
                                        Select Status
                                    </MenuItem>
                                    {Status.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box padding={1} sx={{ flexBasis: '30%' }} className="slectInput">
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-label">Search All Type </InputLabel>
                                <Select name="selectType" value={selectType} onChange={onchangeType}>
                                    <MenuItem key={''} value={' '}>
                                        Select Type
                                    </MenuItem>
                                    {Type.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Stack>
                    <Stack display="flex" flexDirection="row" justifyContent="flex-end" marginRight={2}>
                        <Box margin={2}>
                            <Button
                                onClick={clearAll}
                                color="primary"
                                variant="contained"
                                size="large"
                                disableElevation
                            >
                                Clear
                            </Button>
                        </Box>
                        <Box margin={2}>
                            <Button
                                onClick={onSearch}
                                color="primary"
                                variant="contained"
                                size="large"
                                disableElevation
                            >
                                Search
                            </Button>
                        </Box>
                    </Stack>
                    {/* table box */}

                    <Table>
                        <TableHead>
                            <TableRow sx={{ textTransform: 'uppercase' }}>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    Date
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    Bet ID
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    Bet Amount
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    Win
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    Type
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                Array.from(Array(rowsPerPage).keys()).map((index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : tickets.length > 0 ? (
                                tickets?.map((ticket) => (
                                    <TableRow
                                        key={ticket.id}
                                        onClick={() => getTicketDetail(ticket.pbet_uid)}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span> {formatDate(ticket.created_at, 'ticket datetime')}</span>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span
                                                style={{
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                #{ticket.pbet_uid}
                                            </span>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span>{ticket.total_amount} ETB</span>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span>{ticket.win_amount} ETB</span>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span>{ticket.bet_place_type === 1 ? 'SINGLE' : 'COMBI'}</span>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span className={getBetStatus(ticket.playerbet_status)}></span>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell align="center" colSpan={6}>
                                        <Typography variant="h6"> data not found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>

                {/* pagination section */}

                {tickets.length > 0 && (
                    <Box className="paginationDv" display="flex" justifyContent="center" mt={4}>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={extra_data ? extra_data.total_items : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )}

                <Box>
                    {/* <Stack></Stack> */}
                    <Stack direction="row" alignItems={'baseline'}>
                        <Typography paddingBottom={3} color="info" variant="body1" fontWeight="700" marginRight="20px">
                            Legend
                        </Typography>
                        <Typography variant="body2" marginRight="20px">
                            <span className="status-win"></span>
                            Won
                        </Typography>
                        <Typography variant="body2" marginRight="20px">
                            <span className="status-lost "></span>
                            Lost
                        </Typography>
                        <Typography variant="body2" marginRight="20px">
                            <span className="status-open "></span>
                            Open
                        </Typography>
                        <Typography variant="body2" marginRight="20px">
                            <span className="status-cancelled"></span>
                            Refund/Canceled
                        </Typography>
                        <Typography variant="body2">
                            <span className="status-half-win"></span>
                            Half-Win
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};

export default MiddleTicketsTab;
