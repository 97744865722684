import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useContext, useEffect } from 'react';
import { backToPrivious } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';
import ChapaFormWithdraw from './ChapaFormWithdraw';

const ChapaWithdraw = () => {
    const { user,updateuserBalance } = useContext(UserContext);

    useEffect(() => {
        updateuserBalance(user)
    }, [])
    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                    paddingBottom: '20px',
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Box width="100%">
                    <Stack direction="row" alignItems="center">
                        <Typography
                            color="black"
                            mr={1}
                            variant="h6"
                            sx={{
                                fontSize: '16px;',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                            }}
                        >
                            Withdraw via
                        </Typography>

                        <img src="/images/chapaInside.png" width="123" height="29" className="" alt="Deposit"></img>
                        <Button
                            color="primary"
                            onClick={() => backToPrivious()}
                            // variant="text"
                            sx={{ color: '#000', fontWeight: 600, minWidth: '40px', ml: 'auto' }}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Back
                        </Button>
                    </Stack>
                </Box>

                {/* conent start */}
                <Box className="branchList">
                    <Typography variant="body2">
                        1) Enter the amount of money you want to withdraw and click Withdarw
                    </Typography>
                    <Typography variant="body2">2) Verify the OTP received on the mobile number</Typography>
                    <Typography variant="body2">
                        3) You will be asked to enter your Account name, Account number, and choose a Bank
                    </Typography>
                </Box>

                <Paper elevation={0} variant="outlined" className="branchTable">
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Fees</TableCell>
                                <TableCell>
                                    <b>No fees applied </b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Processing time</TableCell>
                                <TableCell>
                                    <b>Within 5 minutes </b>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Minimum amount per transaction</TableCell>
                                <TableCell>
                                    <b>20.00 ETB </b>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Maximum amount per transaction</TableCell>
                                <TableCell>
                                    <b>10,000.00 ETB </b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>

                {/* form content */}

                <ChapaFormWithdraw type="withdraw" />
            </Box>
        </>
    );
};

export default ChapaWithdraw;
