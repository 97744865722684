import CancelIcon from '@mui/icons-material/Cancel';
import {
    Box,
    Button,
    Divider,
    Grid,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { formatDate, formatNumber, roundto2 } from '../../../services/helper';
const image = {
    alt: 'Logo Main',
    //height: 200, // Replace with the actual height of the image
    src: '/images/primeLogo.png', // Replace with the actual path to your image
    // width: 300, // Replace with the actual width of the image
};
const TicketPreviewModal = ({
    items,
    totalOdds,
    winngAmount,
    betAmount,
    vatAmount,
    actualBetAmount,
    user,
    taxAmount,
    systemSetting,
}) => {
    const vatPercentage = Number(systemSetting['VAT (%)']);
    const incomeTaxThreshold = Number(systemSetting['Income Tax Threshold']);
    const incomeTaxPercentage = Number(systemSetting['Income Tax (%)']);
    const minimumBetAmount = Number(systemSetting['Minimum Bet Amount']);
    const maximumBetAmount = Number(systemSetting['Maximum Bet Amount']);
    const maximumWinAmount = Number(systemSetting['Maximum Win Amount']);
    const dailyMaxBetAmount = Number(systemSetting['Daily Maximum Bet Amount']);
    const weeklyMaxBetAmount = Number(systemSetting['Weekly Maximum Bet Amount']);
    const monthlyMaxBetAmount = Number(systemSetting['Monthly Maximum Bet Amount']);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 590,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
    };

    const tableStyle = {
        border: 0,
        paddingTop: '2px',
        paddingBottom: '2px',
    };

    const getSelectedOdd = (oddSelectedId, MarketSelectedId, fixture) => {
        const oddData = fixture.hasOwnProperty('Market')
            ? fixture.Market.Odds?.find((item) => item.id === oddSelectedId)
            : fixture.Markets?.find((market) => market.id === MarketSelectedId).Odds?.find(
                  (item) => item.id === oddSelectedId
              );
        if (oddData?.name === '1') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {fixture.Participants_home?.name}
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {oddData.price?.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData?.name === 'X') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            Draw
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {oddData.price?.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData?.name === '2') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {fixture.Participants_away.name}
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {oddData.price?.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData?.name === '1X') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {fixture.Participants_home.name} or Draw
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {oddData.price?.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData?.name === 'X2') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            Draw or {fixture.Participants_away.name}
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {oddData?.price?.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData?.name === '12') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            No Draw
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {oddData?.price?.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {oddData?.name} {oddData?.line !== '' ? oddData?.line : ''}
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="20px">
                            {oddData?.price?.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        }
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const actualwining = roundto2(winngAmount) + roundto2(taxAmount);
    function TitleLogo() {
        return (
            <>
                <LazyLoadImage
                    className="maxWith100"
                    alt={image.alt}
                    width="106"
                    height="47"
                    rel="preload"
                    as="image"
                    // height={image.height}
                    src={image.src} // use normal <img> attributes as props
                    // width={image.width}
                />
                {/* <img src="/images/primeLogo.png" className="maxWith100" alt=""></img> */}
            </>
        );
    }
    return (
        <>
            <Button
                aria-label="Open Modal"
                variant="contained"
                sx={{ backgroundColor: '#D9D9D9', '& hover': { backgroundColor: '#D9D9D9' } }}
                onClick={handleOpen}
                disableElevation
                disabled={items.some((item) => item.FixtureStatus === 'non-active')}
            >
                Ticket Preview
            </Button>
            <Modal open={open} onClose={handleClose} className="bgModal">
                <Paper className="tktPrievePop" sx={style}>
                    <div className="modalContentHolder">
                        <div className="modalContentTitle">
                            <div className="ModalLogo">
                                <TitleLogo />
                            </div>
                        </div>

                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ padding: '5px 8px' }}
                            bgcolor={grey[200]}
                        >
                            <Typography variant="h6" component="h6" color="initial" textAlign="center">
                                Ticket Preview (AKO)
                            </Typography>
                            <Typography variant="body1" fontWeight="700" component="h4" color="initial">
                                Total Odds
                            </Typography>
                        </Box>
                        <div className="tktMxHeight" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {items.map((item) => (
                                <>
                                    <Box key={item.id}>
                                        <Typography
                                            variant="body2"
                                            pl="16px"
                                            pt="8px"
                                            sx={{ textTransform: 'uppercase', fontWeight: '500', fontSize: '13px' }}
                                        >
                                            {item.sport_name} - {item.location_name} - {item.league_name}
                                        </Typography>
                                    </Box>
                                    <Box pt="0px" pr="16px" pb="2px" pl="16px" sx={{}}>
                                        <Grid container spacing={2} alignItems="flex-start" wrap="nowrap">
                                            <Grid item>
                                                <Typography variant="body2" fontWeight="600">
                                                    {item.id}
                                                </Typography>
                                            </Grid>
                                            <Grid item flexGrow="2" md={5}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ fontWeight: '600', textTransform: 'uppercase' }}
                                                >
                                                    {item.Participants_home.name} - {item.Participants_away.name}
                                                </Typography>
                                                <Typography variant="body1" fontSize="13px">
                                                    {item.hasOwnProperty('Market')
                                                        ? 'Winner'
                                                        : item.Markets.find(
                                                              (market) => market.id === item.MarketSelectedId
                                                          ).name === '1X2'
                                                        ? '1X2 (Match Results)'
                                                        : item.Markets.find(
                                                              (market) => market.id === item.MarketSelectedId
                                                          ).name}
                                                </Typography>
                                                <Box display="flex">
                                                    <Typography fontSize="13px">Start at </Typography>
                                                    <Typography fontSize="13px" ml="10px" fontWeight="500">
                                                        {formatDate(item.start_date, 'ticketpreview')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {getSelectedOdd(item.oddSelectedId, item.MarketSelectedId, item)}
                                        </Grid>
                                    </Box>
                                </>
                            ))}
                        </div>
                        <Divider sx={{ marginTop: '10px' }} />

                        <Box margin={2} mt="5px">
                            <TableContainer container component="Box" margin={2}>
                                <Table maxWidth>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={tableStyle}>
                                                <Typography>Deposit</Typography>
                                            </TableCell>
                                            <TableCell style={tableStyle} align="right">
                                                <Typography>{formatNumber(betAmount)} ETB </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody fullWidth>
                                        <TableRow fullWidth>
                                            <TableCell style={tableStyle}>
                                                <Typography variant="body2">VAT {vatPercentage}%</Typography>
                                            </TableCell>
                                            <TableCell style={tableStyle} align="right">
                                                <Typography variant="body2"> {formatNumber(vatAmount)} ETB </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableStyle}>
                                                <Typography variant="body2">Placed Bet</Typography>
                                            </TableCell>
                                            <TableCell style={tableStyle} align="right">
                                                <Typography variant="body2">
                                                    {' '}
                                                    {formatNumber(actualBetAmount)} ETB{' '}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableStyle}>
                                                <Typography variant="body2" fontWeight="bold">
                                                    Total Odds
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={tableStyle} align="right">
                                                <Typography variant="body2" fontWeight="bold">
                                                    {formatNumber(totalOdds)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        {taxAmount > 0 ? (
                                            <>
                                                <TableRow>
                                                    <TableCell style={tableStyle}>
                                                        <Typography variant="body2"> Win </Typography>
                                                    </TableCell>
                                                    <TableCell style={tableStyle} align="right">
                                                        <Typography variant="body2">
                                                            {formatNumber(actualwining)} ETB
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={tableStyle}>
                                                        <Typography variant="body2">
                                                            - {incomeTaxPercentage}% Income Tax
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell style={tableStyle} align="right">
                                                        <Typography variant="body2">
                                                            {' '}
                                                            {formatNumber(taxAmount)} ETB{' '}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={tableStyle}>
                                                        <Typography fontWeight="bold" variant="body2">
                                                            Net Win / Payout
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell style={tableStyle} align="right">
                                                        <Typography fontWeight="bold" variant="body2">
                                                            {formatNumber(winngAmount)} ETB
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ) : (
                                            <TableRow>
                                                <TableCell style={tableStyle}>
                                                    <Typography fontWeight="bold" variant="body2">
                                                        Win
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={tableStyle} align="right">
                                                    <Typography fontWeight="bold" variant="body2">
                                                        {formatNumber(winngAmount)} ETB
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box
                            sx={{
                                textTransform: 'lowercase',
                                textAlign: 'center',
                                backgroundColor: '#86E5F7',
                                padding: '7px',
                            }}
                        >
                            www.onlinegamblingtech.com
                        </Box>
                    </div>
                    <Link to="#" onClick={() => setOpen(false)} className="popCloseicon" style={{ color: grey[900] }}>
                        <CancelIcon fontSize="medium" />
                    </Link>
                </Paper>
            </Modal>
        </>
    );
};
export default TicketPreviewModal;
