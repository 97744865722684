import CancelIcon from '@mui/icons-material/Cancel';
import { Modal, Paper } from '@mui/material';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';

const DialogBox = ({ open, setopen, gameId }) => {
    const { user } = useContext(UserContext);

    const handleClose = (confirm) => {
        setopen(false);
    };
    // let iFrameSrc = user
    //     ? `${process.env.REACT_APP_IFRAME_URL}p=${user.token}&i=${user.username}&eg=${gameId}`
    //     : `${process.env.REACT_APP_IFRAME_URL}&dp=1&eg=${gameId}`;

    let iFrameSrc = `${process.env.REACT_APP_IFRAME_URL}&dp=1&eg=${gameId}`
    return (
        <Modal open={open} className="bgModalslots">
            <Paper
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '1265px',
                    bgcolor: '#B2E576',
                    //  border: '2px solid #000',
                    boxShadow: 24,
                    // p: 4,
                    //height: '800px',
                }}
            >
                <div className="vertualIframe">
                    <iframe
                        title="My iFrame"
                        src={iFrameSrc}
                        style={{ width: '100%', height: '720px', overflowY: 'scroll', border: 0 }}
                        frameBorder="0"
                        allowFullScreen
                        className="iframe-placeholder"
                    ></iframe>
                </div>
                <Link
                    to="#"
                    onClick={handleClose}
                    className="popCloseicon"
                    style={{
                        color: '#B2E576',
                        border: '#68CCDF',
                        borderRadius: '50%',
                        height: '24px',
                        width: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CancelIcon fontSize="medium" />
                </Link>
            </Paper>
        </Modal>
    );
};

export default DialogBox;
