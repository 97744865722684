import React, { useEffect, useState } from 'react';
import { formatDate } from '../../services/helper';

export const DateTime = () => {
    var [date, setDate] = useState(new Date());

    useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer);
        };
    });

    return (
        <span className="curtime ng-isolate-scope" style={{ width: '280px' }}>
            {<span className="date ng-binding">{formatDate(date, 'clockdate')}</span>}
            {<span className="separator">|</span>}
            {/* {date} */}
            {formatDate(date, 'clocktime')}
        </span>
    );
};

export default DateTime;
