import React, { useState } from 'react';
import { connect } from 'react-redux';
import LiveMatchDetailHeader from './LiveMatch/LiveMatchDetailHeader';
import LiveMatchMainBets from './LiveMatch/LiveMatchMainBets';

const LiveMatch = (props) => {
    const [liveMatchId, setLiveMatchId] = useState([]);
    // console.log(' props---', props);
    // console.log(' liveMatchId id---', liveMatchId);

    const livematchDetails = props.livematch.find((item) => item.id === Number(liveMatchId.id));

    return (
        <>
            <LiveMatchDetailHeader livematchDetails={livematchDetails} liveMatchId={liveMatchId} />
            <LiveMatchMainBets tab={props.tab} setLiveMatchId={setLiveMatchId} />
        </>
    );
};
const mapStateToProps = (state) => ({
    tab: state.data.activeTab,
    livematch: state.data.liveMatchOdds,
});
// export default LiveMatch;
export default connect(mapStateToProps, null)(LiveMatch);
