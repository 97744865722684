import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Grid, Typography } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import * as yup from 'yup';
import { ToastSuccess } from '../../../services/helper';
import Footer from '../../layout/Footer';
const DminHeight = window.innerHeight;

const validationSchema = yup.object().shape({
    name: yup
        .string()
        .required('Name is required')
        .min(3)
        .max(20)
        .matches(/^[^\s].*/, 'starting spaced is not allowed'),
    // .matches(/^[A-Za-z]+$/, 'Only alphabetic characters are allowed')
    email: yup
        .string()
        .required('Email is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed')
        .email('Invalid email format'),
    subject: yup
        .string()
        .required(' Subject is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed')
        .min(3),
    message: yup
        .string()
        .required(' Message is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed'),
    // dateOfBirth: yup.date().required('Date of Birth is required'),

    phone: yup
        .string()
        .required('Mobile Number is required')
        .min(7, 'Please enter minimum 7 numbers')
        .max(12, 'Please enter maximum 12 numbers'),
});
const Contact = () => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const [phone, setPhone] = useState('');

    const onSubmit = (contactDetails) => {
        console.log('contact ', contactDetails);
        if (contactDetails) {
            ToastSuccess('Submited successfully');
            reset();
            setPhone('+251');
        }
    };

    const handlePhoneChange = (value, data) => {
        setPhone(value);
        const country_code = data.dialCode;
        const phone_no = value.slice(data.dialCode.length);
        setValue('phone', phone_no, true);
        setValue('country_code', country_code);
    };
    const handleWhatsAppClick = () => {
        window.open('https://wa.me/+251368893370', '_blank');
    };
    const handleCallClick = () => {
        window.location.href = 'tel:+251568893370';
    };
    const handleEmailClick = () => {
        window.location.href = 'mailto:info@betinprime.com';
    };
    return (
        <>
            <Container maxWidth="lg" sx={{}}>
                <Box
                    className="profilePage"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        mb: 2,
                        border: '1px solid',
                        borderColor: 'borderBlueColor.main',
                        p: 2,
                        mt: 4,
                        mb: 4,
                    }}
                    // component="form"
                >
                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                        {/* <Grid item md={8}>
                            <Box className="leftContact">
                                

                                <Typography variant="body2" mt={1}>
                                    Contact us using the form and fill in all fields for faster problem-solving. We're
                                    here to help!
                                </Typography>

                                <Box mt={2}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Stack direction="row" spacing={2} mb="20px">
                                            <Box width="50%">
                                                <TextField
                                                    color="black"
                                                    sx={{}}
                                                    placeholder="Enter Your Name"
                                                    fullWidth
                                                    {...register('name')}
                                                    variant="outlined"
                                                    label="Name"
                                                    disableUnderline
                                                ></TextField>

                                                <Typography variant="body2" color="error">
                                                    {errors.name?.message}
                                                </Typography>
                                            </Box>

                                            <Box width="50%">
                                                <PhoneInput
                                                    color="black"
                                                    inputProps={{
                                                        name: 'phone',
                                                        required: true,
                                                        autoFocus: true,
                                                    }}
                                                    variant="outlined"
                                                    placeholder="Phone No"
                                                    value={phone}
                                                    fullWidth
                                                    country={'et'}
                                                    onChange={handlePhoneChange}
                                                />
                                                <Typography variant="body2" color="error">
                                                    {errors.phone?.message}
                                                </Typography>
                                            </Box>
                                        </Stack>

                                        <Stack direction="row" spacing={2} mb="20px">
                                            <Box width="100%">
                                                <TextField
                                                    color="black"
                                                    sx={{}}
                                                    placeholder="Enter your email"
                                                    fullWidth
                                                    {...register('email')}
                                                    variant="outlined"
                                                    label="Email"
                                                    disableUnderline
                                                ></TextField>

                                                <Typography variant="body2" color="error">
                                                    {errors.email?.message}
                                                </Typography>
                                            </Box>

                         
                                        </Stack>

                                        <Stack mb="20px">
                                            <Box width="100%">
                                                <TextField
                                                    color="black"
                                                    sx={{}}
                                                    placeholder="Message"
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Message"
                                                    // {...register('message')}
                                                    disableUnderline
                                                    multiline
                                                    rows={4}
                                                ></TextField>
                                              
                                            </Box>
                                        </Stack>

                                        <Stack alignItems="flex-end">
                                            <Button
                                                aria-label="Submit"
                                                variant="contained"
                                                type="submit"
                                                sx={{
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                    marginTop: '10px',
                                                    fontWeight: '600',
                                                    marginBottom: '20px',
                                                    paddingLeft: '40px',
                                                    paddingRight: '40px',
                                                    backgroundColor: '#074652',
                                                    color: '#fff',
                                                    '&:hover': { backgroundColor: '#074652' },
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </Stack>
                                    </form>
                                </Box>
                            </Box>
                        </Grid> */}
                        <Grid md={4}>
                            <Box display="flex" sx={{ marginLeft: '20px', marginTop: '20px' }}>
                                {' '}
                                <Icon icon="grommet-icons:contact" fontSize={24} />
                                <Typography
                                    sx={{
                                        fontSize: '16px;',
                                        fontWeight: 'bold',
                                        mb: 1,
                                        ml: 1,
                                    }}
                                >
                                    Contact Us
                                </Typography>{' '}
                            </Box>
                            <Box
                                className="rightCLInetDv"
                                m={3}
                                sx={{ backgroundColor: 'secondary.main', padding: 2, borderRadius: 3, marginRight: 0 }}
                            >
                                <Typography sx={{ textTransform: 'uppercase', fontSize: '20px' }} mb={2}>
                                    {' '}
                                    Client Support Hotline
                                </Typography>

                                <Stack direction="row" mb={2}>
                                    <HourglassBottomIcon sx={{ mr: 1 }} />
                                    <Typography>WORKING HOURS: 8am - 10pm</Typography>
                                </Stack>

                                <Stack sx={{ cursor: 'pointer' }} onClick={handleCallClick} direction="row" mb={2}>
                                    <CallIcon sx={{ mr: 1 }} />
                                    <Typography>+251 568893370 </Typography>
                                </Stack>

                                <Stack sx={{ cursor: 'pointer' }} onClick={handleWhatsAppClick} direction="row" mb={2}>
                                    <WhatsAppIcon sx={{ mr: 1 }} />
                                    <Typography>+251 368893370 </Typography>
                                </Stack>
                                <Stack sx={{ cursor: 'pointer' }} direction="row" mb={2} onClick={handleEmailClick}>
                                    <EmailIcon sx={{ mr: 1 }} />
                                    <Typography>info@betinprime.com</Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        {/* Add more Grid items as needed */}
                    </Grid>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default Contact;
