import CancelIcon from '@mui/icons-material/Cancel';
import {
    Box,
    Divider,
    Grid,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { formatDate, formatNumber } from '../../../services/helper';

const image = {
    alt: 'Logo Main',
    //height: 200, // Replace with the actual height of the image
    src: '/images/primeLogo.png', // Replace with the actual path to your image
    // width: 300, // Replace with the actual width of the image
};

const TicketDetails = ({ data, isOpen, onClose }) => {
    const items = data.items;
    const totalOdds = data.totalOdds;
    const winningAmount = data.winningAmount;
    const betAmount = data.betAmount;
    const taxAmount = data.taxAmount;
    const vatAmount = data.vatAmount;
    const actualBetAmount = data.actualBetAmount;
    const incomeTaxPercentage = data.incomeTaxPercentage;
    const vatPercentage = data.vatPercentage;
    const status = data.status;
    const actualwining = parseFloat(winningAmount) + parseFloat(taxAmount);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 590,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
    };

    const tableStyle = {
        border: 0,
        paddingTop: '2px',
        paddingBottom: '2px',
    };

    const getBetStatus = (status) => {
        let betstatus = null;
        switch (status) {
            case 2:
                betstatus = 'WON';
                break;
            case 1:
                betstatus = 'LOST';
                break;
            case 3:
                betstatus = 'REFUND/CANCELED';
                break;
            case -1:
                betstatus = 'CANCELED';
                break;
            default:
                betstatus = 'OPEN';
                break;
        }
        return betstatus;
    };

    const getSelectedOdd = (fixture) => {
        const oddData = fixture.Markets[0].Odds[0];
        if (oddData.name === '1') {
            return (
                <>
                    <Grid item flexGrow="1" md={4}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {fixture.Participants_home.name}
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {oddData.price.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData.name === 'X') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            Draw
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {oddData.price.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData.name === '2') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {fixture.Participants_away.name}
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {oddData.price.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData.name === '1X') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {fixture.Participants_home.name} or Draw
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {oddData.price.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData.name === 'X2') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            Draw or {fixture.Participants_away.name}
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {oddData.price.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else if (oddData.name === '12') {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            No Draw
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {oddData.price.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        } else {
            return (
                <>
                    <Grid item flexGrow="1" md={5}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {oddData.name} {oddData.line !== '' ? oddData.line : ''}
                        </Typography>
                    </Grid>
                    <Grid item md={1}>
                        <Typography variant="body2" fontWeight="bold" mt="">
                            {oddData.price.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            );
        }
    };
    function TitleLogo() {
        return (
            <>
                <LazyLoadImage
                    className="maxWith100"
                    alt={image.alt}
                    width="106"
                    height="47"
                    rel="preload"
                    as="image"
                    // height={image.height}
                    src={image.src} // use normal <img> attributes as props
                    // width={image.width}
                />

                {/* <img src="/images/primeLogo.png" className="maxWith100" alt=""></img> */}
            </>
        );
    }

    const getOddStatus = (status) => {
        switch (status) {
            case 0:
                return 'beslip_open';
            case -1:
            case 4:
            case 3:
                return 'beslip_canceled';
            case 1:
                return 'beslip_lost';
            case 5:
                return 'besliphalf_win';
            case 2:
                return 'beslip_win';
            default:
                return 'beslip_open';
        }
    };

    return (
        <>
            <Modal open={isOpen} onClose={onClose} className="bgModal" sx={{}}>
                <Paper className="tktDpop" sx={style}>
                    <div className="modalContentHolder">
                        <div className="modalContentTitle">
                            <div className="ModalLogo">
                                <TitleLogo />
                            </div>
                            {/* <div className="previewTitle">
                                <Typography component="h5" className="previeTHeading" fontWeight="500" mr="30px">
                                   
                                </Typography>
                            </div> */}
                        </div>

                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            pt="2px"
                            pr={2}
                            bgcolor={grey[200]}
                            pb="2px"
                            px={2}
                        >
                            <Typography variant="h6" component="h6" color="initial" textAlign="left">
                                AKO ( {getBetStatus(status)} )
                            </Typography>
                            <Typography variant="body1" fontWeight="700" component="h4" color="initial">
                                Total Odds
                            </Typography>
                        </Box>
                        <div className="tktMxHeight" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {items.map((item) => (
                                <>
                                    <Box className="">
                                        <Box
                                            className={getOddStatus(item.Markets[0].Odds[0].betsettlement_status)}
                                            mb="3px"
                                            pb="10px"
                                        >
                                            <Typography
                                                variant="body2"
                                                pl="16px"
                                                pt="8px"
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                    marginBottom: '5px',
                                                }}
                                            >
                                                {item.sport_name} - {item.location_name} - {item.league_name}
                                            </Typography>

                                            <Box pt="0px" pr="16px" pb="2px" pl="16px" mb="">
                                                <Grid container spacing={2} alignItems="flex-end" wrap="no-wrap">
                                                    <Grid item md={2} alignSelf="flex-start">
                                                        <Typography variant="body2" fontWeight="600">
                                                            {item.id}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item flexGrow="2" md={5}>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{ fontWeight: '600', textTransform: 'uppercase' }}
                                                        >
                                                            {item.Participants_home.name} -{' '}
                                                            {item.Participants_away.name}
                                                        </Typography>
                                                        <Box display="flex">
                                                            <Typography fontSize="13px">Start at </Typography>
                                                            <Typography fontSize="13px" ml="10px" fontWeight="500">
                                                                {formatDate(item.start_date, 'ticketpreview')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant="body2" fontSize="13px">
                                                            {item.Markets[0].name === '1X2'
                                                                ? '1X2 (Match Results)'
                                                                : item.Markets[0].name}
                                                        </Typography>
                                                    </Grid>
                                                    {getSelectedOdd(item)}
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            ))}
                        </div>
                        <Divider sx={{ marginTop: '10px' }} />

                        <Box margin={5} mt="5px">
                            <TableContainer container component="Box" margin={2}>
                                <Table maxWidth>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={tableStyle}>
                                                <Typography>Deposit</Typography>{' '}
                                            </TableCell>
                                            <TableCell style={tableStyle} align="right">
                                                <Typography>{formatNumber(betAmount)} ETB </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody fullWidth>
                                        <TableRow fullWidth>
                                            <TableCell style={tableStyle}>
                                                <Typography variant="body2">VAT {vatPercentage}%</Typography>
                                            </TableCell>
                                            <TableCell style={tableStyle} align="right">
                                                <Typography variant="body2"> {formatNumber(vatAmount)} ETB </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell style={tableStyle}>
                                                <Typography variant="body2">Placed Bet</Typography>
                                            </TableCell>
                                            <TableCell style={tableStyle} align="right">
                                                <Typography variant="body2">
                                                    {' '}
                                                    {formatNumber(actualBetAmount)} ETB{' '}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell style={tableStyle}>
                                                <Typography variant="body2" fontWeight="bold">
                                                    Total Odds
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={tableStyle} align="right">
                                                <Typography variant="body2" fontWeight="bold">
                                                    {formatNumber(totalOdds)}
                                                </Typography>{' '}
                                            </TableCell>
                                        </TableRow>

                                        {taxAmount > 0 ? (
                                            <>
                                                {status === 3 || status === -1 ? (
                                                    ''
                                                ) : (
                                                    <>
                                                        <TableRow>
                                                            <TableCell style={tableStyle}>
                                                                <Typography variant="body2"> Win </Typography>
                                                            </TableCell>
                                                            <TableCell style={tableStyle} align="right">
                                                                <Typography variant="body2">
                                                                    {formatNumber(actualwining.toFixed(2))} ETB
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={tableStyle}>
                                                                <Typography variant="body2">
                                                                    - {incomeTaxPercentage}% Income Tax
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell style={tableStyle} align="right">
                                                                <Typography variant="body2">
                                                                    {' '}
                                                                    {formatNumber(taxAmount)} ETB{' '}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )}
                                                <TableRow>
                                                    <TableCell style={tableStyle}>
                                                        <Typography fontWeight="bold" variant="body2">
                                                            {status === 3 || status === -1
                                                                ? 'Refund'
                                                                : 'Net Win / Payout'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell style={tableStyle} align="right">
                                                        <Typography fontWeight="bold" variant="body2">
                                                            {status === 1 ? '0' : formatNumber(winningAmount)} ETB
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ) : (
                                            <TableRow>
                                                <TableCell style={tableStyle}>
                                                    <Typography fontWeight="bold" variant="body2">
                                                        {status === 3 || status === -1 ? 'Refund' : 'Win'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={tableStyle} align="right">
                                                    <Typography fontWeight="bold" variant="body2">
                                                        {status === 1 ? '0' : formatNumber(winningAmount)} ETB
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box
                            sx={{
                                textTransform: 'lowercase',
                                textAlign: 'center',
                                backgroundColor: '#B2E576',
                                padding: '7px',
                            }}
                        >
                            www.onlinegamblingtech.com
                        </Box>
                    </div>
                    <Link to="#" onClick={onClose} className="popCloseicon" style={{ color: grey[900] }}>
                        <CancelIcon fontSize="medium" />
                    </Link>
                </Paper>
            </Modal>
        </>
    );
};
export default TicketDetails;
