import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LottieLoader from 'react-lottie-loader';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import sportLoaderData from '../../../loader/sport-loader_2.json';
import { addOrUpdateItem, deleteAllItems, fetchData, onChangeActiveTab, onDeleteItem } from '../../../redux/actions';
import { ToastError, getSportIcon } from '../../../services/helper';
import FilteredPreMatchOddsGriditem from '../FilteredPreMatch/FilteredPreMatchOddsGriditem';

const imgStyle = {
    marginRight: '5px',
    marginTop: '3px',
};
const titleTxtStyle = {
    // marginTop:'-5px',
};

const TopLeaguesMiddleContent = (props) => {
    const { id } = useParams();
    const { pathname } = useLocation();
    const [leagueData, setLeagueData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchDataFromApi = async () => {
        toast.dismiss();
        try {
            setLoading(true); // Set loading before sending API request
            const fetchedData = await fetchData(`/auth/topleagues/detail/${id}`);
            setLeagueData(fetchedData.payload.data[0]);
            setLoading(false); // Stop loading
        } catch (error) {
            console.log('error :>> ', error);
            ToastError(error.response.data.message);
        }
    };

    useEffect(() => {
        if (id) {
            fetchDataFromApi();
        }
    }, [id]);
    return (
        <>
            {loading ? (
                <LottieLoader className="loaderBall" animationData={sportLoaderData} />
            ) : (
                <Box p={1} key={leagueData.id}>
                    <Box
                        variant="h6"
                        fontSize="15px"
                        p={1}
                        backgroundColor="#074652"
                        color="#fff"
                        display="flex"
                        alignItems="center"
                        sx={{ borderRadius: '8px 8px 0 0' }}
                    >
                        <span style={imgStyle}>{getSportIcon(leagueData.sport_name)}</span>
                        <span style={titleTxtStyle}>
                            {' '}
                            {leagueData.sport_name} - {leagueData.location_name}{' '}
                        </span>
                    </Box>
                    {/* top title end */}
                    <div className="scoreTableWrapper">
                        <div className="topBatTitleContainer">
                            <div className="tpBetItem topbetItemTitle">
                                <span className="topFlagImg">
                                    <img
                                        src={
                                            leagueData.location_icon
                                                ? leagueData.location_icon
                                                : '/images/flags/flagGlb.png'
                                        }
                                        alt="flagFrnc"
                                        width="24"
                                        height="24"
                                    />
                                </span>
                                <span className="teamTitle">
                                    {leagueData.location_name} - {leagueData.name}
                                </span>
                            </div>
                            <div className="tpBetItem">
                                <span>1</span>
                            </div>
                            <div className="tpBetItem">
                                <span>X</span>
                            </div>
                            <div className="tpBetItem">
                                <span>2</span>
                            </div>
                            <div className="tpBetItem">
                                <span>1X</span>
                            </div>
                            <div className="tpBetItem">
                                <span>X2</span>
                            </div>
                            <div className="tpBetItem">
                                <span>12</span>
                            </div>
                            <div className="tpBetItem"></div>
                        </div>
                        {leagueData.Fixture && leagueData.Fixture.length ? (
                            leagueData.Fixture.map((item, index) => (
                                <FilteredPreMatchOddsGriditem key={index} item={item} />
                            ))
                        ) : (
                            <Typography>No Match found</Typography>
                        )}
                    </div>
                </Box>
            )}
        </>
    );
};
// export default TopLeaguesMiddleContent;
const mapStateToProps = (state) => ({
    activeTab: state.data.activeTab,
    betslip1: state.data.betslip1,
    betslip2: state.data.betslip2,
    betslip3: state.data.betslip3,
    livebetslip: state.data.livebetslip,
});

const mapDispatchToProps = (dispatch) => ({
    onDeleteItem: (index, tab) => dispatch(onDeleteItem(index, tab)),
    onDeleteAllItem: (tab) => dispatch(deleteAllItems(tab)),
    onChangeActiveTab: (tabIndex) => dispatch(onChangeActiveTab(tabIndex)),
    addOrUpdateItem: (newBet, odd, market, tab) => dispatch(addOrUpdateItem(newBet, odd, market, tab)),
});

// Connect the component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(TopLeaguesMiddleContent);
