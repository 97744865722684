import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export default function FallBackLoader() {
  return (
    <Box  display="flex" justifyContent="center" >
        <CircularProgress disableShrink /> 
    </Box>
  )
}
