import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, IconButton, Stack } from '@mui/material';
import { toast } from 'react-toastify';
import { checkBetSlip, onDeleteItem } from '../../../../redux/actions';
import { postDataWithoutAuth } from '../../../../services/api';
import { getCurrentBetslip, getSportIcon } from '../../../../services/helper';
import OddSection from './OddSection';

const BetSlip = ({ onDeleteItem, items, tab, checkBetSlip, betType, type, activeTab }) => {
    localStorage.setItem('selectedOdds', JSON.stringify(items));
    const handleDeleteItem = (index) => {
        onDeleteItem(index, tab);
    };

    const checkBetSlipAPI = async () => {
        toast.dismiss();
        const betslip = JSON.parse(localStorage.getItem('selectedOdds'));

        try {
            if (betslip?.length > 0) {
                const fetchedData = await postDataWithoutAuth(`/auth/check/odds`, {
                    data: {
                        odds: betslip.map((item) => {
                            return {
                                fixture_id: item.id,
                                market_id: item.MarketSelectedId,
                                odd_id: item.oddSelectedId,
                            };
                        }),
                    },
                });
                checkBetSlip(tab, fetchedData.data);
            }
        } catch (error) {
            console.log('error ==:>> ', error);
            // ToastError(error.response.data.message);
        }
    };

    useEffect(() => {
        checkBetSlipAPI(items);
        // const interval = setInterval(checkBetSlipAPI, 60 * 1000, items);
        // return () => {
        //     clearInterval(interval);
        // };
    }, [activeTab]);

    const getSelectedOdd = (oddSelectedId, MarketSelectedId, fixture) => {
        let oddData = null;
        if (fixture.hasOwnProperty('Market')) {
            oddData = fixture.Market.Odds?.find((item) => item.id === oddSelectedId);
        } else {
            oddData = fixture.Markets?.find((item) => item.id === MarketSelectedId).Odds?.find(
                (item) => item.id === oddSelectedId
            );
        }
        // console.log('oddData', oddData);
        if (oddData?.name === '1') {
            return (
                <>
                    <Stack direction="column">
                        <span className="oddBold">
                            {fixture.Market.name}
                            {' (Match Results)'}
                        </span>
                        <span className="rakText">{fixture.Participants_home.name}</span>
                    </Stack>
                    <Box sx={{}} variant="body1" display="flex" justifyContent="space-between" alignItems="center">
                        <span
                            className={`oddBold ${
                                oddData?.hasOwnProperty('is_updated')
                                    ? oddData.is_updated === 'up'
                                        ? 'arrowUp'
                                        : 'arrowDown'
                                    : ''
                            }`}
                        >
                            {oddData?.price?.toFixed(2)}
                        </span>
                    </Box>
                </>
            );
        } else if (oddData?.name === 'X') {
            return (
                <>
                    <Stack direction="column">
                        <span className="oddBold">
                            {fixture.Market.name}
                            {' (Match Results)'}
                        </span>
                        <span className="rakText">Draw</span>
                    </Stack>
                    <Box sx={{}} variant="body1" display="flex" justifyContent="space-between" alignItems="center">
                        <span
                            className={`oddBold ${
                                oddData?.hasOwnProperty('is_updated')
                                    ? oddData.is_updated === 'up'
                                        ? 'arrowUp'
                                        : 'arrowDown'
                                    : ''
                            }`}
                        >
                            {oddData?.price?.toFixed(2)}
                        </span>
                    </Box>
                </>
            );
        } else if (oddData?.name === '2') {
            return (
                <>
                    <Stack direction="column">
                        <span className="oddBold">
                            {fixture.Market.name}
                            {' (Match Results)'}
                        </span>
                        <span className="rakText">{fixture.Participants_away.name}</span>
                    </Stack>
                    <Box sx={{}} variant="body1" display="flex" justifyContent="space-between" alignItems="center">
                        <span
                            className={`oddBold ${
                                oddData?.hasOwnProperty('is_updated')
                                    ? oddData.is_updated === 'up'
                                        ? 'arrowUp'
                                        : 'arrowDown'
                                    : ''
                            }`}
                        >
                            {oddData?.price?.toFixed(2)}
                        </span>
                    </Box>
                </>
            );
        } else if (oddData?.name === '1X') {
            return (
                <>
                    <Stack direction="column">
                        <span className="oddBold">{fixture.Market.name}</span>
                        <span className="rakText">{fixture.Participants_home.name} or Draw</span>
                    </Stack>
                    <Box sx={{}} variant="body1" display="flex" justifyContent="space-between" alignItems="center">
                        <span
                            className={`oddBold ${
                                oddData?.hasOwnProperty('is_updated')
                                    ? oddData.is_updated === 'up'
                                        ? 'arrowUp'
                                        : 'arrowDown'
                                    : ''
                            }`}
                        >
                            {oddData?.price?.toFixed(2)}
                        </span>
                    </Box>
                </>
            );
        } else if (oddData?.name === 'X2') {
            return (
                <>
                    <Stack direction="column">
                        <span className="oddBold">{fixture.Market.name}</span>
                        <span className="rakText">Draw or {fixture.Participants_away.name}</span>
                    </Stack>
                    <Box sx={{}} variant="body1" display="flex" justifyContent="space-between" alignItems="center">
                        <span
                            className={`oddBold ${
                                oddData?.hasOwnProperty('is_updated')
                                    ? oddData.is_updated === 'up'
                                        ? 'arrowUp'
                                        : 'arrowDown'
                                    : ''
                            }`}
                        >
                            {oddData?.price?.toFixed(2)}
                        </span>
                    </Box>
                </>
            );
        } else if (oddData?.name === '12') {
            return (
                <>
                    <Stack direction="column">
                        <span className="oddBold">{fixture.Market.name}</span>
                        <span className="rakText">No Draw</span>
                    </Stack>
                    <Box sx={{}} variant="body1" display="flex" justifyContent="space-between" alignItems="center">
                        <span
                            className={`oddBold ${
                                oddData?.hasOwnProperty('is_updated')
                                    ? oddData.is_updated === 'up'
                                        ? 'arrowUp'
                                        : 'arrowDown'
                                    : ''
                            }`}
                        >
                            {oddData?.price?.toFixed(2)}
                        </span>
                    </Box>
                </>
            );
        } else {
            return (
                <>
                    <Stack direction="column">
                        <span className="oddBold">{fixture.Market.name}</span>
                        <span className="rakText">
                            {oddData?.name} {oddData?.line !== '' ? oddData?.line : ''}
                        </span>
                    </Stack>
                    <Box sx={{}} variant="body1" display="flex" justifyContent="space-between" alignItems="center">
                        <span
                            className={`oddBold ${
                                oddData?.hasOwnProperty('is_updated')
                                    ? oddData.is_updated === 'up'
                                        ? 'arrowUp'
                                        : 'arrowDown'
                                    : ''
                            }`}
                        >
                            {oddData?.price?.toFixed(2)}
                        </span>
                    </Box>
                </>
            );
        }
    };
    return (
        <>
            <div className="betslipContentHolder">
                {items?.length ? (
                    items.map((item, index) => (
                        <div key={item.id} className={`betsliponHolder ${item.FixtureStatus}`}>
                            <div className="betSlipRow">
                                <div className="betsSlipItem">
                                    <div className="slipItemImage">{getSportIcon(item.sport_name)}</div>
                                    <div className="slipItemName">
                                        <p>{item.Participants_home.name}</p>
                                        <p>{item.Participants_away.name}</p>
                                    </div>
                                    <div className="betClose mrLeftAuto">
                                        <IconButton
                                            onClick={() => handleDeleteItem(item.id)}
                                            aria-label="delete"
                                            sx={{ color: '#000' }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className="slipOddSelector">
                                    <div className="winHeadingDv">
                                        {getSelectedOdd(item.oddSelectedId, item.MarketSelectedId, item)}
                                    </div>
                                    {/* {item?.hasOwnProperty('OddChanged') ? item.OddChanged : ''} */}
                                </div>
                            </div>
                            {/* <p>{formatDate(item.start_date, 'ticketpreview')}</p> */}
                        </div>
                    ))
                ) : (
                    <div className="noBatslip">
                        <h2 className="empHeading">YOUR TICKET IS STILL EMPTY</h2>
                        <p className="empPara">Click on the appropriate odds to add it into your ticket.</p>
                    </div>
                )}
            </div>

            <OddSection 
                show={items?.length ? '' : ' d-none'} 
                items={items} 
                tab={tab}
                checkBetSlipReducer={checkBetSlip} 
            />
        </>
    );
};
// This function maps the Redux state to component props
const mapStateToProps = (state) => ({
    tab: state.data.activeTab,
    items: state.data[getCurrentBetslip(state.data.activeTab)],
});

const mapDispatchToProps = (dispatch) => ({
    onDeleteItem: (index, tab) => {
        dispatch(onDeleteItem(index, tab));
    },
    checkBetSlip: (tab, newbetslip) => {
        dispatch(checkBetSlip(tab, newbetslip));
    },
});

// Connect the component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(BetSlip);
