import { createContext, useContext, useEffect } from "react";
import { io } from "socket.io-client";
import { socketInit } from "../../redux/actions";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { setLocalStorage } from "../../services/helper";

const SocketContext = createContext();

const SocketProviderContext = ({ isSocketInit, setSocketInit, children }) => {
  let socket = io(process.env.REACT_APP_WEB_SOCKET_URL, {
    transports: ["websocket"],
    autoConnect: false
  }); // Replace with the server URL and port


  useEffect(()=>{
    // debugger
    if(!(socket?.connected && socket.connected) && isSocketInit){
        // debugger
        socket.connect();
    }
    // debugger

    // Socket.IO event listeners
    socket.on("connect", () => {
        console.log(` ${socket.id} Socket connected`);
        setLocalStorage('isSocketConnected', true)
    });

    socket.on("disconnect", () => {
        console.log(` Socket disconnected`);
        setLocalStorage('isSocketConnected', false)
        setSocketInit(false)
        // setLocalStorage('lastVisitTimestamp', moment.utc().format());
    });

    socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
    });
  })

  useEffect(() => {
    // Handle disconnect on browser close
    const handleBeforeUnload = () => {
      socket.disconnect();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      setLocalStorage('lastVisitTimestamp', moment.utc().format());
      // socket.disconnect(); /* Disconnect the socket when the component unmounts */
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    isSocketInit: state.socket.isSocketInit,
  };
};

// mapDispatchToProps as an object
const mapDispatchToProps = (dispatch) => ({
  setSocketInit: (data) => dispatch(socketInit(data))
});

const SocketProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(SocketProviderContext);

const useSocket = () => {
  const socket = useContext(SocketContext);

    // socket.emit('CreateRoom', 'room2');
    if (!socket) {
        throw new Error('useSocket must be used within a SocketProvider');
    }

  return socket;
};

export { SocketProvider, useSocket };
