const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    apiKey: process.env.REACT_APP_API_KEY,
    iframeUrl: process.env.REACT_APP_IFRAME_URL,
    mobileUrl: process.env.REACT_APP_MOBILE_URL,
    secretKey: process.env.REACT_APP_SECRET_KEY,
    waNumber: process.env.REACT_APP_WA_NUMBER
};

export default config;
