import { Badge, Box, IconButton, Typography } from '@mui/material';
import React from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { grey } from '@mui/material/colors';
import { Stack } from '@mui/system';
import { connect } from 'react-redux';
import { deleteAllItems } from '../../../redux/actions';
import BetTab from './BetSection/BetTab';
import RightAddBanner from './RightAddBanner';
import RightCheckTkt from './RightCheckTkt';

const RightBetslip = ({ type, betType, onDeleteAllItem, activeTab, livebetslip }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
                className="rightTabholder"
            >
                <div className="betslipContentHolder">
                    <Stack
                        direction="row"
                        pl={2}
                        // pt={1}
                        pr={1}
                        justifyContent="space-between"
                        alignItems="center"
                        textTransform="uppercase"
                        sx={{ backgroundColor: grey[200], borderRadius: '8px 8px 0 0' }}
                    >
                        <Typography variant="body1" fontWeight="600">
                            {type === 'live' ? 'Live Betslip' : 'Betslip ( AKO )'}
                        </Typography>
                        <Box>
                            {type === 'live' && (
                                <Badge
                                    badgeContent={livebetslip.length}
                                    sx={{ backgroundColor: '#52742B' }}
                                    // color="secondary"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    {/* Add your icon component here */}
                                </Badge>
                            )}

                            <IconButton
                                aria-label="Delete all items"
                                // onClick={() => onDeleteAllItem(type === 'live' ? 'live' : activeTab)}
                                onClick={() => {
                                    onDeleteAllItem(type === 'live' ? 'live' : activeTab);
                                }}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>

                            {/* <IconButton>
                                <BookmarkBorderIcon />
                            </IconButton> */}
                        </Box>
                    </Stack>

                    <BetTab type={type} betType={betType} />
                </div>
            </Box>
            <RightCheckTkt />
            <RightAddBanner />
        </>
    );
};

// export default RightBetslip;

const mapStateToProps = (state) => ({
    livebetslip: state.data.livebetslip,
});

const mapDispatchToProps = (dispatch) => ({
    onDeleteAllItem: (tab) => dispatch(deleteAllItems(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightBetslip);
