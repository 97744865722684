import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, CircularProgress, Modal, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useContext, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchDataWithAuth, postData, postDataWithoutAuth } from '../../../services/api';
import { ToastError, generateHash, getSelectedOdd } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';

const PlaceBetModel = ({
    type,
    items,
    totalOdds,
    winngAmount,
    betAmount,
    vatAmount,
    actualBetAmount,
    taxAmount,
    systemSetting,
    checkBetSlipReducer,
    tab,
}) => {
    const { user, updateBalance } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [pbet_uid, setPbetUid] = useState(0);
    const [isClicked, setIsClicked] = useState(false);
    const [btnText, setBtnText] = useState('Place bet');

    const vatPercentage = Number(systemSetting['VAT (%)']);
    const incomeTaxThreshold = Number(systemSetting['Income Tax Threshold']);
    const incomeTaxPercentage = Number(systemSetting['Income Tax (%)']);
    const minimumBetAmount = Number(systemSetting['Minimum Bet Amount']);
    const maximumBetAmount = Number(systemSetting['Maximum Bet Amount']);
    const maximumWinAmount = Number(systemSetting['Maximum Win Amount']);
    const dailyMaxBetAmount = Number(systemSetting['Daily Maximum Bet Amount']);
    const weeklyMaxBetAmount = Number(systemSetting['Weekly Maximum Bet Amount']);
    const monthlyMaxBetAmount = Number(systemSetting['Monthly Maximum Bet Amount']);

    const fetchAndUpdateBetLimit = async () => {
        const data = {
            user_token: user.token,
            data: {},
        };
        const BetLimit = await fetchDataWithAuth('/user/getRemainingLimit', data.user_token);
        return BetLimit.data[0].limit[0].result;
    };

    const checkBetSlipAPI = async () => {
        toast.dismiss();
        const betslip = JSON.parse(localStorage.getItem('selectedOdds'));

        try {
            if (betslip?.length > 0) {
                const fetchedData = await postDataWithoutAuth(`/auth/check/odds`, {
                    data: {
                        odds: betslip.map((item) => {
                            const odd =
                                item?.odds && item?.odds?.length > 0
                                    ? item.odds.find((odd) => odd.id === item.oddSelectedId)
                                    : item.Markets.reduce((arr, data) => [...arr, ...data.Odds], []).find(
                                          (odd) => odd.id === item.oddSelectedId
                                      );
                            return {
                                fixture_id: item.id,
                                market_id: item.MarketSelectedId,
                                odd_id: item.oddSelectedId,
                                odd_price: odd.price,
                            };
                        }),
                        isPlaceBet: true,
                    },
                });
                checkBetSlipReducer(tab, fetchedData.data);
                return fetchedData;
            }
        } catch (error) {
            console.log('error ==:>> ', error);
            // ToastError(error?.response?.data?.message);
            return;
        }
    };

    const handleOpen = async () => {
        const betlimit = await fetchAndUpdateBetLimit();

        if (betlimit <= 0) {
            ToastError(`your daily bet limit is exhausted`);
            return false;
        }

        if (parseFloat(betAmount) > betlimit) {
            ToastError(`Stake amount should be less than available daily bet limit ${betlimit} ETB`);
            return false;
        }

        if (parseFloat(betAmount) < parseFloat(minimumBetAmount)) {
            ToastError(`Stake amount should be minimum ${minimumBetAmount}`);
            return false;
        }

        if (parseFloat(betAmount) > parseFloat(winngAmount)) {
            ToastError(`Win must be bigger than stake ${betAmount} ETB`);
            return false;
        }

        if (betAmount > maximumBetAmount) {
            ToastError(`stake ${betAmount} ETB should be less than ${maximumBetAmount} ETB`);
            return false;
        }

        if (winngAmount > maximumWinAmount) {
            ToastError(`possible winning ${winngAmount} ETB should be less than ${maximumWinAmount} ETB`);
            return false;
        }

        setIsClicked(true);
        toast.dismiss();
        checkBetSlipAPI()
            .then(async (response) => {
                const { isOddUpdated = false } = response?.message || {};
                if (isOddUpdated) {
                    ToastError('Your odds has changed, Please Confirm!', 3000);
                    setBtnText('Confirm');
                    return;
                }
                try {
                    let data = {
                        user_token: user.token,
                        data: {
                            fixture_id: items.map((item) => item.id),
                            type: type === 'live' ? 1 : 2,
                            stake_amount: betAmount,
                            total_odds: totalOdds,
                            vat_amount: vatAmount,
                            total_amount: actualBetAmount,
                            win_amount: winngAmount,
                            tax_amount: taxAmount,
                            odd_id: items.map((item) => ({
                                fixture_id: item.id,
                                odd_id: item.oddSelectedId,
                                odd_price: getSelectedOdd(item.oddSelectedId, item.MarketSelectedId, item),
                            })),
                        },
                    };
                    const hashSignature = generateHash(data['data']);
                    data['data'].hash = hashSignature;
                    const response = await postData('/user/place/bet', data);
                    setPbetUid(response.data[0].pbet_uid);
                    updateBalance(response.data[0].balance);
                    setOpen(true);
                } catch (error) {
                    ToastError(error.response.data.message);
                } finally {
                    setIsClicked(false);
                    setBtnText('Place bet');
                }
            })
            .finally(() => {
                setIsClicked(false);
            });
    };
    const handleClose = () => setOpen(false);

    const image = {
        alt: 'Logo Main',
        //height: 200, // Replace with the actual height of the image
        src: '/images/primeLogo.png', // Replace with the actual path to your image
        // width: 300, // Replace with the actual width of the image
    };

    function TitleLogo() {
        return (
            <>
                <LazyLoadImage
                    className="maxWith100"
                    alt={image.alt}
                    width="106"
                    height="47"
                    rel="preload"
                    as="image"
                    // height={image.height}
                    src={image.src} // use normal <img> attributes as props
                    // width={image.width}
                />

                {/* <img src="/images/primeLogo.png" className="maxWith100" alt=""></img> */}
            </>
        );
    }

    return (
        <>
            <div>
                <Button
                    aria-label="Place bet"
                    onClick={handleOpen}
                    className="placeBtn"
                    variant="contained"
                    disabled={isClicked || items.some((item) => item.FixtureStatus === 'non-active')}
                    disableElevation
                >
                    {isClicked ? <CircularProgress color="inherit" size={22} /> : btnText}
                </Button>
                <Modal size open={open} onClose={handleClose}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '540px',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                            textAlign: 'center',
                        }}
                    >
                        <Box>
                            <Box className="ModalNewLogo">
                                <TitleLogo />
                            </Box>
                            <CheckCircleIcon fontSize="large" color="primary" />
                        </Box>
                        <Typography component="h3" variant="body2" sx={{ textTransform: 'uppercase' }}>
                            Your bet is placed
                        </Typography>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ backgroundColor: '#B2E576', mt: 2 }}
                        >
                            <Typography component="h3" variant="body2" sx={{}}>
                                Bet Ticket Number :
                            </Typography>
                            <Typography component="h1" ml={1} my={0.5} variant="h6" fontWeight="500">
                                {pbet_uid}
                            </Typography>
                        </Stack>

                        <Link
                            to="#"
                            onClick={() => setOpen(false)}
                            className="popCloseicon"
                            style={{ color: grey[900] }}
                        >
                            <CancelIcon fontSize="medium" />
                        </Link>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default PlaceBetModel;
