import React, { useEffect } from 'react';
import Clock from './Clock';

import { AppBar, Box, Container, ListItemButton, ListItemText, Toolbar } from '@mui/material';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { clearAllCheckboxValues } from '../../redux/actions';

const Navigation = (props) => {
    const location = useLocation();

    useEffect(() => {
        location.pathname === '/' && props.clearAllCheckboxValues();
    }, [location.pathname]);

    return (
        <>
            <Box>
                <AppBar position="static" color="customDarkPrimary" elevation={0}>
                    <Toolbar sx={{ minHeight: 'auto' }}>
                        <Container maxWidth={false} className="ContainerBig appBar">
                            <Box display="flex" m={0.5}>
                                <ListItemButton
                                    aria-label="Sportsbook"
                                    className={location.pathname === '/' ? 'active' : ''}
                                    component={Link}
                                    to="/"
                                    alignItems="flex-start"
                                    sx={{
                                        flexGrow: 0,
                                        mr: 1,
                                        borderRadius: 1,
                                        '&:hover': {
                                            backgroundColor: 'white.main',
                                        },
                                    }}
                                >
                                    <ListItemText primary="Sportsbook" />
                                </ListItemButton>
                                {/* <ListItemButton
                                    aria-label="Livegames"
                                    className={location.pathname === '/live' ? 'active' : ''}
                                    component={Link}
                                    to="/live"
                                    alignItems="flex-start"
                                    sx={{
                                        mr: 1,
                                        borderRadius: 1,
                                        flexGrow: 0,
                                        mr: 1,
                                        '&:hover': {
                                            backgroundColor: 'white.main',
                                        },
                                    }}
                                >
                                    <ListItemText primary="Livegames" />
                                </ListItemButton> */}
                                <ListItemButton
                                    aria-label="Virtuals"
                                    className={location.pathname === '/virtuals' ? 'active' : ''}
                                    component={Link}
                                    to="/virtuals"
                                    alignItems="flex-start"
                                    sx={{
                                        mr: 1,
                                        borderRadius: 1,
                                        flexGrow: 0,
                                        '&:hover': {
                                            backgroundColor: 'white.main',
                                        },
                                    }}
                                >
                                    <ListItemText primary="Virtuals" />
                                </ListItemButton>
                                <ListItemButton
                                    aria-label="slot"
                                    className={location.pathname === '/slot' ? 'active' : ''}
                                    component={Link}
                                    to="/slot"
                                    alignItems="flex-start"
                                    sx={{
                                        mr: 1,
                                        borderRadius: 1,
                                        flexGrow: 0,
                                        '&:hover': {
                                            backgroundColor: 'white.main',
                                        },
                                    }}
                                >
                                    <ListItemText primary="Slot" />
                                </ListItemButton>
                                <ListItemButton
                                    aria-label="Aviator"
                                    className={location.pathname === '/aviator' ? 'active' : ''}
                                    component={Link}
                                    to="/aviator"
                                    alignItems="flex-start"
                                    sx={{
                                        mr: 1,
                                        borderRadius: 1,
                                        flexGrow: 0,
                                        '&:hover': {
                                            backgroundColor: 'white.main',
                                        },
                                    }}
                                >
                                    <ListItemText primary="Aviator" />
                                </ListItemButton>
                                {/* <ListItemButton
                  component={Link}
                  to="#simple-list"
                  alignItems="flex-start"
                  sx={{ flexGrow: 0 }}
                >
                  <ListItemText primary="VIRTUALS" />
                </ListItemButton>

                <ListItemButton
                  component={Link}
                  to="#simple-list"
                  alignItems="flex-start"
                  sx={{ flexGrow: 0 }}
                >
                  <ListItemText primary="KENO" />
                </ListItemButton>

                <ListItemButton
                  component={Link}
                  to="#simple-list"
                  alignItems="flex-start"
                  sx={{ flexGrow: 0 }}
                >
                  <ListItemText primary="DOG RACE" />
                </ListItemButton>

                <ListItemButton
                  component={Link}
                  to="#simple-list"
                  alignItems="flex-start"
                  sx={{ flexGrow: 0 }}
                >
                  <ListItemText primary="LIVESCORE" />
                </ListItemButton> */}
                                <Clock />
                            </Box>
                        </Container>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearAllCheckboxValues: () => dispatch(clearAllCheckboxValues()),
    };
};
export default connect(null, mapDispatchToProps)(Navigation);

// export default Navigation;
