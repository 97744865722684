import React from 'react';
import Register from './Register';

const Auth = ({ path }) => {
    if (path === 'Register')
        return (
            <>
                <Register />
            </>
        );
};

export default Auth;
