import _ from 'lodash';
export const updateAndMergeDataUsing_ES6 = (oldData, newData) => {
  const updatedData = oldData.map((oldItem) => {
    const newItem = newData.find((newItem) => newItem.id === oldItem.id);

    if (newItem) {
      // Update old data with new data
      return { ...oldItem, ...newItem };
    }

    // If the item is not in new data, keep the old item as is
    return oldItem;
  });

  // Add new items that are not present in old data
  const newItems = newData.filter(
    (newItem) => !oldData.some((oldItem) => oldItem.id === newItem.id)
  );
  const mergedData = [...updatedData, ...newItems];

  return mergedData;
};

export const updateAndMergeData = (oldData, newData, primaryKeyName = 'id') =>{
  return _.unionBy(newData, oldData, primaryKeyName);
}

export const filterUnique = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()];

export const sortByPropertyName = (arr, property = 'name') =>
  arr.sort((a, b) => a[property].localeCompare(b[property]));

export const sortByDate = (arr, property = 'start_date') =>{
  try{
    const sortedData = arr.sort((a, b) => {
      const dateA = new Date(a[property]);
      const dateB = new Date(b[property]);
    
      // Sort based on the start_date in ascending order
      return dateA - dateB;
    });
    return sortedData
  }
  catch{
    return arr
  }
}