import React from 'react';
import ContentLayout from '../../layout/ContentLayout';

function Virtualsgames() {
    return (
        <>
            <ContentLayout leftSection="false" content="Virtuals" />
        </>
    );
}

export default Virtualsgames;
