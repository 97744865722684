import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box, Stack } from '@mui/system';
import { default as React, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as yup from 'yup';
import { ToastSuccess } from '../../../services/helper';

const validationSchema = yup.object().shape({
    name: yup
        .string()
        .required('Name is required')
        .min(3)
        .max(20)
        .matches(/^[^\s].*/, 'starting spaced is not allowed'),
    // .matches(/^[A-Za-z]+$/, 'Only alphabetic characters are allowed')
    // email: yup
    //     .string()
    //     .required('Email is required')
    //     .matches(/^[^\s].*/, 'starting spaced is not allowed')
    //     .email('Invalid email format'),
    subject: yup
        .string()
        .required(' Subject is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed')
        .min(3),
    message: yup
        .string()
        .required(' Message is required')
        .matches(/^[^\s].*/, 'starting spaced is not allowed'),
    // dateOfBirth: yup.date().required('Date of Birth is required'),

    phone: yup
        .string()
        .required('Mobile Number is required')
        .min(7, 'Please enter minimum 7 numbers')
        .max(12, 'Please enter maximum 12 numbers'),
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 790,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

const RaiseTicketModel = ({ isOpen, onClose }) => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const [phone, setPhone] = useState('');

    const onSubmit = (contactDetails) => {
        console.log('suppport ', contactDetails);
        if (contactDetails) {
            ToastSuccess('Submited successfully');
            reset();
            setPhone('+251');
        }
    };

    const handlePhoneChange = (value, data) => {
        setPhone(value);
        const country_code = data.dialCode;
        const phone_no = value.slice(data.dialCode.length);
        setValue('phone', phone_no, true);
        setValue('country_code', country_code);
    };

    return (
        <Modal open={isOpen} className="bgModal" sx={{}}>
            <Paper className="tktDpop" sx={style}>
                <Grid container>
                    <Grid item md={12} padding={3}>
                        <Box className="leftContact">
                            <Box display="flex">
                                {' '}
                                <Icon icon="mynaui:support" fontSize="25px" />
                                <Typography
                                    sx={{
                                        fontSize: '16px;',
                                        fontWeight: 'bold',
                                        mb: 1,
                                        ml: 1,
                                    }}
                                >
                                    Raise Ticket
                                </Typography>{' '}
                                <Box onClick={onClose} className="popCloseicon" style={{ color: grey[900] }}>
                                    <CancelIcon fontSize="medium" />
                                </Box>
                            </Box>

                            <Typography variant="body2" mt={1}>
                                Reach us using the form and fill in all fields for faster problem-solving. We're here to
                                help!
                            </Typography>

                            <Box mt={2}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Stack direction="row" spacing={2} mb="20px">
                                        <Box width="50%">
                                            <TextField
                                                color="black"
                                                sx={{}}
                                                placeholder="Enter Your Name"
                                                fullWidth
                                                {...register('name')}
                                                variant="outlined"
                                                label="Name"
                                                disableUnderline
                                            ></TextField>

                                            <Typography variant="body2" color="error">
                                                {errors.name?.message}
                                            </Typography>
                                        </Box>

                                        <Box width="50%">
                                            <PhoneInput
                                                color="black"
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                variant="outlined"
                                                placeholder="Phone No"
                                                value={phone}
                                                fullWidth
                                                country={'et'}
                                                onChange={handlePhoneChange}
                                            />
                                            <Typography variant="body2" color="error">
                                                {errors.phone?.message}
                                            </Typography>
                                        </Box>
                                    </Stack>

                                    <Stack direction="row" spacing={2} mb="20px">
                                        <Box width="50%">
                                            <TextField
                                                color="black"
                                                sx={{}}
                                                placeholder="Enter your email"
                                                fullWidth
                                                {...register('email')}
                                                variant="outlined"
                                                label="Email"
                                                disableUnderline
                                            ></TextField>
                                        </Box>

                                        <Box width="50%" className="supportType">
                                            <FormControl sx={{ minWidth: '100%' }}>
                                                <InputLabel id="demo-simple-select-label">Type of Support</InputLabel>
                                                <Select name="selectStatus">
                                                    <MenuItem>Registration</MenuItem>
                                                    <MenuItem>Login</MenuItem>

                                                    <MenuItem>Sportsbook</MenuItem>
                                                    <MenuItem>Bet Placement</MenuItem>

                                                    <MenuItem>Virtual</MenuItem>
                                                    <MenuItem>Deposit & Withdrawal</MenuItem>

                                                    <MenuItem>Bonus</MenuItem>
                                                    <MenuItem>Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Stack>

                                    <Stack direction="row" spacing={2} mb="20px">
                                        <Box width="100%">
                                            <TextField
                                                color="black"
                                                sx={{}}
                                                placeholder="Enter a subject"
                                                fullWidth
                                                variant="outlined"
                                                {...register('subject')}
                                                label="Subject"
                                                disableUnderline
                                            ></TextField>

                                            <Typography variant="body2" color="error">
                                                {errors.subject?.message}
                                            </Typography>
                                        </Box>
                                    </Stack>

                                    <Stack mb="20px">
                                        <Box width="100%">
                                            <TextField
                                                color="black"
                                                sx={{}}
                                                placeholder="Description"
                                                fullWidth
                                                variant="outlined"
                                                label="Description"
                                                {...register('message')}
                                                disableUnderline
                                                multiline
                                                rows={4}
                                            ></TextField>
                                            <Typography variant="body2" color="error">
                                                {errors.message?.message}
                                            </Typography>
                                        </Box>
                                    </Stack>

                                    <Stack>
                                        <Box width="100%">
                                            <TextField
                                                color="black"
                                                type="file"
                                                sx={{}}
                                                placeholder="Attachment"
                                                fullWidth
                                                //  variant="outlined"
                                                // {...register('subject')}
                                                //  label="file"
                                                disableUnderline
                                            ></TextField>
                                            <Typography variant="body2" color="error">
                                                {/* {errors.message?.message} */}
                                            </Typography>
                                        </Box>
                                    </Stack>

                                    <Stack alignItems="flex-end">
                                        <Button
                                            aria-label="Submit"
                                            variant="contained"
                                            type="submit"
                                            sx={{
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                                marginTop: '10px',
                                                fontWeight: '600',
                                                marginBottom: '20px',
                                                paddingLeft: '40px',
                                                paddingRight: '40px',
                                                backgroundColor: '#0B5765',
                                                color: '#fff',
                                                '&:hover': { backgroundColor: '#084D5A' },
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Stack>
                                </form>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default RaiseTicketModel;
