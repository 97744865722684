import { Container } from '@mui/system';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/UserContext';

const Aviator = (props) => {
    const { user, updateuserBalance } = useContext(UserContext);

    useEffect(() => {
        updateuserBalance(user);
        window.scrollTo(0, 500);
    }, []);

    let DemoToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdF9uYW1lIjoiamF5ZGlwIiwibGFzdF9uYW1lIjoiYXZ0IiwidXNlcm5hbWUiOiJqYXlkaXBhdnQiLCJlbWFpbCI6IiIsImlkIjoxMTYsImlhdCI6MTcxODcxMzE0NSwiZXhwIjoxNzIxMzkxNTQ1fQ.s1V-EUxkquOwPAmsHkwfjLvRpnSX3UthX1zNr3lAb4I';

    return (
        <>
            {/* {user?.token ? ( */}
            <Container maxWidth="false" sx={{ mt: 2 }}>
                <div className="vertualIframe">
                    <iframe
                        title="My iFrame"
                        src={
                            user?.token
                                ? `${process.env.REACT_APP_AVIATOR_IFRAME_URL}&token=${user.token}`
                                : `${process.env.REACT_APP_AVIATOR_IFRAME_URL}&isDemo=true&token=${DemoToken}`
                        }
                        style={{ width: '100%', minHeight: '850px', overflowY: 'scroll' }}
                        frameBorder="0"
                        allowFullScreen
                        className="iframe-placeholder"
                    ></iframe>
                </div>
            </Container>
            {/* ) : (
                <Typography
                    textAlign="center"
                    marginTop="50px"
                    variant="h3"
                    sx={{ fontSize: '28px', fontWeight: '600' }}
                >
                    Please Login in to Continue
                </Typography>
            )} */}
        </>
    );
};

export default Aviator;