import { CircularProgress, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { getSportIcon } from '../../../services/helper';
const SearchResultList = (props) => {
    return (
        <>
            {props.loading ? (
                <CircularProgress disableShrink />
            ) : (
                <Box className="searchList" mt={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{}}>
                        <Typography
                            sx={{
                                fontSize: '16px;',
                                fontWeight: 'bold',
                                marginBottom: '10px',
                                textTransform: 'uppercase',
                                //padding:'10px'
                            }}
                        >
                            {props.filterData.length} Results Found
                        </Typography>
                        <IconButton aria-label="delete" color="black">
                            <DeleteIcon
                                onClick={() => {
                                    props.searchValue(' ');
                                    props.setRecords([]);
                                    props.searchValue('');
                                }}
                            />
                        </IconButton>
                    </Stack>

                    {/* list item */}

                    {props.filterData?.map((item) => {
                        return (
                            <Link
                                to={'/prematch/detail/' + item.id}
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'inherit',
                                }}
                            >
                                <List disablePadding>
                                    <ListItem disableGutters disablePadding>
                                        <ListItemIcon>{getSportIcon(item.sport_name)}</ListItemIcon>
                                        <ListItemText>{item.fixture_name}</ListItemText>
                                    </ListItem>
                                </List>
                            </Link>
                        );
                    })}
                </Box>
            )}
        </>
    );
};

export default SearchResultList;
