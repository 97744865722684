import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControl, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastError} from '../../../services/helper';
import SearchResultList from './SearchResultList';
import { connect } from 'react-redux';
import { getFixtureByTextSearch } from '../../../redux/SportBook/sportBookMutation';
import { debounce } from 'lodash';

function SideSearch({ AllSportBookData }) {
    const [records, setRecords] = useState([]);
    const { initFixture, leagues, locations, sports, markets } = AllSportBookData
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    
    const boxsearchtyle = {
        padding: '10px',
    };
    const onHandleChange = async (event) => {
        setSearchValue(event.target.value);
        toast.dismiss();
        try {
            if (event.target.value.length >= 3) {
                setLoading(true); // Set loading before sending API request
                 // Debounce the API call
                const debouncedSearch = debounce(async () => {
                    try {
                        let searchData = await getFixtureByTextSearch({
                            filter: event.target.value,
                            initFixture,
                            leagues,
                            locations,
                            sports,
                            markets,
                        });
                        setRecords(searchData);
                        setLoading(false);
                    } catch (error) {
                        ToastError(error.response.data.message);
                    }
                }, 300); // Adjust the delay as needed (300 milliseconds in this example)

                debouncedSearch();
            } else {
                setRecords([]);
            }
        } catch (error) {
            ToastError(error.response.data.message);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && searchValue?.length >= 3 ) {
            navigate(`/searchresult/${searchValue}`)
        }
      };

    const showRecords = () => {
        if (searchValue?.length >= 3 && records?.length > 0)
            return (
                <SearchResultList
                    searchValue={setSearchValue}
                    loading={loading}
                    setRecords={setRecords}
                    filterData={records}
                />
            );
        else if (searchValue?.length !== 0 && searchValue?.length < 3)
            return <Typography variant="body2">Text is too short. Please insert at least 3 characters. </Typography>;
        else return <></>;
    };
    return (
        <>
            <Box
                className="searchLeft"
                sx={{
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    my: 2,
                    p: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                {/* <Typography 
                   
                >
                    SEARCH
                </Typography> */}

                <InputLabel
                    sx={{
                        fontSize: '16px;',
                        fontWeight: 'bold',
                        mb: 1,
                        //padding:'10px'
                    }}
                    htmlFor="Search"
                >
                    SEARCH
                </InputLabel>
                <FormControl>
                    <TextField
                        name="Search"
                        aria-label="Search"
                        sx={{
                            backgroundColor: 'grey.100',
                            px: 2,
                            py: 0.5,
                            borderRadius: 1.2,
                        }}
                        hiddenLabel
                        variant="standard"
                        onKeyPress={handleKeyPress}
                        onChange={onHandleChange}
                        value={searchValue}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment:
                                searchValue?.length >= 3 ? (
                                    <IconButton
                                        aria-label="Search"
                                        LinkComponent={Link}
                                        to={`/searchresult/${searchValue}`}
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton aria-label="Search" edge="end">
                                        <SearchIcon />
                                    </IconButton>
                                ),
                        }}
                    />
                </FormControl>
                {showRecords()}
            </Box>

            {/* <Divider /> */}
        </>
    );
}

const mapStateToProps = (state) => ({
    AllSportBookData: state.sportsBook,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(SideSearch);
