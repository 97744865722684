import { Icon } from '@iconify/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import { io } from 'socket.io-client';
import sportLoaderData from '../../../loader/sport-loader_2.json';
import { addOrUpdateItem, checkBetSlip, onDeleteItem, setActiveTab, setLiveMatchOdds } from '../../../redux/actions';
import { calculateMinutes, compareAndFlagChanges, getCurrentBetslip, getSportIcon } from '../../../services/helper';
import { useSocket } from '../../contexts/SocketContext';

import LottieLoader from 'react-lottie-loader';

const LiveMatches = ({
    tab,
    addOrUpdateItem,
    onDeleteItem,
    setLiveMatchOdds,
    betslip,
    checkBetSlip,
    items,
    ...props
}) => {
    const liveMatchOddsData = props.liveMatchOddsData;

    // const PlayingNowDatas = props;
    // console.log('tab=========', tab);
    const [PlayingNowData, setPlayingNowData] = useState([]);
    const [LiveSports, setLiveSports] = useState([]);
    const [loading, setLoading] = useState(false);
    const oddsHeader = ['1', 'X', '2'];
    const socket = useSocket();
    localStorage.setItem('liveOddsData', JSON.stringify(items));
    useEffect(() => {
        props.setActiveTab('live');
        socket.emit('CreateRoom', 'room2');

        const InPlayMatchData = () => {
            setLoading(true);

            socket.emit('InPlayMatch', { room: 'room2' });
            setLoading(false);
        };
        const getOddsPriceData = () => {
            const betslip = JSON.parse(localStorage.getItem('liveOddsData'));
            socket.emit('GetOddsPrice', {
                room: 'room2',
                odds: betslip.map((item) => {
                    return {
                        fixture_id: item.id,
                        market_id: item.MarketSelectedId,
                        odd_id: item.oddSelectedId,
                    };
                }),
            });
        };

        socket.on('GetOddsPriceData', (data) => {
            // console.log('GetOddsPriceData', data);
            checkBetSlip('live', data);
        });
        socket.on('InPlayMatchData', (fixtures) => {
            // console.log('InPlayMatchData:---->>', fixtures);
            setLoading(true);
            setPlayingNowData(fixtures);
            setLiveSports([...new Set(fixtures.map((item) => item.sport_name))]);

            props.setData(compareAndFlagChanges(PlayingNowData, fixtures));
            setLoading(false);
            // setLoading(false); // Stop loading
        });

        // setInterval(GetInPlayMatch, 1000);
        setInterval(InPlayMatchData, 1000);
        setInterval(getOddsPriceData, 1000);
    }, [socket]);

    const activeOdd = (target, item) => {
        const elements = document.getElementById(item).querySelectorAll('.eventContentWraperRow>.oddbg');

        elements.forEach((element) => {
            element.querySelector('span').classList.remove('active');
        });
        target.classList.add('active');
    };

    const handleAddItem = (item, odd, market) => {
        addOrUpdateItem(item, odd, market, tab);
    };

    const getOptionValue = (option, item) => {
        const isSelected = betslip.find((betitem) => betitem.id === item.id)
            ? betslip.find((betitem) => betitem.id === item.id).oddSelectedId
            : null;

        const hasMarkets = item.hasOwnProperty('Markets');

        let oddData, MarketData;
        if (hasMarkets) {
            const market1X2 = item.Markets.find((market) => market.name === '1X2');
            const market12 = item.Markets.find((market) => market.name === '12');
            const marketDoubleChance = item.Markets.find((market) => market.name === 'Double Chance');

            if (market1X2 && market1X2.Odds.find((odd) => odd.name === option)) {
                oddData = market1X2.Odds.find((odd) => odd.name === option);
                MarketData = market1X2;
            } else if (market12 && market12.Odds.find((odd) => odd.name === option)) {
                oddData = market12.Odds.find((odd) => odd.name === option);
                MarketData = market12;
                if (marketDoubleChance && marketDoubleChance.Odds.find((odd) => odd.name === option)) {
                    oddData = marketDoubleChance.Odds.find((odd) => odd.name === option);
                    MarketData = marketDoubleChance;
                }
            } else {
                oddData = item.Market?.Odds.find((odd) => odd.name === option);
                MarketData = item.Market;
            }
        }
        return oddData ? (
            <div
                className="eventWraperColumn oddbg gridItem"
                data={oddData.name}
                style={{
                    pointerEvents: 'none',
                }}
            >
                {/* <KeyboardArrowUpOutlinedIcon color="success" fontSize="small" />
                    <KeyboardArrowDownOutlinedIcon color="error" fontSize="small" /> */}
                {oddData.bet_status == 1 ? (
                    <span
                        className={`odsSpan ${isSelected && isSelected === oddData.id ? 'active' : ''} ${
                            oddData.is_updated ? (oddData.is_updated === 'up' ? 'arrowUp' : 'arrowDown') : ''
                        }`}
                        onClick={(e) => {
                            if (!e.target.classList.contains('active')) {
                                activeOdd(e.target, item.id);
                                handleAddItem(item, oddData.id, MarketData.id);
                            } else {
                                e.target.classList.remove('active');
                                onDeleteItem(item.id, 'live');
                            }
                        }}
                        style={{
                            pointerEvents: 'auto',
                            cursor: 'pointer',
                        }}
                    >
                        {oddData.price?.toFixed(2)}
                    </span>
                ) : (
                    <span className="odsEmptySpan">
                        <Icon icon="material-symbols:lock" />
                    </span>
                )}
            </div>
        ) : (
            <div className="eventWraperColumn gridItem hiddenSh">
                <span className="odsEmptySpan">
                    <Icon icon="material-symbols:lock" />
                </span>
            </div>
        );
    };
    const getMatchCount = (sport) => {
        const totalMatchCount = liveMatchOddsData.filter((item) => item.sport_name === sport).length;
        return `(${totalMatchCount})`;
    };
    return (
        <>
            <Box mt={2} className="liveSoccerHolder">
                {LiveSports.length > 0 ? (
                    LiveSports.sort().map((sport, index) => (
                        <Box key={index}>
                            <Accordion disableGutters sx={{ mt: 2 }} defaultExpanded>
                                <AccordionSummary
                                    disableGutters
                                    sx={{
                                        backgroundColor: '#074652',
                                        borderRadius: 2,
                                        color: '#fff',
                                    }}
                                    expandIcon={<ExpandMoreIcon color="white" />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box display="flex" alignItems="center">
                                        <div className="eventImage">{getSportIcon(sport)}</div>

                                        <Typography
                                            component="h4"
                                            variant="h5"
                                            fontWeight="500"
                                            fontSize="16px"
                                            textTransform="uppercase"
                                            ml="10px"
                                        >
                                            {sport} {getMatchCount(sport)}
                                        </Typography>
                                    </Box>
                                </AccordionSummary>

                                {liveMatchOddsData
                                    .filter((item) => item.sport_name === sport)
                                    .map((item, index) => (
                                        <>
                                            <AccordionDetails key={index}>
                                                {/* row 1 */}
                                                <Box className="odsWrapper  tennisRow">
                                                    <div className="addFavTitleContainer">
                                                        <div className="clockLink gridItem col_2">
                                                            <img
                                                                src="/images/flags/flagGlb.png"
                                                                className="clubImg"
                                                                alt="flag"
                                                            />
                                                            <span className="spGridName">{item.league_name} </span>
                                                        </div>

                                                        <div className="gridItem"></div>
                                                        {/* <div className="gridItem">
                                                    <span> </span>
                                                </div> */}
                                                        <div className="gridItem">
                                                            <span>1 </span>
                                                        </div>
                                                        <div className="gridItem">
                                                            <span>X </span>
                                                        </div>
                                                        <div className="gridItem">
                                                            <span>2 </span>
                                                        </div>

                                                        <div className="gridItem itemStats"></div>
                                                    </div>

                                                    <div
                                                        id={item.id}
                                                        className="eventContentWraperRow addFavContentContainer"
                                                    >
                                                        <Link
                                                            className="clockLink gridItem"
                                                            to={'/live/detail/' + item.id}
                                                        >
                                                            <img
                                                                src="/images/clock.png"
                                                                className="clockTimer"
                                                                alt="clock"
                                                            ></img>
                                                            <span className="">
                                                                {calculateMinutes(item.actual_start_date)}'
                                                            </span>
                                                        </Link>
                                                        <div className="gridItem">
                                                            {item.Live_score.home_score
                                                                ? item.Live_score.home_score
                                                                : 0}
                                                            :
                                                            {item.Live_score.away_score
                                                                ? item.Live_score.away_score
                                                                : 0}
                                                        </div>
                                                        {/* <div className="gridItem gameName">Canberra FC - Tuggeranong</div> */}
                                                        {/* <Link to={'/live/detail/' + item.id}> */}
                                                        <Link
                                                            // className="eventWraperColumn eventFHolder eventRes"
                                                            to={'/live/detail/' + item.id}
                                                            style={{
                                                                color: 'inherit',
                                                                textDecoration: 'inherit',
                                                            }}
                                                        >
                                                            <span className="eventTitleInside">
                                                                <h3>
                                                                    {item.Participants_home.name} -{' '}
                                                                    {item.Participants_away.name}
                                                                </h3>
                                                                <p>
                                                                    {item.location_name} - {item.league_name}
                                                                </p>
                                                            </span>
                                                            {/* <div className="score dataGridItem ">
                                                        <span className="scoreSpan">
                                                            {item.Live_score.home_score}:{item.Live_score.away_score}
                                                        </span>
                                                    </div> */}
                                                        </Link>

                                                        {oddsHeader.map((odd) => getOptionValue(odd, item))}
                                                        <div className="gridItem itemStats">
                                                            <Link to={'/live/detail/' + item.id} key={item.id}>
                                                                <span className="round">
                                                                    <span>+{item.sidebets}</span>
                                                                </span>
                                                                {/* <IconButton aria-label="delete" color="black">
                                                            <StarIcon sx={{ fontSize: '16px' }} />
                                                        </IconButton> */}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Box>

                                                {/* row 2 */}
                                            </AccordionDetails>
                                        </>
                                    ))}
                            </Accordion>
                        </Box>
                    ))
                ) : (
                    <>
                        {loading === true && <LottieLoader className="loaderBall" animationData={sportLoaderData} />}
                        {LiveSports.length < 1 && (
                            <Typography variant="h5" fontWeight={500} textAlign="center">
                                No Data Found
                            </Typography>
                        )}
                    </>
                )}
            </Box>
        </>
    );
};

const mapStateToProps = (state) => ({
    // Map your state properties here if needed
    betslip: state.data.livebetslip,
    liveMatchOddsData: state.data.liveMatchOdds,
    items: state.data[getCurrentBetslip(state.data.activeTab)],
    tab: state.data.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
    addOrUpdateItem: (item, odd, market, activeTab) => dispatch(addOrUpdateItem(item, odd, market, activeTab)),
    onDeleteItem: (itemId, tab) => dispatch(onDeleteItem(itemId, tab)),
    setActiveTab: (tabId) => dispatch(setActiveTab(tabId)),
    setData: (livedata) => dispatch(setLiveMatchOdds(livedata)),
    checkBetSlip: (tab, newbetslip) => dispatch(checkBetSlip(tab, newbetslip)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LiveMatches);
