import { SET_FILTER_VALUE, SET_FIXTURES_DATA, SET_FIXTURES_DATA_BY_FILTER, SET_FIXTURES_DATA_BY_TIMESTAMP, SET_SEARCH_FIXTURE_DATA, SET_SPORTS_BOOK_DATA } from "./types";

export const setSportsBookData = (data) => ({
    type: SET_SPORTS_BOOK_DATA,
    payload: { ...data },
});

export const setFixtureData = (data) => ({
    type: SET_FIXTURES_DATA,
    payload: { data },
});

export const setFixtureDataByTimeFilter = (data) => ({
    type: SET_FIXTURES_DATA_BY_FILTER,
    payload: {data},
});

export const setFixturesDataByTimeStamp = (data) => ({
    type: SET_FIXTURES_DATA_BY_TIMESTAMP,
    payload: {data},
});

export const setTimeFilterForSportBook = (data = 'All') => ({
    type: SET_FILTER_VALUE,
    payload: {data},
});

export const setSearchFixtureData = (data) => ({
    type: SET_SEARCH_FIXTURE_DATA,
    payload: {data},
});