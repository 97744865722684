import { Icon } from '@iconify/react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box, Stack } from '@mui/system';
import React, { useContext, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-phone-input-2/lib/style.css';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import RaiseTicketModel from './RaiseTicketModel';
const image = {
    alt: 'Logo Main',
    //height: 200, // Replace with the actual height of the image
    src: '/images/primeLogo.png', // Replace with the actual path to your image
    // width: 300, // Replace with the actual width of the image
};
const DminHeight = window.innerHeight;
function TitleLogo() {
    return (
        <>
            <LazyLoadImage
                className="maxWith100"
                alt={image.alt}
                width="106"
                height="47"
                rel="preload"
                as="image"
                // height={image.height}
                src={image.src} // use normal <img> attributes as props
                // width={image.width}
            />
            {/* <img src="/images/primeLogo.png" className="maxWith100" alt=""></img> */}
        </>
    );
}

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 590,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

const Support = () => {
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useContext(UserContext);

    const handleClose = () => {
        // Close the modal and reset the data
        setIsOpen(false);
    };

    return (
        <>
            <RaiseTicketModel isOpen={isOpen} onClose={handleClose} />
            <Box
                sx={{
                    padding: '10px',
                    minHeight: '300px',
                    paddingBottom: '20px',
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Box>
                    <Stack direction="row" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="center" width="100%">
                            <Icon icon="mynaui:support" fontSize="25px" />
                            <Typography
                                color="black"
                                ml={1}
                                variant="h6"
                                sx={{
                                    fontSize: '16px;',
                                    fontWeight: 'bold',

                                    textTransform: 'uppercase',
                                }}
                            >
                                Support Tickets
                            </Typography>

                            <Button
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                                variant="contained"
                                disableElevation
                                sx={{ marginLeft: 'auto' }}
                            >
                                Raise Ticket
                            </Button>
                        </Stack>
                    </Stack>

                    <Box mt={2}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ textTransform: 'uppercase' }}>
                                    <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                        Date
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                        Ticket No
                                    </TableCell>
                                    <TableCell
                                        sx={{ backgroundColor: grey[200], fontWeight: '600', width: '350px' }}
                                        align="center"
                                    >
                                        Subject
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">2/26/24 6:38:08 PM</TableCell>
                                    <TableCell
                                        onClick={() => setOpen(true)}
                                        align="center"
                                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        4512
                                    </TableCell>
                                    <TableCell align="center"> This is a subject</TableCell>
                                    <TableCell align="center">
                                        <span className="status-open mrgBtm_0"></span> <label>Open</label>{' '}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Box>
            <Modal open={open} onClose={() => setOpen(false)} className="bgModal" sx={{}}>
                <Paper className="tktPrievePop" sx={style2}>
                    <div className="modalContentHolder">
                        <div className="modalContentTitle">
                            <div className="ModalLogo">
                                <TitleLogo />
                            </div>
                        </div>

                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ padding: '5px 8px' }}
                            bgcolor={grey[200]}
                        >
                            <Typography variant="h6" component="h6" color="initial" textAlign="center">
                                Status
                            </Typography>
                            <Typography variant="body1" fontWeight="700" component="h4" color="initial">
                                <span className="status-open mrgBtm_0"></span> <label>Open</label>{' '}
                            </Typography>
                        </Box>

                        <Box padding={2}>
                            <Typography variant="body-1">
                                Thank you for submitting your application. We are currently in the process of reviewing
                                all applicants thoroughly to ensure each receives the attention it deserves. Our team is
                                working diligently to assess qualifications and match them with the requirements of the
                                position.{' '}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                textTransform: 'lowercase',
                                textAlign: 'center',
                                backgroundColor: '#86E5F7',
                                padding: '7px',
                            }}
                        >
                            www.primebetin.com
                        </Box>
                    </div>
                    <Link to="#" onClick={() => setOpen(false)} className="popCloseicon" style={{ color: grey[900] }}>
                        <CancelIcon fontSize="medium" />
                    </Link>
                </Paper>
            </Modal>
        </>
    );
};

export default Support;
