import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { Paper, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { backToPrivious } from '../../../services/helper';

const BranchWithdraw = () => {
    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                    paddingBottom: '20px',
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Box width="100%">
                    <Stack direction="row" alignItems="center">
                        <img
                            src="/images/branch_out.png"
                            className="imgWithdraw"
                            width="62"
                            height="66"
                            alt="Deposit"
                        ></img>
                        <Typography
                            color="black"
                            ml={1}
                            variant="h6"
                            sx={{
                                fontSize: '16px;',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                            }}
                        >
                            Withdraw at Branch
                        </Typography>

                        <Button
                            color="primary"
                            onClick={() => backToPrivious()}
                            // variant="text"
                            sx={{ color: '#000', fontWeight: 600, minWidth: '40px', ml: 'auto' }}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Back
                        </Button>
                    </Stack>
                </Box>

                {/* conent start */}

                <Box className="branchList">
                    {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Steps
                    </Typography> */}

                    <Typography variant="body2">
                        To cash out money from your online account you have to log into your account and initiate a
                        request first. To do so click on the Account button and then on Deposit and Withdrawal and than
                        click on the Withdrawal at Branch. You will be able to see your balance, and then please enter a
                        desired amount and initiate a request. After that you will receive a 4digit number on your
                        registered phone number. To complete the cash out you have to visit any of our shops and give
                        the operator your registered username along with the 4digit number. When all information is
                        correct you will receive your winnings. Please be aware that if you send more than one
                        withdrawal request, only the last one will be valid.
                    </Typography>
                </Box>

                <Paper elevation={0} variant="outlined" className="branchTable">
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Fees</TableCell>
                                <TableCell>
                                    <b> No fees applied </b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Processing time</TableCell>
                                <TableCell>
                                    <b>Within 5 minutes </b>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Minimum cash in the amount per transaction</TableCell>
                                <TableCell>
                                    <b>10.00 ETB </b>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Maximum cash in the amount per transaction</TableCell>
                                <TableCell>
                                    <b>Unlimited </b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Box>
        </>
    );
};

export default BranchWithdraw;
