import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import LottieLoader from 'react-lottie-loader';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import sportLoaderData from '../../../loader/sport-loader_2.json';
import { addOrUpdateItem, deleteAllItems, onChangeActiveTab, onDeleteItem } from '../../../redux/actions';
import {
    ToastError,
    findMatchingObjects_new,
    getCurrentBetslip,
    getSportIcon,
    transformToObjectArray,
} from '../../../services/helper';
import FilteredPreMatchOddsGriditem from './FilteredPreMatchOddsGriditem';

const SelectionsPreMatch = ({ selections, AllSportBookData, initFixture, fixtures, ...props }) => {
    const [sportBookwithFixture, setSportBookwithFixture] = useState([]);
    const [loading, setLoading] = useState(false);
    const sportsData = transformToObjectArray(selections);
    const navigate = useNavigate();

    const fetchDataFromApi = async () => {
        toast.dismiss();
        try {
            if (sportsData.length > 0) {
                setLoading(true); // Set loading before sending API request
                const fetchedData = findMatchingObjects_new(sportsData, AllSportBookData);
                if (fetchedData && fetchedData?.length > 0) {
                    setSportBookwithFixture(fetchedData); // Make sure the response data is as expected
                } else {
                    ToastError('No Match Found');
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }

                setLoading(false);
            } else {
                navigate('/');
            }
        } catch (error) {
            console.log('error :>> ', error);
            // ToastError(error.response.data.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataFromApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections, AllSportBookData, initFixture, fixtures, props.sportbook]);

    return (
        <>
            {loading ? (
                <LottieLoader className="loaderBall" animationData={sportLoaderData} />
            ) : sportBookwithFixture.length > 0 ? (
                sportBookwithFixture.map((sport, index) => (
                    <Box
                        className="forNMrg"
                        sx={{
                            backgroundColor: 'white.main',
                            borderRadius: 2,
                            mb: 2,
                            p: 1,
                            border: '1px solid',
                            borderColor: 'borderBlueColor.main',
                        }}
                        key={sport.id}
                    >
                        {sport?.Locations && sport.Locations?.length > 0 ? (
                            sport.Locations.map((location, index) => (
                                <>
                                    <Box
                                        key={location.id}
                                        className="firstSprtbx"
                                        variant="h6"
                                        fontSize="15px"
                                        p={1}
                                        mt={1}
                                        backgroundColor="#074652"
                                        color="#fff"
                                        display="flex"
                                        alignItems="center"
                                        sx={{ borderRadius: '8px 8px 0 0' }}
                                    >
                                        <span className="sportImg">{getSportIcon(sport.name)}</span>
                                        <span>
                                            {sport.name} - {location.name}
                                        </span>
                                    </Box>
                                    {location?.Leagues && location.Leagues?.length > 0 ? (
                                        location.Leagues.map((league, index) => (
                                            <div key={league.id} className="scoreTableWrapper">
                                                <div className="topBatTitleContainer">
                                                    <div className="tpBetItem topbetItemTitle">
                                                        <span className="topFlagImg">
                                                            <img
                                                                src={
                                                                    location.countryflagimage !== ''
                                                                        ? location.countryflagimage
                                                                        : '/images/_a_default.png'
                                                                }
                                                                alt="flagFrnc"
                                                                width="24"
                                                                height="24"
                                                            />
                                                        </span>{' '}
                                                        <span className="teamTitle">
                                                            {location.name} – {league.name}
                                                        </span>
                                                    </div>
                                                    <div className="tpBetItem">
                                                        <span>1</span>
                                                    </div>
                                                    <div className="tpBetItem">
                                                        <span>X</span>
                                                    </div>
                                                    <div className="tpBetItem">
                                                        <span>2</span>
                                                    </div>
                                                    <div className="tpBetItem">
                                                        <span>1X</span>
                                                    </div>
                                                    <div className="tpBetItem">
                                                        <span>X2</span>
                                                    </div>
                                                    <div className="tpBetItem">
                                                        <span>12</span>
                                                    </div>
                                                    <div className="tpBetItem"></div>
                                                </div>
                                                {league.Fixtures.map((item, index) => (
                                                    <FilteredPreMatchOddsGriditem key={item.id} item={item} />
                                                ))}
                                            </div>
                                        ))
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Box className="lastMinWrapperPadd">
                                                <Typography style={{ fontWeight: '500' }}>No Matches Found</Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </>
                            ))
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box className="lastMinWrapperPadd">
                                    <Typography style={{ fontWeight: '500' }}>No Matches Found</Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                ))
            ) : (
                <Box
                    sx={{
                        backgroundColor: 'white.main',
                        borderRadius: 2,
                        mb: 2,
                        p: 1,
                        border: '1px solid',
                        borderColor: 'borderBlueColor.main',
                    }}
                >
                    <Box className="lastMinWrapperPadd">
                        <Typography style={{ fontWeight: '500' }}>No Matches Found</Typography>
                    </Box>
                </Box>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    tab: state.data.activeTab,
    betslip: state.data[getCurrentBetslip(state.data.activeTab)],
    selections: state.data.sportbookCheckboxValues,
    sportbook: state.data.sportbook,
    AllSportBookData: state.sportsBook,
    initFixture: state.sportsBook.initFixture,
    fixtures: state.sportsBook.fixtures,
});

const mapDispatchToProps = (dispatch) => ({
    onDeleteItem: (index, tab) => dispatch(onDeleteItem(index, tab)),
    onDeleteAllItem: (tab) => dispatch(deleteAllItems(tab)),
    onChangeActiveTab: (tabIndex) => dispatch(onChangeActiveTab(tabIndex)),
    addOrUpdateItem: (newBet, odd, market, tab) => dispatch(addOrUpdateItem(newBet, odd, market, tab)),
});

// Connect the component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(SelectionsPreMatch);
