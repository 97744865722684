import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";

const LeftSidePlayingNow = () => {
  return (
    <>
      <Box backgroundColor="#fff" padding="0 0 10px 0" mt="10px" >
        {/* heading bar */}
        <Box
          component="box"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor={grey[300]}
        >
          <Box display="flex" alignItems="center" >
            <Typography
              component="h4"
              variant="h5"
              fontWeight="500"
              fontSize="large"
              padding="10px"
            >
              Playing Now
            </Typography>
          </Box>
        </Box>

        {/* accordian section start */}

        <Accordion disableGutters  >
          <AccordionSummary
            sx={{
              backgroundColor: grey[200],

            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box display="flex" alignItems="center">
              <img src="/images/sportbook/img1.png" alt="football" />

              <Typography
                component="h4"
                variant="h5"
                fontWeight="500"
                fontSize="16px"
                textTransform="uppercase"
                ml="10px"
              >
                SOCCER (2)
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails >

            {/* row 1 */}
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>

            {/* row 2 */}
            <Divider />
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* 2nd row */}


        <Accordion disableGutters>
          <AccordionSummary
            sx={{
              backgroundColor: grey[200],
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box display="flex" alignItems="center">
              <img src="/images/sportbook/img2.png" alt="Tennis" />

              <Typography
                component="h4"
                variant="h5"
                fontWeight="500"
                fontSize="16px"
                textTransform="uppercase"
                ml="10px"
              >
                TENNIS (2)
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails >

            {/* row 1 */}
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>
                <Typography component="span" variant="body2" fontWeight="500">
                  6:7, 0:1
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  2nd Set
                </Typography>
              </Box>
            </Box>

            {/* row 2 */}
            <Divider />
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>
                <Typography component="span" variant="body2" fontWeight="500">
                  6:7, 0:1
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  2nd Set
                </Typography>
              </Box>
            </Box>

          </AccordionDetails>
        </Accordion>


        {/* accordian row 3 */}

        <Accordion disableGutters  >
          <AccordionSummary
            sx={{
              backgroundColor: grey[200],

            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box display="flex" alignItems="center">
              <img src="/images/sportbook/img1.png" alt="football" />

              <Typography
                component="h4"
                variant="h5"
                fontWeight="500"
                fontSize="16px"
                textTransform="uppercase"
                ml="10px"
              >
                SOCCER (2)
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails >

            {/* row 1 */}
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>

            {/* row 2 */}
            <Divider />
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>





        <Accordion disableGutters  >
          <AccordionSummary
            sx={{
              backgroundColor: grey[200],

            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box display="flex" alignItems="center">
              <img src="/images/sportbook/img1.png" alt="football" />

              <Typography
                component="h4"
                variant="h5"
                fontWeight="500"
                fontSize="16px"
                textTransform="uppercase"
                ml="10px"
              >
                SOCCER (2)
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails >

            {/* row 1 */}
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>

            {/* row 2 */}
            <Divider />
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>






        <Accordion disableGutters  >
          <AccordionSummary
            sx={{
              backgroundColor: grey[200],

            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box display="flex" alignItems="center">
              <img src="/images/sportbook/img1.png" alt="football" />

              <Typography
                component="h4"
                variant="h5"
                fontWeight="500"
                fontSize="16px"
                textTransform="uppercase"
                ml="10px"
              >
                SOCCER (2)
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails >

            {/* row 1 */}
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>

            {/* row 2 */}
            <Divider />
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>






        <Accordion disableGutters  >
          <AccordionSummary
            sx={{
              backgroundColor: grey[200],

            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box display="flex" alignItems="center">
              <img src="/images/sportbook/img1.png" alt="football" />

              <Typography
                component="h4"
                variant="h5"
                fontWeight="500"
                fontSize="16px"
                textTransform="uppercase"
                ml="10px"
              >
                SOCCER (2)
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails >

            {/* row 1 */}
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>

            {/* row 2 */}
            <Divider />
            <Box margin='5px 0px'>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                Position="relative"
              >
                <Typography variant="body2" fontWeight="500">
                  {" "}
                  <span>Canberra FC - WC Wanderers </span>
                </Typography>
                <IconButton
                  aria-label="StarIcon"
                  sx={{ position: "absolute", right: "0" }}
                >
                  <StarIcon fontSize="small" />
                </IconButton>
              </Box>

              <Box display="flex" padding="5px 0">
                <Typography
                  component="span"
                  variant="body2"
                  width="40px"
                  fontWeight="500"
                >
                  0:1
                </Typography>

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="500"
                  ml="auto"
                >
                  31
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

      </Box>
    </>
  );
};

export default LeftSidePlayingNow;
