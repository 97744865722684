import { Box, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
// import { useState } from 'react';

const SideTopLeagues = ({ topleagues }) => {
    const lableStyle = {
        fontSize: '16px',
        marginLeft: '10px',
        justifyContent: 'center',
    };

    const linkStyle = {
        color: '#000',
        cursor: 'pointer',
        display: 'flex',
        textDecoration: 'none',
        // padding:"0"
    };

    if(topleagues && topleagues.length === 0){
        return  <></>
    }

    return (
        <>
            <Box
                className="topLeagueSec"
                sx={{
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    p: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '16px;',
                        fontWeight: 'bold',
                        mb: 1,
                        textTransform: 'uppercase',
                    }}
                >
                    Top Leagues
                </Typography>

                <List>
                    {topleagues &&
                        topleagues.map((leagues, index) => (
                            <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                                <Link key={index} to={`/offer/topbet/${leagues.id}`} style={linkStyle}>
                                    {' '}
                                    <img
                                        src={
                                            leagues.location_icon ? leagues.location_icon : '/images/flags/flagGlb.png'
                                        }
                                        width={25}
                                        height={25}
                                        alt="flag"
                                    ></img>
                                    <Typography style={lableStyle}>{leagues.name}</Typography>{' '}
                                </Link>
                            </ListItem>
                        ))}
                </List>
            </Box>
        </>
    );
};

export default SideTopLeagues;
