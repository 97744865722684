import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { Button, Divider, IconButton, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { fetchDataWithAuth, postData } from '../../../services/api';
import { ToastError, ToastSuccess, formatDate } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';

const schema = yup.object().shape({
    dob: yup.lazy((value) => {
        if (value) {
            return yup
                .string()
                .required('Date of Birth is required')
                .test('is-valid-date', 'You must be at least 18 years old', (selectedDate) => {
                    const minValidDate = dayjs().subtract(18, 'years');
                    return dayjs(selectedDate).isBefore(minValidDate);
                });
        }
        return yup.string();
    }),
    first_name: yup.lazy((value) => {
        if (value) {
            return yup
                .string()
                .matches(/^\S.*$/, 'No starting space allowed')
                .min(3)
                .max(25);
        }
        return yup.string();
    }),
    last_name: yup.lazy((value) => {
        if (value) {
            return yup
                .string()
                .matches(/^\S.*$/, 'No starting space allowed')
                .min(3)
                .max(25);
        }
        return yup.string();
    }),
    password: yup.string().required('Please Enter your Old password').min(6),
    // .matches(/^\S.*$/, 'No starting space allowed')
    // .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
    //     'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    // ),
    new_password: yup.lazy((value) => {
        if (value) {
            return yup.string().min(6, 'Password must be at least 6 characters');
            // .matches(/^[^\s].*/, 'starting spaced is not allowed')
            // .matches(
            //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
            //     'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
            // );
        }
        return yup.string();
    }),
    // email: yup.lazy((value) => {
    //     if (value) {
    //         return yup
    //             .string()
    //             .email('Email must be a valid email')
    //             .matches(/^\S.*$/, 'No starting space allowed');
    //     }

    //     return yup.string();
    // }),
    address: yup.lazy((value) => {
        if (value) {
            return yup
                .string()
                .matches(/^\S.*$/, 'No starting space allowed')
                .min(3)
                .max(100);
        }
        return yup.string();
    }),
});

const MiddleProfileTab = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldOassword] = useState(false);
    const [DOB, setDOB] = useState(null);
    const [date, setDate] = useState('');

    const { user, updateuserBalance, logout } = useContext(UserContext);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        trigger,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        updateuserBalance(user);
        getProfile();
    }, []);

    const getProfile = async () => {
        const response = await fetchDataWithAuth(`/user/profile/detail`, user.token);
        const profileData = response.data[0];
        setValue('first_name', profileData.first_name);
        setValue('last_name', profileData.last_name);
        setValue('address', profileData.address);
        // setValue('email', profileData.email);
        setValue('username', profileData.username);
        setValue('phone', `+${profileData.country_code}  ${profileData.phone}`);
        setDate(dayjs(formatDate(profileData.dob, 'datepicker')));
        setValue('dob', profileData.dob);
    };

    const onSubmit = async (uservalues) => {
        toast.dismiss();
        let data = {
            user_token: user.token,
            data: {
                new_password: uservalues.new_password,
                first_name: uservalues.first_name,
                last_name: uservalues.last_name,
                // email: uservalues.email,
                address: uservalues.address,
                password: uservalues.password,
                phone: uservalues.phone,
                dob: uservalues.dob,
            },
        };
        try {
            const profileData = await postData('/user/profile/update', data);

            if (uservalues.new_password.length && profileData.status_code === 200) {
                ToastSuccess('Password changed successfully');
                logout();
            } else {
                ToastSuccess(profileData.message);
            }
        } catch (error) {
            console.log('error2 :>> ', error);
            ToastError(error.response.data.message);
        }
    };
    let currentDate = new Date();

    let min_date = new Date(currentDate);
    min_date.setFullYear(currentDate.getFullYear() - 18);

    const minDate = dayjs(min_date);

    const onchangedate = async (e) => {
        if (e !== null) {
            const start_date = new Date(e);
            const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
            await trigger('dob');
            setValue('dob', utcstartDate);
            setDate(dayjs(formatDate(e, 'datepicker')));
        }
    };
    return (
        <>
            <Box
                className="profilePage"
                sx={{
                    minHeight: '300px',
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                    p: 2,
                }}
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Box>
                    <Stack direction="row" alignItems="center">
                        <PersonIcon color="black" />
                        <Typography
                            color="black"
                            ml={1}
                            sx={{
                                fontSize: '16px;',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                            }}
                        >
                            Edit your profile
                        </Typography>
                    </Stack>
                </Box>

                {/* input sections starts from here */}

                {/* input sections starts from here */}
                <Box mt={2}>
                    <Typography variant="subtitle1" fontWeight="500" sx={{ fontSize: '17px' }}>
                        Basic Info
                    </Typography>
                    <Divider sx={{ marginTop: '10px' }} />

                    <Stack direction="row" spacing={2} mb="20px" mt="20px">
                        <Box width="50%">
                            <InputLabel
                                sx={{ textTransform: 'uppercase', fontSize: '14px', marginBottom: 0.5 }}
                                htmlFor="first_name"
                            >
                                First Name
                            </InputLabel>

                            <TextField
                                color="black"
                                {...register('first_name')}
                                name="first_name"
                                fullWidth
                                placeholder="Enter First Name"
                                disableUnderline
                            />
                            <Typography variant="body2" color="error">
                                {errors.first_name?.message}
                            </Typography>
                        </Box>
                        <Box width="50%">
                            <InputLabel
                                sx={{ textTransform: 'uppercase', fontSize: '14px', marginBottom: 0.5 }}
                                htmlFor="last_name"
                            >
                                Last Name
                            </InputLabel>

                            <TextField
                                color="black"
                                name="last_name"
                                {...register('last_name')}
                                fullWidth
                                placeholder="Enter Last Name"
                                disableUnderline
                            />
                            <Typography variant="body2" color="error">
                                {errors.last_name?.message}
                            </Typography>
                        </Box>
                    </Stack>

                    <Stack direction="row" spacing={2} mb="20px" mt="20px">
                        <Box width="50%">
                            <InputLabel
                                sx={{ textTransform: 'uppercase', fontSize: '14px', marginBottom: 0.5 }}
                                htmlFor="user_name"
                            >
                                Username
                            </InputLabel>

                            <TextField
                                sx={{ mt: 0.5 }}
                                disabled
                                color="black"
                                {...register('username')}
                                name="user_name"
                                // helperText="Required"
                                fullWidth
                                // value={user.username}
                                variant="outlined"
                                //label="Username"
                                placeholder="Username"
                                // required
                                disableUnderline
                            />
                        </Box>
                        <Box width="50%" className="disabled">
                            <InputLabel
                                sx={{ textTransform: 'uppercase', fontSize: '14px', marginBottom: 0.5 }}
                                htmlFor="phone"
                            >
                                Phone
                            </InputLabel>

                            <TextField
                                disabled
                                color="black"
                                name="phone"
                                {...register('phone')}
                                fullWidth
                                variant="outlined"
                                placeholder="Phone"
                                disableUnderline
                            />
                        </Box>
                    </Stack>

                    {/* 2 */}

                    <Stack direction="row" spacing={2} mb="20px" mt="20px">
                        {/* <Box width="50%">
                            <InputLabel
                                sx={{ textTransform: 'uppercase', fontSize: '14px', marginBottom: 0.5 }}
                                htmlFor="email"
                            >
                                Email
                            </InputLabel>
                            <TextField
                                sx={{}}
                                {...register('email')}
                                name="email"
                                black
                                fullWidth
                                variant="outlined"
                                disableUnderline
                            ></TextField>
                        </Box> */}
                    </Stack>

                    {/* 3 */}
                    <Stack direction="row" spacing={2} mb="20px" mt="20px">
                        <Box width="50%">
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    slotProps={{
                                        textField: {
                                            color: 'black',
                                            size: 'medium',
                                            marginBottom: '20px',
                                            error: false,
                                        },
                                    }}
                                    maxDate={minDate}
                                    value={DOB}
                                    // label="Date of Birth"
                                    format="DD-MM-YYYY"
                                    // onChange={(e) => setDOB(formatDate(e, 'datepicker'))}
                                />
                            </LocalizationProvider> */}

                            <InputLabel
                                sx={{ textTransform: 'uppercase', fontSize: '14px', marginBottom: 0.5 }}
                                htmlFor="dob"
                            >
                                DOB
                            </InputLabel>
                            <Controller
                                name="dob"
                                control={control}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            {...field}
                                            sx={{ width: '100%' }}
                                            // label="Date of Birth"
                                            format="DD-MM-YYYY"
                                            maxDate={minDate}
                                            // disabled
                                            value={date}
                                            {...register('dob')}
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                                onchangedate(newValue);
                                            }}
                                            slotProps={{
                                                // color: 'black',
                                                textField: {
                                                    size: 'medium',
                                                    error: false,
                                                    marginBottom: '20px',
                                                    color: 'black',
                                                },
                                            }}
                                            // onChange={onchangedate}
                                            renderInput={(props) => <TextField {...props} />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                            <Typography variant="body2" color="error">
                                {errors.dob?.message}
                            </Typography>
                        </Box>
                        <Box width="50%">
                            <InputLabel
                                sx={{ textTransform: 'uppercase', fontSize: '14px', marginBottom: 0.5 }}
                                htmlFor="address"
                            >
                                Address
                            </InputLabel>

                            <TextField
                                color="black"
                                {...register('address')}
                                name="address"
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Your Address"
                                // required
                                disableUnderline
                            />
                            <Typography variant="body2" color="error">
                                {errors.address?.message}
                            </Typography>
                        </Box>
                    </Stack>

                    {/* 3 */}

                    {/* 4 */}
                    <Typography variant="subtitle1" fontWeight="500" sx={{ fontSize: '17px' }}>
                        Change Password
                    </Typography>
                    <Divider sx={{ marginTop: '10px' }} />

                    <Stack
                        direction="row"
                        spacing={2}
                        mb="20px"
                        mt="20px"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Box width="50%">
                            <InputLabel
                                sx={{ textTransform: 'uppercase', fontSize: '14px', marginBottom: 0.5 }}
                                htmlFor="password"
                            >
                                Old Password
                            </InputLabel>

                            <TextField
                                color="black"
                                placeholder="Enter Old Password"
                                {...register('password')}
                                type={showOldPassword ? 'text' : 'password'}
                                name="password"
                                sx={{}}
                                fullWidth
                                variant="outlined"
                                // label=" Enter Your Old Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setShowOldOassword(!showOldPassword);
                                                }}
                                            >
                                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Typography variant="body2" color="error">
                                {errors.password?.message}
                            </Typography>
                        </Box>

                        <Box width="50%">
                            <InputLabel
                                sx={{ textTransform: 'uppercase', fontSize: '14px', marginBottom: 0.5 }}
                                htmlFor="new_password"
                            >
                                New Password
                            </InputLabel>

                            <TextField
                                color="black"
                                placeholder="Enter New Password"
                                {...register('new_password')}
                                type={showPassword ? 'text' : 'password'}
                                name="new_password"
                                sx={{}}
                                // helperText="Required"
                                fullWidth
                                variant="outlined"
                                // label=" Enter Your New Password"
                                // required
                                // disableUnderline
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Typography variant="body2" color="error">
                                {errors.new_password?.message}
                            </Typography>
                        </Box>
                    </Stack>

                    <Stack>
                        <Box width="50%" display="flex" justifyContent="flex-end" ml="auto">
                            <Button
                                type="submit"
                                disableElevation
                                variant="contained"
                                sx={{
                                    fontWeight: 'bold',
                                    backgroundColor: '#074652',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#074652' },
                                }}
                                size="medium"
                                color="primary"
                            >
                                Update Profile
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};

export default MiddleProfileTab;
