import { Badge, Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { deleteAllItems, setActiveTab } from '../../../../redux/actions';
import BetSlip from './BetSlip';

const BetTab = ({ type, betslip1, betslip2, betslip3, livebetslip, setActiveTab, activeTab }) => {
    // const dispatch = useDispatch();

    const handleTabs = (e, val) => {
        // dispatch(setActiveTab(val,activeTab)); // Dispatch the action to set the active tab
        setActiveTab(val);
    };

    if (type === 'live')
        return (
            <>
                <Box>
                    <BetSlip />
                </Box>
            </>
        );
    else
        return (
            <>
                <Box>
                    <Tabs
                        className="betslipHolder"
                        sx={{
                            backgroundColor: '#074652',
                            minHeight: '40px',
                            '& .MuiTabs-indicator': { display: 'none' },
                        }}
                        value={activeTab}
                        onChange={handleTabs}
                        variant="fullWidth"
                    >
                        <Tab
                            label="1"
                            sx={{
                                color: '#fff',
                                minHeight: '40px',
                                textDecoration: 'none',
                                '&.Mui-selected': {
                                    backgroundColor: 'white.main',
                                    borderBottom: 0,
                                    fontWeight: '600',
                                },
                            }}
                            icon={
                                <Badge
                                    sx={{ left: '10px', top: '6px', backgroundColor: '#3f5e1c' }}
                                    badgeContent={betslip1.length}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {/* Add your icon component here */}
                                </Badge>
                            }
                        />
                        <Tab
                            label="2"
                            sx={{
                                color: '#fff',
                                minHeight: '40px',
                                '&.Mui-selected': {
                                    backgroundColor: 'white.main',
                                    fontWeight: '600',
                                },
                            }}
                            icon={
                                <Badge
                                    sx={{ left: '10px', top: '6px' }}
                                    badgeContent={betslip2.length}
                                    color="secondary"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {/* Add your icon component here */}
                                </Badge>
                            }
                        />
                        <Tab
                            label="3"
                            sx={{
                                color: '#fff',
                                minHeight: '40px',
                                '&.Mui-selected': {
                                    backgroundColor: 'white.main',
                                    fontWeight: '600',
                                },
                            }}
                            icon={
                                <Badge
                                    sx={{ left: '10px', top: '6px' }}
                                    badgeContent={betslip3.length}
                                    color="secondary"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {/* Add your icon component here */}
                                </Badge>
                            }
                        />
                    </Tabs>

                    <TabPanel value={activeTab} index={activeTab}>
                        <Box>
                            <BetSlip activeTab={activeTab + 1} />
                        </Box>
                    </TabPanel>

                    {/* <TabPanel value={activeTab} index={1}>
                        <Box>
                            <BetSlip />
                        </Box>
                    </TabPanel>

                    <TabPanel value={activeTab} index={2}>
                        <Box>
                            <BetSlip />
                        </Box>
                    </TabPanel> */}
                </Box>
            </>
        );
};

function TabPanel(props) {
    // const { children, value, index } = props;
    // return <>{value === index && <div>{children}</div>}</>;

    const { children, value, index } = props;
    return <div>{value === index && <div>{children}</div>}</div>;
}

const mapStateToProps = (state) => ({
    activeTab: state.data.activeTab,
    betslip1: state.data.betslip1,
    betslip2: state.data.betslip2,
    betslip3: state.data.betslip3,
    livebetslip: state.data.livebetslip,
});

const mapDispatchToProps = (dispatch) => ({
    onDeleteAllItem: (tab) => dispatch(deleteAllItems(tab)),
    setActiveTab: (tabId) => dispatch(setActiveTab(tabId)), // Add this line to dispatch setActiveTab
});

// Connect the component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(BetTab);
