import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { fetchDataWithAuth, postData } from '../../../services/api';
import { ToastError, ToastSuccess, formatNumber, generateHash } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';
import OtpModel from '../Auth/OtpModel';
import BankDetailsModal from './BankDetailsModal';
const schema = yup.object().shape({
    amount: yup
        .string()
        .required('Amount is required')
        // .min(2, 'Amount should have atleast 2 digits')
        .test('greater-than-zero', 'Value must be greater than or equal to 20 ETB', (value) => {
            return parseInt(value) >= 20;
        })
        .max(8, 'maximum 8 digits allowed'),
});

const ChapaFormWithdraw = ({ type }) => {
    const { user, updateBalance, login } = useContext(UserContext);
    const [isClicked, setIsClicked] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [disable, setDisable] = useState(false);
    const [seconds, setSeconds] = useState(5 * 60);
    const [otpVal, setOtpVal] = useState('');
    const [modal, setModal] = useState(false);
    const [Amount, setAmount] = useState('');
    const [bank, setBank] = useState('');
    const [FetchBank, setFetchBank] = useState([]);
    const [ResetForm, SetResetForm] = useState(false);
    const [disableBtn, setDesableBtn] = useState(false);
    const [OtpBtn, setOtpBtn] = useState(false);
    const [verifyId, setVerifyId] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();

    const FetchBankList = async () => {
        const data = {
            user_token: user.token,
            data: {
                payment_type: 1,
            },
        };
        try {
            const response = await postData('/user/bank/get', data);
            setFetchBank(response.data);
        } catch (error) {
            console.log('FetchBankList error==>', error);
        }
    };
    const onIntiateWithdraw = async (withdraw) => {
        toast.dismiss();
        setIsClicked(true);
        if (Number(withdraw.amount) >= 20 && Number(withdraw.amount) < Math.floor(parseFloat(user.balance))) {
            setAmount(withdraw.amount);

            try {
                const response = await fetchDataWithAuth('/user/sent/otp', user.token);
                ToastSuccess(response.message);
                setTimeout(() => {
                    setOpen(true);
                    setIsClicked(false);
                }, 700);
            } catch (error) {
                ToastError(error.response.data.message);
                setIsClicked(false);
            }
        } else {
            // Handle the case where the conditions are not met (e.g., show an error message)
            ToastError(`Withdraw amount must be between  20 ETB  and less than  ${formatNumber(user.balance)} ETB`);
            setIsClicked(false);
        }
    };
    const onSubmitOtp = async () => {
        try {
            if (otpVal !== '') {
                if (otpVal.length !== 6) {
                    setError('OTP should be 6 digits long.');
                    return;
                }
                if (!/^\d+$/.test(otpVal)) {
                    setError('OTP should contain only numbers.');
                    return;
                }
            } else {
                setError('Please Enter OTP');
                return;
            }
            setOtpBtn(true);
            const data = {
                user_token: user.token,
                data: {
                    otp: otpVal,
                },
            };
            const OtpResponse = await postData('/user/verify/otp', data);
            ToastSuccess(OtpResponse.message);
            if (OtpResponse.status_code === 200) {
                setVerifyId(OtpResponse.data[0].verify_id);
                FetchBankList();
                setTimeout(() => {
                    setOpen(false);
                    setOtpVal('');
                    setDesableBtn(false);
                    setOtpBtn(false);
                    setModal(true);
                    reset();
                }, 600);
            }
        } catch (error) {
            console.log('otp error:>> ', error);
            ToastError(error.response.data.message);
            setTimeout(() => {
                setOtpBtn(false);
            }, 1000);
        }
    };
    const handleClose = async () => {
        setOtpBtn(false);
        toast.dismiss();
        // const data = {
        //     data: {
        //         user_id: userId.data[0].user_id,
        //     },
        // };
        // try {
        //     const cancleRequestRespone = await postData('/auth/user/cancel', data);
        //     if (cancleRequestRespone.status_code === 200) {
        //         setOpen(false);
        //         setOtpVal('');
        //     }

        //     toast.success(cancleRequestRespone.message, {
        //         position: 'top-center',
        //         autoClose: 500,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         theme: 'colored',
        //     });
        ToastSuccess('Cancelled successfully');
        setOpen(false);
        setOtpVal('');
        setError('');
        setDisable(false);
        setTimeout(() => {
            setSeconds(5 * 60);
        }, 500);
        // } catch (error) {
        //     console.log('otp error:>> ', error);
        // }
    };

    const handleOtpChange = (newValue) => {
        setOtpVal(newValue);
        setError('');
    };
    const onResendOtp = async () => {
        setDisable(true);
        handleOtpChange(''); // reset the value and error
        try {
            const profileData = await fetchDataWithAuth('/user/resent/otp', user.token);
            if (profileData.status_code === 200) {
                ToastSuccess(profileData.message);
                setTimeout(() => {
                    setDisable(false);
                }, 300000);
                setSeconds(5 * 60);
                setOtpBtn(false);
            }
        } catch (error) {
            console.log('resend :>> ', error);
        }
    };
    const handleModalClose = () => {
        setModal(false);
        setDisable(false);
    };
    const onchangeBank = (event) => {
        setBank(event.target.value);
    };

    const onSubmitBankDetails = async (paymentDetails) => {
        setDesableBtn(true);
        toast.dismiss();
        if (verifyId !== '') {
            try {
                const data = {
                    user_token: user.token,
                    data: {
                        account_name: paymentDetails.account_name,
                        account_number: paymentDetails.account_number,
                        payment_type: 1,
                        amount: Amount,
                        verify_id: verifyId,
                        bank_code: bank,
                    },
                };
                const hashSignature = generateHash(data['data']);
                data.data.hash = hashSignature;

                const response = await postData('/user/transfer', data);
                if (response.status_code === 200) {
                    setModal(false);
                    SetResetForm(true);
                    setDesableBtn(false);
                    setDisable(false);
                    ToastSuccess(response.message);
                    setTimeout(() => {
                        navigate('/user/Transactions');
                    }, 1000);
                }
                if (response.status_code === 422) {
                    setModal(false);
                    setDisable(false);
                    ToastError(response.data[0].message);
                }
            } catch (error) {
                setModal(false);
                setDisable(false);
                ToastError(error.response.data.message);
            }
            setIsClicked(false);
        } else {
            setModal(false);
            setDisable(false);
            ToastError('Invalid OTP Please try again later');
        }
    };
    return (
        <>
            <Box
                component="form"
                sx={{
                    backgroundColor: '#e3fbff',
                    padding: 1,
                    margin: '0 auto',
                    marginTop: '20px',
                    border: '1px solid #B2E576',
                    borderRadius: '4px',
                }}
                onSubmit={handleSubmit(onIntiateWithdraw)}
            >
                {/* <img src="/images/chapa.svg" className="chapaFormLogo" alt="Chapa" /> */}

                <FormControl fullWidth sx={{ m: 1 }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: '500',
                            textTransform: 'uppercase',
                            marginBottom: '10px',
                            borderBottom: '1px solid #B2E576',
                            paddingBottom: '10px',
                        }}
                    >
                        Your current account balance is :{' '}
                        <span style={{ fontSize: '17px' }}> {formatNumber(user.balance)} ETB</span>{' '}
                    </Typography>
                    <Stack direction="row" alignItems="flex-start">
                        <Typography
                            sx={{
                                fontSize: '16px;',
                                fontWeight: '500',
                                position: 'relative',
                                width: '210px',
                                marginTop: '11px',

                                //padding:'10px'
                            }}
                        >
                            REQUEST AMOUNT
                        </Typography>

                        <Box>
                            <TextField
                                aria-describedby="Request Amount"
                                aria-label="Request Amount"
                                className="withdrawTxtFld"
                                sx={{ backgroundColor: '#fff' }}
                                id="outlined-adornment-amount"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">ETB</InputAdornment>,
                                }}
                                placeholder="Amount"
                                {...register('amount')}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]*/g, '');
                                }}
                            ></TextField>

                            <Typography variant="body2" color="error">
                                {errors.amount?.message}
                            </Typography>
                        </Box>

                        <Button
                            variant="contained"
                            disableElevation
                            disabled={isClicked}
                            type="submit"
                            sx={{ fontWeight: '500', ml: 2, paddingTop: '13px', paddingBottom: '13px' }}
                        >
                            {type}
                        </Button>
                    </Stack>
                </FormControl>
            </Box>
            <OtpModel
                open={open}
                OtpBtn={OtpBtn}
                handleClose={handleClose}
                onSubmitOtp={onSubmitOtp}
                handleOtpChange={handleOtpChange}
                otpVal={otpVal}
                onResendOtp={onResendOtp}
                setSeconds={setSeconds}
                seconds={seconds}
                disable={disable}
                error={error}
            />
            <BankDetailsModal
                modal={modal}
                disableBtn={disableBtn}
                handleModalClose={handleModalClose}
                onSubmitBankDetails={onSubmitBankDetails}
                onchangeBank={onchangeBank}
                amount={Amount}
                setAmount={setAmount}
                bank={bank}
                setBank={setBank}
                FetchBank={FetchBank}
                resetForm={ResetForm}
            />
        </>
    );
};

export default ChapaFormWithdraw;
