import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { default as Grid, default as Item } from '@mui/material/Unstable_Grid2'; // Grid version 2
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import {
    addOrUpdateItem,
    checkBetSlip,
    onDeleteItem,
    setFilterDateValue,
    setSportbooks,
    setTimeFilter,
} from '../../redux/actions';

import FallBackLoader from '../../loader/FallBackLoader';
import {
    setFixtureData,
    setFixtureDataByTimeFilter,
    setSportsBookData,
    setTimeFilterForSportBook,
} from '../../redux/SportBook/actions';
import { fetchData, postDataWithoutAuth } from '../../redux/actions';
import { timeDiffForApiCall } from '../../services/helper';
import { UserContext } from '../contexts/UserContext';
import Footer from '../layout/Footer';
import MiddleContentFilteredPreMatch from '../pages/FilteredPreMatch/MiddleContentFilteredPreMatch';
import LiveGamesMiddleContent from '../pages/LiveGames/LiveGamesMiddleContent';
import LiveSoccer from '../pages/LiveGames/LiveMatches';
import LiveMatch from '../pages/MatchDetail/LiveMatch';
import LivePlayingNowMenu from '../pages/MatchDetail/LivePlayingNowMenu';
import BranchDeposit from '../pages/Payments/BranchDeposit';
import BranchWithdraw from '../pages/Payments/BranchWithdraw';
import ChapaDeposit from '../pages/Payments/ChapaDeposit';
import ChapaWithdraw from '../pages/Payments/ChapaWithdraw';
import SantimPayDeposit from '../pages/Payments/SantimPayDeposit';
import SantimPayWithdraw from '../pages/Payments/SantimPayWithdraw';
import SuccessPayment from '../pages/Payments/SuccessPayment';
import TopLeaguesMiddleContent from '../pages/TopBet/TopLeaguesMiddleContent';
import MiddleDepositsAndWithdrawalsTab from '../pages/User/MiddleDepositsAndWithdrawalsTab';
import MiddleLiveTicketsTab from '../pages/User/MiddleLiveTicketsTab';
import MiddleProfileTab from '../pages/User/MiddleProfileTab';
import MiddleTicketsTab from '../pages/User/MiddleTicketsTab';
import MiddleTransactionsTab from '../pages/User/MiddleTransactionsTab';
import MiddleVirtualsTicketTab from '../pages/User/MiddleVirtualsTicketTab';
import Support from '../pages/User/Support';
import Virtuals from '../pages/Virtualsgames/Virtuals';
import RightBetslip from './Betslip/RightBetslip';
import Sidebar from './Sidebar/Sidebar';
const PreMatch = lazy(() => import('../pages/MatchDetail/PreMatch'));
const MiddleContentTab = lazy(() => import('../pages/Home/MiddleContentTab'));

const ContentLayout = ({
    setSportsBook,
    setFixture,
    sportsList,
    AllSportBookData,
    fixturesTimeStamp,
    initFixture,
    setFixtureDataByTimeFilter,
    setTimeFilterForSportBook,
    ...props
}) => {
    const [topLeaguesFetched, setTopLeaguesFetched] = useState(false);
    const livebetslip = props.livebetslip;
    const activeTab = props.activeTab;
    const sportbook = props.sportbook;

    const [loading, setLoading] = useState(false);
    const [topleagues, setTopLeagues] = useState([]);

    const { user } = useContext(UserContext);

    //new code for indexDB start

    const fetchFixturesBySportId = async (currentSportId = '', value = 'All', isLoader = true) => {
        try {
            const activeSportId = currentSportId; //|| activeSportsTab?.id
            const lengthOfPreMatchListBySportId =
                initFixture && initFixture?.[activeSportId] && initFixture[activeSportId].length === 0;

            const getTimeStampForPreMatchBySportId = fixturesTimeStamp && fixturesTimeStamp?.[activeSportId];
            const isPreMatchTimeStampExpired = timeDiffForApiCall(getTimeStampForPreMatchBySportId);

            if (
                (lengthOfPreMatchListBySportId ||
                    lengthOfPreMatchListBySportId === undefined ||
                    isPreMatchTimeStampExpired ||
                    isPreMatchTimeStampExpired === undefined) &&
                value === 'All'
            ) {
                const fixturesData = await postDataWithoutAuth('/auth/prematch', {
                    data: {
                        sport_id: activeSportId,
                        time_filter: 'ALL', //value || filtervalue || moment.utc(),
                    },
                });

                const { data } = fixturesData.payload;
                setFixture({ sport_id: activeSportId, fixtures: data });
            } else {
                if (sportsList.length > 0) {
                    for (const [index, sportDetail] of sportsList.entries()) {
                        setFixtureDataByTimeFilter({ sport_id: sportDetail.id, filter: value });
                    }
                }
            }
        } catch (error) {
            console.log('error :>> ', error);
        } finally {
            //   setPreMatchLoading(false);
        }
    };

    //new code for indexDB end

    const getTopLeagues = async () => {
        const response = await fetchData(`/auth/topleagues/get`);
        setTopLeagues(response.payload.data);
        setTopLeaguesFetched(true); // Mark data as fetched
    };

    useEffect(() => {
        if (!topLeaguesFetched) {
            if (props.content === 'MiddleContentTab' || 'PreMatch') getTopLeagues();
        }
        // fetchDataFromApi('All');

        // if(sportsList?.length === 0){
        //     preFetchData()
        // }else if(sportsList?.length > 0){
        //     fetchFixturesBySportId('', 'All');
        // }
    }, []);

    // const handleDeleteItem = (itemId, tab) => {
    //     props.onDeleteItem(itemId, tab);
    // };

    // const handleAddOrUpdateItem = (newBet, odd, market, tab) => {
    //     props.addOrUpdateItem(newBet, odd, market, tab);
    // };

    const handleSetTimeFilter = (value, dateValue = null) => {
        props.setTimeFilter(value);
        if (value === 'Date') {
            fetchFixturesBySportId('', dateValue);
            setTimeFilterForSportBook(dateValue);
        } else {
            setTimeFilterForSportBook(value.toString());
            fetchFixturesBySportId('', value.toString());
        }
    };

    const iFrameSrc = user
        ? `${process.env.REACT_APP_IFRAME_URL}p=${user.token}&i=${user.username}`
        : `${process.env.REACT_APP_IFRAME_URL}dp=1`;
    var findComponent = (item) => {
        switch (item) {
            case 'MiddleContentTab':
                return (
                    <Suspense fallback={<FallBackLoader />}>
                        {' '}
                        <MiddleContentTab />{' '}
                    </Suspense>
                );
            case 'TopLeaguesMiddleContent':
                return <TopLeaguesMiddleContent />;
            case 'PreMatch':
                return (
                    <Suspense fallback={<FallBackLoader />}>
                        {' '}
                        <PreMatch />{' '}
                    </Suspense>
                );
            case 'LiveMatch':
                return <LiveMatch />;
            case 'LivePlayingNowMenu':
                return <LivePlayingNowMenu />;
            case 'LiveGamesMiddleContent':
                return <LiveGamesMiddleContent />;
            case 'Virtuals':
                return <Virtuals iFrameSrc={iFrameSrc} />;
            case 'MiddleTicketsTab':
                return <MiddleTicketsTab />;
            case 'MiddleLiveTicketsTab':
                return <MiddleLiveTicketsTab />;
            case 'MiddleVirtualsTicketTab':
                return <MiddleVirtualsTicketTab />;
            case 'MiddleTransactionsTab':
                return <MiddleTransactionsTab />;
            case 'MiddleSupportTab':
                return <Support />;
            case 'MiddleProfileTab':
                return <MiddleProfileTab />;
            case 'MiddleDepositsAndWithdrawalsTab':
                return <MiddleDepositsAndWithdrawalsTab />;
            case 'MiddleContentFilteredPreMatch':
                return <MiddleContentFilteredPreMatch type={props.type} />;
            case 'BranchDeposit':
                return <BranchDeposit />;
            case 'BranchWithdraw':
                return <BranchWithdraw />;
            case 'ChapaDeposit':
                return <ChapaDeposit />;
            case 'ChapaWithdraw':
                return <ChapaWithdraw />;
            case 'SantimPayDeposit':
                return <SantimPayDeposit />;
            case 'SantimPayWithdraw':
                return <SantimPayWithdraw />;
            case 'SuccessPayment':
                return <SuccessPayment />;
            default:
                return null; //You might want to return something else here//
        }
    };
    // formiddle section//
    return (
        <>
            <Container
                maxWidth={false}
                className={props.content === 'Virtuals' ? 'contentMrgTop' : 'ContainerBig contentMrgTop'}
                sx={{ mt: 2 }}
            >
                <Grid container spacing={2} className="forPaymentpage">
                    {props.leftSection === 'false' ||
                    props.content === 'SuccessPayment' ||
                    props.content === 'Virtuals' ? (
                        <></>
                    ) : props.leftSection === 'livegames' ? (
                        <Grid className="leftbarItem sheduleTab" style={{ flex: '0 0 310px' }}>
                            <LiveSoccer />
                        </Grid>
                    ) : (
                        <Grid className="leftbarItem" style={{ flex: '0 0 310px' }}>
                            <Item>
                                <Box>
                                    <Sidebar
                                        type={props.leftSection}
                                        selectedTab={props.selectedTab}
                                        loading={loading}
                                        setTimeFilter={handleSetTimeFilter}
                                        topleagues={topleagues}
                                    />
                                </Box>
                            </Item>
                        </Grid>
                    )}

                    {/* xs={props.leftSection === 'false' ? 10 : 8} */}
                    <Grid
                        sx={{ flexWrap: 'wrap' }}
                        className={
                            props.leftSection === 'false'
                                ? props.content === 'Virtuals'
                                    ? 'oneColumn centerbarItem'
                                    : 'twoColumn centerbarItem'
                                : 'threeColumn centerbarItem'
                        }
                    >
                        <Item>
                            <Box sx={{}}>{findComponent(props.content)}</Box>
                        </Item>
                    </Grid>
                    <Grid className="rightbarItem" style={{ flex: '0 0 310px' }}>
                        <Item>
                            <Box>
                                {props.betslip === true ? (
                                    <RightBetslip
                                        type={activeTab}
                                        betType={props.content}
                                        activeTab={activeTab}
                                        livebetslip={livebetslip}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Item>
                    </Grid>
                </Grid>
                {/* footer start */}
                <Footer />
            </Container>
        </>
    );
};
const mapStateToProps = (state) => ({
    betslip1: state.data.betslip1,
    betslip2: state.data.betslip2,
    betslip3: state.data.betslip3,
    livebetslip: state.data.livebetslip,
    sportbook: state.data.sportbook,
    activeTab: state.data.activeTab,
    sportbookCheckboxValues: state.data.sportbookCheckboxValues,
    //new code for indexDB start
    sportsList: state.sportsBook.sports,
    fixturesTimeStamp: state.sportsBook.fixturesTimeStamp,
    initFixture: state.sportsBook.initFixture,
    AllSportBookData: state.sportsBook,
    //new code for indexDB end
});

const mapDispatchToProps = (dispatch) => ({
    onDeleteItem: (itemId, activeTab) => dispatch(onDeleteItem(itemId, activeTab)),
    setSportbook: (data) => dispatch(setSportbooks(data)),
    addOrUpdateItem: (newBet, odd, market, tab) => dispatch(addOrUpdateItem(newBet, odd, market, tab)),
    setTimeFilter: (value) => dispatch(setTimeFilter(value)),
    setFilterDateValue: (value) => dispatch(setFilterDateValue(value)),
    // setLiveMatchOdds: (data) => dispatch(setLiveMatchOdds(data)),
    checkBetSlip: (tab, newbetslip) => dispatch(checkBetSlip(tab, newbetslip)),
    //new code for indexDB start
    setSportsBook: (sportBookData) => dispatch(setSportsBookData(sportBookData)),
    setFixture: (data) => dispatch(setFixtureData(data)),
    setFixtureDataByTimeFilter: (data) => dispatch(setFixtureDataByTimeFilter(data)),
    setTimeFilterForSportBook: (value) => dispatch(setTimeFilterForSportBook(value)),

    //new code for indexDB end
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentLayout);
