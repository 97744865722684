import React, { useContext } from 'react';

import { Box } from '@mui/system';
import { UserContext } from '../../contexts/UserContext';
import ContentLayout from '../../layout/ContentLayout';

const Payments = ({ path }) => {
    const { user, updateuserBalance } = useContext(UserContext);
   
    return (
        <>
            <Box className="payColumn">
                <ContentLayout leftSection="profile" selectedTab="DepositsAndWithdrawals" content={path} />
            </Box>
        </>
    );
};

export default Payments;
