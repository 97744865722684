import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchDataWithAuth } from '../../../services/api';
import { ToastError } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';

const SuccessPayment = () => {
    const { user, updateBalance } = useContext(UserContext);
    const [transaction, setTransaction] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchDataFromApi = async (storedTransactionId) => {
        toast.dismiss();
        try {
            setLoading(true);
            const fetchedData = await fetchDataWithAuth(`/user/transaction/check/${storedTransactionId}`, user.token);
            // console.log('fetchedData.data[0] :>> ', fetchedData.data[0]);
            setTransaction(fetchedData.data[0]);
            updateBalance(fetchedData.data[0].user_balance);
            setLoading(false);
        } catch (error) {
            console.log('error :>> ', error);
            ToastError(error.response.data.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        const storedTransactionId = localStorage.getItem('transactionId');

        if (storedTransactionId) {
            fetchDataFromApi(storedTransactionId);
            localStorage.removeItem('transactionId');
        } else {
            navigate('/');
        }
    }, []);

    return (
        <Stack
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="depositWithdrawlPage"
            direction="row"
            sx={{
                padding: '20px',
                minHeight: '300px',
                backgroundColor: '#fff',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'borderBlueColor.main',
                display: 'flex',
                paddingBottom: '80px',
            }}
        >
            {loading ? (
                <CircularProgress />
            ) : (
                <Box sx={{ width: '600px', margin: '0 auto' }}>
                    <Stack alignItems="center" sx={{ marginBottom: '20px' }}>
                        {transaction?.status === 'success' ? (
                            <img
                                src="/images/success_Check.png"
                                width="121"
                                height="124"
                                className=""
                                alt="status"
                            ></img>
                        ) : (
                            <img src="/images/failed.png" width="129" height="139" className="" alt="status"></img>
                        )}
                        <Typography
                            variant="h6"
                            sx={{ color: '#7BC285', textAlign: 'center', textTransform: 'capitalize' }}
                        >
                            {transaction?.status}
                            {/* Transition completed successfully */}
                            <Typography variant="subtitle2" sx={{ color: '#656565' }}>
                                Thank you for using our services
                            </Typography>
                        </Typography>
                    </Stack>

                    {transaction && (
                        <>
                            <Paper elevation={0} variant="outlined" className="branchTable">
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Transaction Ref</TableCell>
                                            <TableCell>
                                                <b> {transaction?.tx_ref} </b>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>User Email</TableCell>
                                            <TableCell>
                                                <b>{transaction?.email}</b>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell> Total Amount </TableCell>
                                            <TableCell>
                                                <b>
                                                    {transaction?.amount} {transaction.currency}
                                                </b>
                                            </TableCell>
                                        </TableRow>

                                        {transaction.status === 'success' && (
                                            <>
                                                <TableRow>
                                                    <TableCell> Amount Credited </TableCell>
                                                    <TableCell>
                                                        <b>
                                                            {transaction?.add_balance} {transaction.currency}
                                                        </b>
                                                    </TableCell>
                                                </TableRow>

                                                {/* <TableRow>
                                                    <TableCell> Charges</TableCell>
                                                    <TableCell>
                                                        <b>
                                                            {transaction?.charge} {transaction.currency}
                                                        </b>
                                                    </TableCell>
                                                </TableRow> */}
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </Paper>
                            <Button
                                component={Link}
                                to="/"
                                variant="contained"
                                disableElevation
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#5FD3E9',
                                    },
                                    margin: '30px auto 0 auto ',
                                    display: 'block',
                                    width: '180px',
                                }}
                            >
                                Back to home page
                            </Button>
                        </>
                    )}
                </Box>
            )}
        </Stack>
    );
};

export default SuccessPayment;
