import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { fetchData, postDataWithoutAuth, preMatchBetSlipUpdate, socketInit } from "../../redux/actions";
import {
  setFixtureData,
  setFixtureDataByTimeFilter,
  setFixturesDataByTimeStamp,
  setSportsBookData,
} from "../../redux/SportBook/actions";
import { getLocalStorage, setLocalStorage, timeDiffForApiCall } from "../../services/helper";
import { useSocket } from "../contexts/SocketContext";

export const UniversalSportBook = (props) => {
  const {
    setSportsBook,
    setFixture,
    sportsList,
    AllSportBookData,
    fixturesTimeStamp,
    initFixture,
    setFixtureDataByTimeFilter,
    setFixturesDataByTimeStamp,
    setSocketInit,
    isSocketInit,
    preMatchBetSlipUpdateWithLatestOdd,
  } = props;
  const socket = useSocket();
  const [chunksQue, setChunksQue] = useState({
    cc: -1,
    tc: 0,
    data: [] 
  })

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  /* first time user load data - start */
  const fetchFixturesBySportId = async (
    currentSportId = "",
    value = "All",
    isLoader = true
  ) => {
    try {
      const activeSportId = currentSportId; //|| activeSportsTab?.id
      const lengthOfPreMatchListBySportId =
        initFixture &&
        initFixture?.[activeSportId] &&
        initFixture[activeSportId].length === 0;

      const getTimeStampForPreMatchBySportId =
        fixturesTimeStamp && fixturesTimeStamp?.[activeSportId];
      const isPreMatchTimeStampExpired = timeDiffForApiCall(
        getTimeStampForPreMatchBySportId
      );

      if (
        (lengthOfPreMatchListBySportId ||
          lengthOfPreMatchListBySportId === undefined ||
          isPreMatchTimeStampExpired ||
          isPreMatchTimeStampExpired === undefined) &&
        value === "All"
      ) {
        const fixturesData = await postDataWithoutAuth("/auth/prematch", {
          data: {
            sport_id: activeSportId,
            time_filter: "ALL", //value || filtervalue || moment.utc(),
          },
        });

        const { data } = fixturesData.payload;
        setFixture({ sport_id: activeSportId, fixtures: data });
      } else {
        if (sportsList.length > 0) {
          for (const [index, sportDetail] of sportsList.entries()) {
            setFixtureDataByTimeFilter({
              sport_id: sportDetail.id,
              filter: value,
            });
          }
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
      //   setPreMatchLoading(false);
    }
  };

  const preFetchData = async () => {
    try {
      const fetchedData = await fetchData("/auth/prefetch/sports");
      let {
        sports: sportsData = [],
        locations: sportLocations = [],
        leagues: locationLeagues = [],
        markets: homePageMarkets = [],
      } = fetchedData?.payload?.data?.[0] || {};
      sportsData = sportsData.sort((a, b) => {
        if (a.name === "Football") {
          return -1; // "Football" comes before other sports
        } else if (b.name === "Football") {
          return 1; // "Football" comes after other sports
        } else {
          return a.name.localeCompare(b.name); // Sort alphabetically for other sports
        }
      });
      setSportsBook({
        sports: sportsData,
        locations: sportLocations,
        leagues: locationLeagues,
        markets: homePageMarkets,
      });

      for (const [index, sportDetail] of sportsData.entries()) {
        await delay(2000);
        await fetchFixturesBySportId(sportDetail.id, "All", false);
      }
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
        setSocketInit(true)
        setLocalStorage('lastVisitTimestamp', moment.utc().format());
    }
  };

  useEffect(() => {
    if (sportsList?.length > 0) {
    //   fetchFixturesBySportId("", "All");
        for (const [index, sportDetail] of sportsList.entries()) {
            setFixtureDataByTimeFilter({
                sport_id: sportDetail.id,
                filter: 'All',
            });
        }
    }
  }, []);

  useEffect(() => {
    // Check if the current date and time have passed the target date and time
    const intervalId = setInterval(() => {
      let isSportTimeStampExpired = timeDiffForApiCall(
        AllSportBookData.sportsTimeStamp
      );
      if (isSportTimeStampExpired) {
        preFetchData();
      }
    }, 1 * 60 * 1000);

    let isSportTimeStampExpired = timeDiffForApiCall(
      AllSportBookData.sportsTimeStamp
    );

    if (sportsList.length === 0 || isSportTimeStampExpired) {
      preFetchData();
    }

    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportsList, AllSportBookData]);

  /* first time user load data - end */

  /* =========== Based on socket data and user based data load - start =============== */
  const getFixtureByTimeStamp = async (activeTab, val) => {
    const lastVisitTimestamp = localStorage.getItem('lastVisitTimestamp');

    const fixturesData = await postDataWithoutAuth("/auth/prematch", {
      data: {
        sport_id: activeTab,
        // time_filter: 'ALL',
        timestamp:  lastVisitTimestamp // '2024-01-01T15:00:00.000Z'// lastVisitTimestamp,
      },
    });
    if(fixturesData?.data?.length > 0){
        setFixturesDataByTimeStamp({
            sport_id: activeTab,
            fixtures: fixturesData.data,
        });
    }
        
    return;
  };
  const fetchAllFixturesSequentially = async () => {
    try {
      for (const [index, sportDetail] of sportsList.entries()) {
        let lengthOfPreMatchListBySportId = false

        if(AllSportBookData.filtervalue === 'All'){
          lengthOfPreMatchListBySportId = initFixture &&
          initFixture?.[sportDetail.id] &&
          initFixture[sportDetail.id].length > 0;
        }
        else{
          lengthOfPreMatchListBySportId = AllSportBookData.fixtures &&
          AllSportBookData.fixtures?.[sportDetail.id] &&
          AllSportBookData.fixtures[sportDetail.id].length > 0;
        }

        if (lengthOfPreMatchListBySportId) {
          await getFixtureByTimeStamp(sportDetail.id, AllSportBookData.filtervalue);
          await delay(2000);
        }
      }
    } catch (error) {
      console.error("Error fetching fixtures:", error);
    }
    finally{
      setSocketInit(true)
      setLocalStorage('lastVisitTimestamp', moment.utc().format());
    }
  };

  const isMoreThanMinutes = () => {
    const lastVisitTimestamp = getLocalStorage('lastVisitTimestamp');
    const timeDiff = getLocalStorage('socket_interval') || 2
    if (lastVisitTimestamp) {
      const currentTimestamp = moment.utc();
      const diffMinutes = currentTimestamp.diff(lastVisitTimestamp, 'minutes');
      return diffMinutes > timeDiff; //if diff < than time diff rhen return true
    }

    return false;
  };

  const checkUserActivity = () => {

    if (
        isSocketInit === false
        &&
        (
            (!(socket?.connected && socket.connected) && sportsList.length > 0) 
            ||  
            (!(socket?.connected && socket.connected) &&  isMoreThanMinutes())
        )
    ){
        fetchAllFixturesSequentially();
    }
};

useEffect(() => {

    checkUserActivity()
    const handleBeforeUnload = () => {
      setLocalStorage('lastVisitTimestamp', moment.utc().format());
    };

    const visibilityChangeHandler = () => {
      if (document.visibilityState === 'visible') {
          checkUserActivity();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', visibilityChangeHandler);



    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        document.removeEventListener('visibilitychange', visibilityChangeHandler);
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, isSocketInit]);

  useEffect(() => {
    socket.on("SendUpdatedPreMatches", async (fixtures) => {
    //   console.log({fixtures});
      setLocalStorage("socket_interval", fixtures.socket_interval);
      setChunksQue((prevState)=>{
        return({
          ...prevState,
          cc: fixtures.current_chunk,
          tc: fixtures.total_chunks,
          data: [...prevState.data,...fixtures.data]
        })
      })
      // await setFixturesDataByTimeStamp({
      //   fixtures: fixtures.data,
      // });
      // await preMatchBetSlipUpdateWithLatestOdd(fixtures.data);
      // setSocketChunksQue({...fixtures, fixData: fixtures.data})
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);


  useEffect(()=>{

    if(chunksQue.cc === chunksQue.tc && chunksQue?.data.length > 0){
      // console.log("chunksQue",chunksQue);
      setFixturesDataByTimeStamp({
        fixtures: chunksQue.data,
      });
      preMatchBetSlipUpdateWithLatestOdd(chunksQue.data);
      setTimeout(() =>{
        setChunksQue({
          cc: -1,
          tc: 0,
          data: [] 
        })
      }, 500)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chunksQue])

  /* Based on socket data - end */
  return <></>;
};

const mapStateToProps = (state) => ({
  //new code for indexDB start
  sportsList: state.sportsBook.sports,
  fixturesTimeStamp: state.sportsBook.fixturesTimeStamp,
  initFixture: state.sportsBook.initFixture,
  AllSportBookData: state.sportsBook,
  isSocketInit: state.socket.isSocketInit,
  //new code for indexDB end
});

const mapDispatchToProps = (dispatch) => ({
  //new code for indexDB start
  setSportsBook: (sportBookData) => dispatch(setSportsBookData(sportBookData)),
  setFixture: (data) => dispatch(setFixtureData(data)),
  setFixtureDataByTimeFilter: (data) =>
    dispatch(setFixtureDataByTimeFilter(data)),
  setFixturesDataByTimeStamp: (data) =>
    dispatch(setFixturesDataByTimeStamp(data)),
    setSocketInit: (data) => dispatch(socketInit(data)),
    preMatchBetSlipUpdateWithLatestOdd: (data) => dispatch(preMatchBetSlipUpdate(data))


  //new code for indexDB end
});

export default connect(mapStateToProps, mapDispatchToProps)(UniversalSportBook);
