import { Icon } from '@iconify/react';
// import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addOrUpdateItem, deleteAllItems, onChangeActiveTab, onDeleteItem } from '../../../redux/actions';
import { formatDate, getCurrentBetslip } from '../../../services/helper';
import { StatsImage } from '../../layout/IconImage';

const FilteredPreMatchOddsGriditem = ({ addOrUpdateItem, onDeleteItem, item, tab, betslip }) => {
    const isSelected = betslip.find((betitem) => betitem.id === item.id)
        ? betslip.find((betitem) => betitem.id === item.id).oddSelectedId
        : null;

    const handleAddItem = (odd, market) => {
        addOrUpdateItem(item, odd, market, tab);
    };

    const handleDeleteItem = (itemId, tab) => {
        onDeleteItem(itemId, tab);
    };

    const activeOdd = (target) => {
        const elements = document.getElementById(item.id).querySelectorAll('.eventContentWraperRow > .oddbg .odsSpan');
        elements.forEach((element) => {
            element.classList.remove('active');
        });
        target.classList.add('active');
    };

    //   const activeOdd = (itemId) => {
    //     setActiveItemId(itemId); // Set the active item ID in state
    //   };

    const oddsHeader = ['1', 'X', '2', '1X', 'X2', '12'];

    const handleClick = (e, oddId, marketId) => {
        if (!e.target.classList.contains('active')) {
            activeOdd(e.target);
            handleAddItem(oddId, marketId, tab);
        } else {
            e.target.classList.remove('active');
            handleDeleteItem(item.id, tab);
        }
    };

    const getOptionValue = (option) => {
        const hasMarkets = item.hasOwnProperty('Markets');

        let oddData, MarketData;

        if (hasMarkets) {
            const market1X2 = item.Markets.find((market) => market.name === '1X2');
            const market12 = item.Markets.find((market) => market.name === '12');
            const marketDoubleChance = item.Markets.find((market) => market.name === 'Double Chance');

            if (market1X2 && market1X2.Odds.find((odd) => odd.name === option)) {
                oddData = market1X2.Odds.find((odd) => odd.name === option);
                MarketData = market1X2;
            } else if (market12 && market12.Odds.find((odd) => odd.name === option)) {
                oddData = market12.Odds.find((odd) => odd.name === option);
                MarketData = market12;
            } else if (marketDoubleChance && marketDoubleChance.Odds.find((odd) => odd.name === option)) {
                oddData = marketDoubleChance.Odds.find((odd) => odd.name === option);
                MarketData = marketDoubleChance;
            }
        } else {
            oddData = item.Market.Odds.find((odd) => odd.name === option);
            MarketData = item.Market;
        }

        return oddData && oddData.bet_status === 1 ? (
            <div
                className="eventWraperColumn oddbg"
                data={oddData.name}
                style={{
                    pointerEvents: 'none',
                }}
            >
                <span
                    className={`odsSpan ${isSelected && isSelected === oddData.id ? 'active' : ''}`}
                    id={oddData.id}
                    onClick={(e) => {
                        handleClick(e, oddData.id, MarketData.id);
                    }}
                    style={{
                        pointerEvents: 'auto',
                        cursor: 'pointer',
                    }}
                >
                    {oddData.price.toFixed(2)}
                </span>
            </div>
        ) : (
            <div className="eventWraperColumn">
                <span className="odsEmptySpan">
                    <Icon icon="material-symbols:lock" />
                </span>
            </div>
        );
    };

    return (
        <div className="eventContentWraperRow newColumn" id={item.id}>
            <Link
                aria-label="Prematch detais"
                className="eventWraperColumn eventFHolder eventRes"
                to={'/prematch/detail/' + item.id}
                style={{
                    color: 'inherit',
                    textDecoration: 'inherit',
                }}
            >
                <div className="timeFRow">
                    <span className="time1">{formatDate(item.start_date, 'fixturedate')}</span>
                    <span className="time2">{formatDate(item.start_date, 'fixturetime')}</span>
                </div>

                <div className="eventNameandLCenter">
                    {/* <Link to={`/prematch/detail/${item.id}`}> */}
                    {/* <div className="eventImage ">{getSportIcon(item.sport_name)}</div> */}
                    {/* </Link> */}
                    <span className="eventTitleInside">
                        <h3>
                            {item.Participants_home.name} - {item.Participants_away.name}
                        </h3>
                        <p>
                            {item.location_name} - {item.league_name}
                        </p>
                    </span>
                </div>
            </Link>
            {oddsHeader.map((odd) => getOptionValue(odd))}
            <Link
                aria-label="Prematch detais"
                className="eventWraperColumn timewSHolder"
                to={'/prematch/detail/' + item.id}
                style={{
                    color: 'inherit',
                    textDecoration: 'inherit',
                }}
            >
                <span className="stimageSpan">
                    <StatsImage />
                </span>
                <span className="round">
                    <span>+{item.sidebets}</span>
                </span>
            </Link>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        tab: state.data.activeTab,
        betslip: state.data[getCurrentBetslip(state.data.activeTab)],
        livebetslip: state.data.livebetslip,
        sportbook: state.data.sportbook, // Assuming you are storing the betslip data in the Redux state under the active tab
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDeleteItem: (itemId, tab) => dispatch(onDeleteItem(itemId, tab)),
        onDeleteAllItem: (tab) => dispatch(deleteAllItems(tab)),
        onChangeActiveTab: (tabIndex) => dispatch(onChangeActiveTab(tabIndex)),
        addOrUpdateItem: (newBet, odd, market, tab) => dispatch(addOrUpdateItem(newBet, odd, market, tab)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilteredPreMatchOddsGriditem);
