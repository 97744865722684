import axiosInstance from './axiosInstance';
import { toast } from 'react-toastify';
import config from '../config';
import { ToastError, ToastSuccess } from './helper';

const API_DEV_URL = config.apiUrl;
const API_KEY = config.apiKey;

export const fetchData = async (request_url) => {
    try {
        const response = await axiosInstance.get(`${API_DEV_URL}${request_url}`, {
            crossorigin: 'anonymous',
            headers: {
                'Content-Type': 'application/json',
                'API-KEY': API_KEY,
            },
        });
        return response.data;
    } catch (error) {
        // Handle error
        console.error(error);
        throw error;
    }
};
export const fetchDataWithAuth = async (request_url, user_token) => {
    try {
        const response = await axiosInstance.get(`${API_DEV_URL}${request_url}`, {
            crossorigin: 'anonymous',
            headers: {
                'Content-Type': 'application/json',
                // API_KEY: API_KEY,
                'API-KEY': API_KEY,
                Authorization: `Bearer ${user_token}`,
            },
        });
        return response.data;
    } catch (error) {
        // Handle error
        console.error(error);
        throw error;
    }
};

export const postData = async (request_url, payload) => {
    try {
        const response = await axiosInstance.post(`${API_DEV_URL}${request_url}`, payload.data, {
            crossorigin: 'anonymous',
            headers: {
                'Content-Type': 'application/json',
                // API_KEY: API_KEY,
                'API-KEY': API_KEY,
                Authorization: `Bearer ${payload.user_token}`,
            },
        });
        return response.data;
    } catch (error) {
        // Handle error
        console.error(error);
        throw error;
    }
};

export const postDataWithoutAuth = async (request_url, payload) => {
    try {
        const response = await axiosInstance.post(`${API_DEV_URL}${request_url}`, payload.data, {
            crossorigin: 'anonymous',
            headers: {
                'Content-Type': 'application/json',
                // API_KEY: API_KEY,
                'API-KEY': API_KEY,
            },
        });
        return response.data;
    } catch (error) {
        // Handle error
        console.error(error);
        throw error;
    }
};

export const loginUser = async (request_url, payload,setDisableBtn) => {
    try {
        const response = await axiosInstance.post(`${API_DEV_URL}${request_url}`, payload, {
            crossorigin: 'anonymous',
            headers: {
                'Content-Type': 'application/json',
                // API_KEY: API_KEY,
                'API-KEY': API_KEY,
            },
        });
        toast.dismiss();
        ToastSuccess(response.data.message);
        return response.data.data[0];
    } catch (error) {
        // Handle error
        setDisableBtn(false)
        ToastError(error.response.data.message);
        return null;
    }
};

export const registerUser = async (request_url, payload, setIsClicked) => {
    try {
        const response = await axiosInstance.post(`${API_DEV_URL}${request_url}`, payload.data, {
            crossorigin: 'anonymous',
            headers: {
                'Content-Type': 'application/json',
                // API_KEY: API_KEY,
                'API-KEY': API_KEY,
            },
        });
        // if (response.status === 200) navigate('/');
        ToastSuccess(response.data.message);
        return response;
    } catch (error) {
        // Handle error
        setIsClicked(false);
        ToastError(error.response.data.message);
        return error;
    }
};
