import { deDE as coreDeDE } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { deDE as dataGridDeDE } from '@mui/x-data-grid';
import { deDE } from '@mui/x-date-pickers/locales';

const theme = createTheme({
    palette: {
        background: {
            default: '#F3F4F5',
        },

        primary: {
            main: '#86E5F7', // Replace with your desired primary color
        },

        secondary: {
            main: '#FFC616', // Replace with your desired primary color
        },
        text: {
            primary: '#000', // White color for text
            secondary: '#000',
        },
        white: {
            main: '#fff',
        },
        tBlue: {
            main: '#86E5F7', // Replace with your desired secondary color
        },
        black: {
            main: '#000',
        },
        customPrimary: {
            main: '#86E5F7',
        },

        customDarkPrimary: {
            main: '#65DBF1',
        },

        borderBlueColor: {
            main: '#C8E9EF',
        },

        darkBlue: {
            main: '#0B5765',
        },
    },
    deDE,
    dataGridDeDE,
    coreDeDE,
    typography: {
        //fontFamily: 'Your-Font-Family-Name, sans-serif',
        fontFamily: 'Inter, sans-serif',
        // Replace 'Your-Font-Family-Name' with the actual font family name you want to use.
        // The fallback 'sans-serif' will be used if the specified font is not available.
    },
});

export default theme;