import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { Paper, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { backToPrivious } from '../../../services/helper';

const BranchDeposit = () => {
    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                    paddingBottom: '20px',
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Box width="100%">
                    <Stack direction="row" alignItems="center">
                        <img
                            src="/images/branch_in.png"
                            className="imgBranch"
                            width="62"
                            height="66"
                            alt="Deposit"
                        ></img>
                        <Typography
                            color="black"
                            ml={1}
                            variant="h6"
                            sx={{
                                fontSize: '16px;',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                            }}
                        >
                            Deposit at Branch
                        </Typography>

                        <Button
                            color="primary"
                            onClick={() => backToPrivious()}
                            // variant="text"
                            sx={{ color: '#000', fontWeight: 600, minWidth: '40px', ml: 'auto' }}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Back
                        </Button>
                    </Stack>
                </Box>

                {/* conent start */}

                <Box className="branchList">
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Steps
                    </Typography>
                    <Typography variant="body2">1) Visit any branch</Typography>
                    <Typography variant="body2">
                        2) Provide your registered username and the amount you wish to deposit.
                    </Typography>
                    <Typography variant="body2">
                        3) You will receive a receipt after the transaction - check that all details are correct.
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Note: This transaction can be cancelled within 5 minutes if there is a mistake.
                    </Typography>
                </Box>

                <Paper elevation={0} variant="outlined" className="branchTable">
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Fees</TableCell>
                                <TableCell>
                                    <b>No fees applied</b>{' '}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Processing time</TableCell>
                                <TableCell>
                                    <b>Within 5 minutes</b>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Minimum cash in the amount per transaction</TableCell>
                                <TableCell>
                                    <b>10.00 ETB</b>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Maximum cash in the amount per transaction</TableCell>
                                <TableCell>
                                    <b>Unlimited</b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Box>
        </>
    );
};

export default BranchDeposit;
