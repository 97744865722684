import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { postData } from '../../../services/api';
import { ToastError, formatNumber } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';

const schema = yup.object().shape({
    amount: yup
        .string()
        .required('Amount is required')
        // .min(2, 'Amount should have atleast 2 digits')
        .max(8, 'maximum 8 digits allowed')
        .test('greater-than-twenty', 'Value must be greater than or equal to 20 ETB', (value) => {
            return parseInt(value) >= 20;
        }),
});

const ChapaForm = ({ type }) => {
    const { user, updateBalance } = useContext(UserContext);
    const [isClicked, setIsClicked] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onIntiateDeposit = async (deposit) => {
        // if (Number(deposit.amount) >= 20) {
        setIsClicked(true);
        toast.dismiss();
        try {
            const data = {
                user_token: user.token,
                data: {
                    // payment_mode: 'chapa',
                    payment_type: 1,
                    amount: deposit.amount,
                },
            };
            const response = await postData('/user/deposit/checkout', data);

            if (response.data[0].hasOwnProperty('checkout_url')) {
                window.location = response.data[0].checkout_url;
                const transactionId = response.data[0].tx_ref; // Replace with the actual transaction ID
                localStorage.setItem('transactionId', transactionId);
                updateBalance(response.data[0].balance);
            } else {
                setIsClicked(false);
                ToastError('Something went wrong try again later');
            }
        } catch (error) {
            ToastError(error.response.data.message);
        }
        // } else {
        //     // Handle the case where the conditions are not met (e.g., show an error message)
        //     toast.error(`Deposit amount must be greater than  20 ETB`, {
        //         position: 'top-center',
        //         autoClose: 1000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         theme: 'colored',
        //     });
        // }

        setIsClicked(false);
    };

    return (
        <Box
            component="form"
            sx={{
                backgroundColor: '#e3fbff',
                padding: 1,
                margin: '0 auto',
                marginTop: '20px',
                border: '1px solid #B2E576',
                borderRadius: '4px',
            }}
            onSubmit={handleSubmit(onIntiateDeposit)}
        >
            {/* <img src="/images/chapa.svg" className="chapaFormLogo" alt="Chapa" /> */}

            <FormControl fullWidth sx={{ m: 1 }}>
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        marginBottom: '10px',
                        borderBottom: '1px solid #B2E576',
                        paddingBottom: '10px',
                    }}
                >
                    Your current account balance is :{' '}
                    <span style={{ fontSize: '17px' }}> {formatNumber(user.balance)} ETB</span>{' '}
                </Typography>
                <Stack direction="row" alignItems="flex-start">
                    <Typography
                        sx={{
                            fontSize: '16px;',
                            fontWeight: '500',
                            position: 'relative',
                            width: '210px',
                            marginTop: '11px',

                            //padding:'10px'
                        }}
                    >
                        REQUEST AMOUNT
                    </Typography>

                    <Box>
                        <TextField
                            aria-describedby="Request Amount"
                            aria-label="Request Amount"
                            className="withdrawTxtFld"
                            sx={{ backgroundColor: '#fff' }}
                            id="outlined-adornment-amount"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">ETB</InputAdornment>,
                            }}
                            placeholder="Amount"
                            {...register('amount')}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]*/g, '');
                            }}
                        ></TextField>

                        <Typography variant="body2" color="error">
                            {errors.amount?.message}
                        </Typography>
                    </Box>

                    <Button
                        variant="contained"
                        disableElevation
                        disabled={isClicked}
                        type="submit"
                        sx={{ fontWeight: '500', ml: 2, paddingTop: '13px', paddingBottom: '13px' }}
                    >
                        {type}
                    </Button>
                </Stack>
            </FormControl>
        </Box>
    );
};

export default ChapaForm;
