import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const LoadingOverlay = () => {
    const image = {
        alt: 'loade Logo',
        //height: 200, // Replace with the actual height of the image
        src: '/images/primeLogo.png', // Replace with the actual path to your image
        // width: 300, // Replace with the actual width of the image
    };
    return (
        <>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'fixed',
                    backgroundColor: 'rgba(256,256,256,0.5)',
                    left: '0',
                    top: '0',
                }}
            >
                <Stack alignItems="center" justifyContent="center" height="100vh">
                    {' '}
                    <Box>
                        <LazyLoadImage
                            className="loaderLogo hithere"
                            alt={image.alt}
                            width="140"
                            height="95"
                            rel="preload"
                            as="image"
                            // height={image.height}
                            src={image.src} // use normal <img> attributes as props
                            // width={image.width}
                        />

                        {/* <img src="/images/primeLogo.png" alt="Prime Logo" className="loaderLogo hithere" /> */}
                    </Box>
                </Stack>
            </div>
        </>
    );
};

export default LoadingOverlay;
