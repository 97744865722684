import ArticleIcon from '@mui/icons-material/Article';
import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';
import 'react-phone-input-2/lib/style.css';
import Footer from '../../layout/Footer';
const DminHeight = window.innerHeight;
const TermsCondition = () => {
    return (
        <>
            <Container maxWidth="lg" sx={{}}>
                <Box
                    className="profilePage"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        mb: 2,
                        border: '1px solid',
                        borderColor: 'borderBlueColor.main',
                        p: 2,
                        mt: 4,
                        mb: 4,
                    }}
                    component="form"
                >
                    <Box display="flex">
                        {' '}
                        <ArticleIcon color="black" />
                        <Typography
                            sx={{
                                fontSize: '16px;',
                                fontWeight: 'bold',
                                mb: 1,
                                ml: 1,
                            }}
                        >
                            Terms & Conditions
                        </Typography>{' '}
                    </Box>
                    <Box>
                        <Typography variant="button"> Eligibility:</Typography>
                        <Typography variant="body2" mb={2}>
                            You must be of legal age in your jurisdiction to participate in any betting activities
                            offered on the Website. By using the Website, you confirm that you meet the legal age
                            requirements.
                        </Typography>

                        <Typography variant="button"> User Account: </Typography>
                        <Typography variant="body2" mb={2}>
                            To use certain features or participate in betting activities, you may need to create a user
                            account. You are solely responsible for maintaining the confidentiality of your account
                            credentials, and you agree not to share them with any third party. You are responsible for
                            all activities that occur under your account.
                        </Typography>

                        <Typography variant="button"> Betting Rules:</Typography>
                        <Typography variant="body2" mb={2}>
                            Before placing any bets, carefully review the rules and regulations provided by the Website.
                            It is your responsibility to understand and comply with these rules. We reserve the right to
                            void bets or suspend accounts in case of any rule violations.
                        </Typography>

                        <Typography variant="button"> Responsible Gambling:</Typography>
                        <Typography variant="body2" mb={2}>
                            We are committed to promoting responsible gambling. If you feel that your betting activities
                            are becoming problematic, we encourage you to set limits or seek assistance from relevant
                            support organizations.
                        </Typography>

                        <Typography variant="button"> Payment and Transactions:</Typography>
                        <Typography variant="body2" mb={2}>
                            The Website may offer various payment methods for deposits and withdrawals. You must ensure
                            that the payment information you provide is accurate and up-to-date. We are not responsible
                            for any delays or losses caused by incorrect payment information.
                        </Typography>

                        <Typography variant="button"> Fair Play and Prohibited Activities:</Typography>
                        <Typography variant="body2" mb={2}>
                            Any form of cheating, collusion, or abuse of the Website's services is strictly prohibited.
                            Engaging in such activities may lead to account suspension or termination.
                        </Typography>

                        <Typography variant="button"> Intellectual Property:</Typography>
                        <Typography variant="body2" mb={2}>
                            The content and materials on the Website, including logos, trademarks, text, images, and
                            software, are the property of the Website or its licensors and are protected by applicable
                            intellectual property laws. You may not reproduce, modify, distribute, or use any content
                            without prior written consent.
                        </Typography>

                        <Typography variant="button"> Indemnity:</Typography>
                        <Typography variant="body2" mb={2}>
                            You agree to indemnify and hold the Website, its affiliates, and their respective officers,
                            directors, employees, and agents harmless from any claims, losses, damages, liabilities, or
                            expenses arising out of your use of the Website or any violation of these Terms.
                        </Typography>
                    </Box>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default TermsCondition;
