import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const BankDetailsModal = (props) => {
    const validationSchema = yup.object().shape({
        account_name: !props.type
            ? yup
                  .string()
                  .required('Account name is required')
                  .matches(/[A-Za-z]+$/, 'Only alphabetic characters are allowed')
                  .matches(/^[^\s].*/, 'starting spaced is not allowed')
            : yup.string().notRequired(),
        account_number: !props.type
            ? yup
                  .string()
                  .required(' Account number is required')
                  .matches(/^[0-9]+$/, 'Only numbers are allowed')
                  .matches(/^[^\s].*/, 'starting spaced is not allowed')
                  .min(8, 'Account number should have atleast 8 digits')
                  .max(18, 'Account number should have atleast 18 digits')
            : yup.string().notRequired(),
        // amount: yup
        //     .string()
        //     .required(' Amount is required')
        //     .matches(/^[0-9]+$/, 'Only numbers are allowed')
        //     .test('greater-than-zero', 'Value must be greater than 0', (value) => {
        //         return parseInt(value) > 0;
        //     })
        //     .matches(/^[^\s].*/, 'starting spaced is not allowed'),
        bank_code: yup.string().required('Bank is required').notOneOf([' '], 'Please select a bank'),
    });

    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        reset({
            account_name: '',
            account_number: '',
            payment_type: '',
            amount: '',
        });
        props.setBank('');
        clearErrors('bank_code');
        // props.setPhone('');
    }, [props.modal]);

    return (
        <Dialog
            open={props.modal}
            disableEscapeKeyDown
            onClose={props.handleModalClose}
            sx={{ maxWidth: '600px', margin: '0 auto' }}
            // sx={{ margin: '0 auto', width: 500 }}
        >
            <Typography textAlign="right">
                <CloseIcon sx={{ cursor: 'pointer', margin: 1 }} onClick={props.handleModalClose} />
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: 25 }} marginLeft={5}>
                Bank Details
            </Typography>
            <form onSubmit={handleSubmit(props.onSubmitBankDetails)}>
                <DialogContent sx={{ minWidth: 500 }}>
                    {!props.type && (
                        <>
                            <Box textAlign={'center'} marginLeft={2} width="68%">
                                <TextField
                                    name="account_name"
                                    fullWidth
                                    variant="outlined"
                                    label="Account Name"
                                    {...register('account_name')}
                                    placeholder="Account Name"
                                    disableUnderline
                                />
                            </Box>

                            <Typography marginLeft={2} variant="body2" color="error">
                                {errors.account_name?.message}
                            </Typography>
                        </>
                    )}
                    {!props.type && (
                        <Box margin={2} width="68%">
                            <TextField
                                name="account_number"
                                fullWidth
                                variant="outlined"
                                label="Account Number"
                                {...register('account_number')}
                                placeholder="Account Number"
                                disableUnderline
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]*/g, '');
                                }}
                            />
                            <Typography variant="body2" color="error">
                                {errors.account_number?.message}
                            </Typography>
                        </Box>
                    )}
                    <Box margin={2} width="68%">
                        <TextField
                            name="amount"
                            fullWidth
                            variant="outlined"
                            label="Amount"
                            {...register('amount')}
                            placeholder="Amount"
                            value={props.amount}
                            disableUnderline
                            disabled
                            InputProps={{
                                endAdornment: <InputAdornment position="start">{''}ETB</InputAdornment>,
                            }}
                        />
                        <Typography variant="body2" color="error">
                            {errors.amount?.message}
                        </Typography>
                    </Box>
                    <Box margin={2}>
                        <FormControl sx={{ minWidth: '73%' }} className="selectInput">
                            <InputLabel id="demo-simple-select-label">Select Bank</InputLabel>
                            <Select
                                name="bank_code"
                                {...register('bank_code')}
                                // name="bet_place_type"

                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={props.bank}
                                fullWidth
                                onChange={props.onchangeBank}
                            >
                                <MenuItem key={''} value={' '}>
                                    Select Bank
                                </MenuItem>
                                {props.FetchBank.map((option) => (
                                    <MenuItem key={option.bank_code} value={option.bank_code}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant="body2" color="error">
                            {errors.bank_code?.message}
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button
                        sx={{
                            // paddingTop: '10px',
                            marginBottom: '10px',
                            fontWeight: '600',
                            backgroundColor: '#074652',
                            color: '#fff',
                            '&:hover': { backgroundColor: '#074652' },
                        }}
                        variant="contained"
                        size="medium"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={props.disableBtn}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default BankDetailsModal;
