import React from 'react';
import LeftSideFavorites from './LeftSideFavorites';
import LeftSidePlayingNow from './LeftSidePlayingNow';
import SideSearch from './SideSearch';
import SideTimeFilter from './SideTimeFilter';
import SideTopLeagues from './SideTopLeagues';
import SidebarSportbook from './SidebarSportbook';
import UserSidebar from './UserSidebar';

const Sidebar = (props) => {
    if (props.type === 'favourites')
        return (
            <>
                <LeftSideFavorites />
                <LeftSidePlayingNow />
            </>
        );
    else if (props.type === 'profile')
        return (
            <>
                <UserSidebar activeTab={props.selectedTab} />
            </>
        );
    else
        return (
            <>
                <SideTimeFilter setTimeFilter={props.setTimeFilter} />
                <SideSearch />
                <SideTopLeagues topleagues={props.topleagues} />
                <SidebarSportbook loading={props.loading} />
                {/* <SideDownload /> */}
            </>
        );
};

export default Sidebar;
