import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import { Box, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import React from 'react';
const LeftSideFavorites = () => {
    return (
        <>
            <Box backgroundColor="#fff" padding="0 0 10px 0">
                {/* heading bar */}
                <Box
                    component="box"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    backgroundColor={grey[300]}
                >
                    <Box display="flex" alignItems="center">
                        <IconButton aria-label="Like">
                            <StarIcon />
                        </IconButton>

                        <Typography component="h4" variant="h5" fontWeight="500" fontSize="large">
                            Favorites <span>(01)</span>
                        </Typography>
                    </Box>
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Box>

                {/* content bar */}

                <Box padding="0 16px">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        position="relative"
                        padding="10px 0"
                    >
                        <Typography variant="body2" fontWeight="500">
                            Canberra FC - WC Wanderers{' '}
                        </Typography>
                        <IconButton aria-label="StarIcon" sx={{ position: 'absolute', right: '-16px' }}>
                            <StarIcon fontSize="small" color="secondary" />
                        </IconButton>
                    </Box>

                    <Box display="flex" padding="5px 0">
                        <Typography component="span" variant="body2" width="40px" fontWeight="500">
                            0:1
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight="500">
                            6:7, 0:1
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight="500" ml="auto">
                            2nd Set
                        </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" padding="5px 0">
                        <Typography component="span" variant="body2" width="40px" fontWeight="500">
                            0:1
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight="500">
                            6:7, 0:1
                        </Typography>
                        <Typography component="span" variant="body2" fontWeight="500" ml="auto">
                            2nd Set
                        </Typography>
                    </Box>
                    {/* <Divider/> */}
                </Box>
            </Box>
        </>
    );
};

export default LeftSideFavorites;
