import { Icon } from '@iconify/react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { postData } from '../../../services/api';
import { ToastError, convertToUTC, formatDate } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';

const MiddleTransactionsTab = () => {
    const { user, updateuserBalance } = useContext(UserContext);
    const [transactions, setTransactions] = useState([]);
    const [extra_data, setExtraData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(['', '']);
    const [selectedValues, setSelectedValues] = useState([]);

    const checkboxes = [
        { label: 'All', value: '' },
        { label: 'Deposit', value: 1 },
        { label: 'Withdraw', value: 2 },
        { label: 'Bet place', value: 3 },
        { label: 'Winning', value: 4 },
    ];

    const min_date = new Date();
    const minDate = dayjs(min_date);

    useEffect(() => {
        updateuserBalance(user);
        toast.dismiss();
        fetchTransactions(page, rowsPerPage, '', []);
    }, []);

    const fetchTransactions = async (page, pageSize, date, type) => {
        toast.dismiss();
        let data = {
            user_token: user.token,
            data: {
                start_date: convertToUTC(date).start_date,
                end_date: convertToUTC(date).end_date,
                type: type,
            },
        };
        try {
            setLoading(true);
            const response = await postData(`/user/transactions?page=${page + 1}&size=${pageSize}`, data);
            setTransactions(response.data);
            setExtraData(response.extra_data);
            setLoading(false);
        } catch (error) {
            console.log('error :>> ', error);
            ToastError(error.response.data.message);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchTransactions(newPage, rowsPerPage, date, selectedValues);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        fetchTransactions(page, event.target.value, date, selectedValues);
    };

    const getTransactionType = (type) => {
        let betstatus = null;
        switch (type) {
            case 1:
                betstatus = 'Deposit IN';
                break;
            case 2:
                betstatus = 'Withdrawal OUT';
                break;
            case 3:
                betstatus = 'Bet Placed IN';
                break;
            case 4:
                betstatus = 'Winnings OUT';
                break;
            case 6:
                betstatus = 'Bonus IN';
                break;
            default:
                break;
        }
        return betstatus;
    };

    const getTransactionStatus = (status) => {
        let betstatus = null;
        switch (status) {
            case 1:
                betstatus = 'OPEN';
                break;
            case 2:
                betstatus = 'PENDING';
                break;
            case 3:
                betstatus = 'SUCCESS';
                break;
            case 4:
                betstatus = 'CANCEL';
                break;
            case 5:
                betstatus = 'FAIL';
                break;

            default:
                break;
        }
        return betstatus;
    };
    const getPaymentType = (paymentType) => {
        let type = null;
        switch (paymentType) {
            case 1:
                type = 'CHAPA';
                break;
            case 2:
                type = 'SANTIM PAY';
                break;

            default:
                break;
        }
        return type;
    };

    const handleDateChange = (newValue) => {
        setDate(newValue);
    };
    const onSearch = (data) => {
        if (selectedValues.length || date[0] !== '' || date[1] !== '')
            fetchTransactions(page, rowsPerPage, date, selectedValues);
    };

    const clearAll = () => {
        if (selectedValues.length || date[0] !== '' || date[1] !== '') {
            setDate(['', '']);
            setSelectedValues([]);
            fetchTransactions(page, rowsPerPage, '', '');
        }
    };

    useEffect(() => {
        if (selectedValues.includes('')) {
            setSelectedValues([1, 2, 3, 4]);
        }
    }, [selectedValues]);

    const handleCheckboxChange = (value) => {
        if (value === '') {
            if (selectedValues.length === 4) {
                setSelectedValues([]);
            } else {
                setSelectedValues([1, 2, 3, 4]);
            }
        } else {
            if (selectedValues.includes(value)) {
                setSelectedValues(selectedValues.filter((v) => v !== value));
            } else {
                setSelectedValues([...selectedValues, value]);
            }
        }
    };

    const isCheckboxChecked = (value) => {
        if (selectedValues.length === 4) {
            return selectedValues;
        }
        if (value === '') {
            return selectedValues.length === 4;
        }
        return selectedValues.includes(value);
    };

    return (
        <>
            <Box
                sx={{
                    padding: '10px',
                    minHeight: '600px',
                    paddingBottom: '20px',
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Box>
                    <Stack direction="row" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="center">
                            <AttachMoneyIcon color="black" />{' '}
                            <Typography
                                color="black"
                                ml={1}
                                variant="h6"
                                sx={{
                                    fontSize: '16px;',
                                    fontWeight: 'bold',

                                    textTransform: 'uppercase',
                                }}
                            >
                                YOUR TRANSACTIONS
                            </Typography>
                        </Stack>
                        <IconButton
                            onClick={() => {
                                fetchTransactions(page, rowsPerPage, '', '');
                            }}
                        >
                            <Icon icon="jam:refresh" />
                        </IconButton>
                    </Stack>
                </Box>

                <Box mt={2}>
                    <Stack flexDirection="row">
                        <Box padding={2} width="50%">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DesktopDateRangePicker']}>
                                    <DemoItem component="DesktopDateRangePicker">
                                        <DesktopDateRangePicker
                                            localeText={{ start: 'Start Date', end: 'End Date' }}
                                            className=""
                                            size="small"
                                            maxDate={minDate}
                                            value={date}
                                            onChange={handleDateChange}
                                            calendars={1}
                                            format="DD-MM-YYYY"
                                            slotProps={{
                                                textField: {
                                                    error: false,
                                                },
                                            }}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Stack>
                    <Box sx={{ flexBasis: '25%' }} padding={2}>
                        {checkboxes.map((checkbox) => (
                            <FormControlLabel
                                key={checkbox.value}
                                label={checkbox.label}
                                control={
                                    <Checkbox
                                        checked={isCheckboxChecked(checkbox.value)}
                                        onChange={() => handleCheckboxChange(checkbox.value)}
                                    />
                                }
                            />
                        ))}
                    </Box>
                    <Stack display="flex" flexDirection="row" justifyContent="flex-end">
                        <Box margin={2}>
                            <Button
                                onClick={clearAll}
                                color="primary"
                                variant="contained"
                                size="large"
                                disableElevation
                            >
                                Clear
                            </Button>
                        </Box>
                        <Box margin={2}>
                            <Button
                                onClick={onSearch}
                                color="primary"
                                variant="contained"
                                size="large"
                                disableElevation
                            >
                                Search
                            </Button>
                        </Box>
                    </Stack>
                    {/* table box */}
                    <Table spac>
                        <TableHead>
                            <TableRow sx={{ textTransform: 'uppercase' }}>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    DATE
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    TYPE
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    VALUE
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    PAYMENT TYPE
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    DESCRIPTION
                                </TableCell>
                                <TableCell sx={{ backgroundColor: grey[200], fontWeight: '600' }} align="center">
                                    STATUS
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                Array.from(Array(rowsPerPage).keys()).map((index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : transactions.length > 0 ? (
                                transactions.map((transaction) => (
                                    <TableRow key={transaction.id}>
                                        <TableCell
                                            padding="normal"
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span>{formatDate(transaction.created_at, 'transaction date')}</span>
                                        </TableCell>
                                        <TableCell
                                            padding="normal"
                                            sx={{
                                                backgroundColor: grey[100],
                                                textTransform: 'capitalize',
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span>{getTransactionType(transaction.type)}</span>
                                        </TableCell>
                                        <TableCell
                                            padding="normal"
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span>{transaction.amount} ETB </span>
                                        </TableCell>
                                        <TableCell
                                            padding="normal"
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span>
                                                {/* {getPaymentType(
                                                    transaction.payment_transaction
                                                        ? transaction.payment_transaction.payment_type
                                                        : 0
                                                )} */}
                                                {(transaction.type === 1 || transaction.type === 2) &&
                                                transaction.payment_transaction === null
                                                    ? 'CASHIER'
                                                    : (transaction.type === 3 || transaction.type === 4) &&
                                                      transaction.payment_transaction === null
                                                    ? '-'
                                                    : transaction.type === 6 && transaction.payment_transaction === null
                                                    ? 'BACK OFFICE'
                                                    : getPaymentType(
                                                          transaction.payment_transaction
                                                              ? transaction.payment_transaction.payment_type
                                                              : 0
                                                      )}
                                            </span>
                                        </TableCell>
                                        <TableCell
                                            padding="normal"
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span>{transaction.note ? transaction.note : transaction.pbet_uid}</span>
                                        </TableCell>
                                        <TableCell
                                            padding="normal"
                                            sx={{
                                                backgroundColor: grey[100],
                                                fontWeight: '400',
                                                border: '1px solid #fff',
                                            }}
                                            align="center"
                                        >
                                            <span>{getTransactionStatus(transaction.status)}</span>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell align="center" colSpan={6}>
                                        <Typography variant="h6"> data not found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>

                {transactions.length > 0 && (
                    <Box className="paginationDv" display="flex" justifyContent="center" mt={4}>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={extra_data ? extra_data.total_items : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default MiddleTransactionsTab;
