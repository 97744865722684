import { Box, Container, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useContext, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import RaiseTicketModel from '../pages/User/RaiseTicketModel';

const Footer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => {
        console.log('clicked');
        setIsOpen(false);
    };

    const image = {
        alt: 'Footer Logo',
        //height: 200, // Replace with the actual height of the image
        src: '/images/primeLogo.png', // Replace with the actual path to your image
        // width: 300, // Replace with the actual width of the image
    };
    const { user } = useContext(UserContext);

    const linkStyle = {
        color: grey[800],
        textDecoration: 'none',
        marginRight: '15px',
        marginLeft: '15px',
        fontSize: '14px',
        fontWeight: '500',
        position: 'relative',
    };

    const imgBoxStyle = {
        width: '80px',
        margin: '0 auto',
    };

    const currentYear = new Date().getFullYear();


    return (
        <>
            <Container maxWidth="false" sx={{ marginTop: '10px' }}>
                <Box textAlign="center">
                    <Box style={imgBoxStyle}>
                        <Link
                            to="/"
                            style={{
                                color: 'inherit',
                                textDecoration: 'inherit',
                            }}
                        >
                            <LazyLoadImage
                                className="maxWith100"
                                alt={image.alt}
                                width="80"
                                height="54"
                                // height={image.height}
                                src={image.src} // use normal <img> attributes as props
                                // width={image.width}
                            />
                        </Link>

                        {/* <img src="/images/primeLogo.png" alt="Prime Logo" className="maxWith100" /> */}
                    </Box>

                    <Box>
                        <Link to="/" className="linkDevider" style={linkStyle}>
                            SPORTBOOK
                        </Link>

                        {/* <Link to="/live" className="linkDevider" style={linkStyle}>
                            LIVEGAMES
                        </Link> */}

                        {/* <Link to="#" className="linkDevider" style={linkStyle}>
                            VIRTUAL SOCCER
                        </Link>

                        <Link to="#" className="linkDevider" style={linkStyle}>
                            TODAY OFFER PDF
                        </Link>

                        <Link to="#" className="linkDevider" style={linkStyle}>
                            FULL OFFER PDF
                        </Link> */}

                        {user ? (
                            <Link to="/user/depositsandwithdrawals" className="linkDevider" style={linkStyle}>
                                DEPOSITS & WITHDRAWALS
                            </Link>
                        ) : (
                            <></>
                        )}
                        {/* <Link to="/contact" className="linkDevider" style={linkStyle}>
                            CONTACT US
                        </Link> */}

                        <Link to="/termscondition" className="linkDevider" style={linkStyle}>
                            TERMS & CONDITIONS
                        </Link>

                        {/* <Link to="#" className="linkDevider" style={linkStyle}>
                            BET SHOPS
                        </Link> */}
                        {!user ? (
                            <Link to="/registration" className="linkDevider" style={linkStyle}>
                                REGISTER
                            </Link>
                        ) : (
                            <></>
                        )}

                        {user ? (
                            <Link to="/user/support" className="linkDevider" style={linkStyle}>
                                SUPPORT
                            </Link>
                        ) : (
                            <Link className="linkDevider" style={linkStyle} onClick={() => setIsOpen(true)}>
                                SUPPORT
                                <RaiseTicketModel isOpen={isOpen} onClose={handleClose} />
                            </Link>
                        )}
                    </Box>

                    <Typography fontSize="13px" mt="10px" color="#7e7e7e">
                        {' '}
                        No minors allowed. Betting can be addictive and psychologically harmful. Betting is only allowed
                        to persons over 21 years old.{' '}
                    </Typography>

                    <Typography fontSize="12px" mt="10px" pb="20px" color="#7e7e7e">
                        {' '}
                        Copyright {currentYear}, IDEMO SPORT{' '}
                    </Typography>
                    <Typography fontSize="12px" pb="20px" color="#7e7e7e">
                        {' '}
                        Designed and Developed by <a href='https://sportsbettingsoft.com/' target='_blank' >Sports Betting Soft</a>
                    </Typography>
                </Box>
            </Container>
        </>
    );
};

export default Footer;
