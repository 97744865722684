import { Box } from '@mui/system';
import React from 'react';
import LiveSoccer from './LiveMatches';

const LiveGamesMiddleContent = () => {
    return (
        <>
            <Box
                className=""
                sx={{
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    p: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                {/* <LiveFavorite /> */}
                <LiveSoccer />
            </Box>
        </>
    );
};

export default LiveGamesMiddleContent;
