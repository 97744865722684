import moment from "moment-timezone";
import Fuse from 'fuse.js';
import { fixtureFilterByTime, removePreMatchByBeforeTime } from "../../services/helper";
import { filterUnique, sortByPropertyName, updateAndMergeData } from "../../services/objectManipulation";

export const filterFixturesByLeague = (fixtures, leagues) => {
  const filteredFixtures = [];
  const filteredLeagues = [];

  for (const fixture of fixtures) {
    const league = leagues.find(league => league.id === fixture.league_id);
    if (league) {
      filteredLeagues.push(league);
      filteredFixtures.push(fixture);
    }
  }

  return {
    fixtures: filteredFixtures,
    leagues: filterUnique(filteredLeagues, 'id'),
  };
};

export const filterLeaguesByLocation = (leagues, locations) => {
  const filteredLocations = [];
  for (const league of leagues) {
    const location = locations.find(location => location.id === league.location_id);
    if (location) {
      filteredLocations.push(location);
    }
  }

  return filterUnique(filteredLocations, 'id');
};

export const sortLocationsByName = locations => sortByPropertyName(locations, 'name');

export const setFixturesDataMutation = (state, action) => {
  const { fixtures, sport_id } = action.payload.data;

  const { fixtures: filteredFixtures, leagues } = filterFixturesByLeague(fixtures, [...state.leagues]);
  const locations = filterLeaguesByLocation(leagues, [...state.locations]);
  const sortedLocations = sortLocationsByName(locations);

  return {
    ...state,
    initFixture: { ...state.initFixture, [sport_id]: fixtures },
    fixtures: { ...state.fixtures, [sport_id]: filteredFixtures },
    updatedLeague: { ...state.updatedLeague, [sport_id]: sortByPropertyName(leagues) },
    updatedLocation: { ...state.updatedLocation, [sport_id]: sortedLocations },
    fixturesTimeStamp: { ...state.fixturesTimeStamp, [sport_id]: moment.utc() },
  };
};

export const setFixturesDataByFilterMutation = (state, action) => {
  const { sport_id, filter = '' } = action.payload.data;
  const fixtureFilter = filter || state.filter;
  const allInitFixtures = state.initFixture?.[sport_id] ? [...state.initFixture[sport_id]] : [];
  let tempInitLeague = [...state.leagues]

  let updatedLeagueData = [];
  const tempUpdatedFix = [];

  for (const fixture of allInitFixtures) {
    const { league_id, start_date } = fixture;
    // console.log("fixture",fixture);
    if (
        removePreMatchByBeforeTime(start_date)
    ) {
      if(fixtureFilter === "All" || fixtureFilterByTime(fixtureFilter, start_date, fixture)){
        const league = tempInitLeague.find(league => league.id === league_id);
        if (league) {
            updatedLeagueData.push(league);
            tempUpdatedFix.push(fixture);
        }
      }
    }
  }

  updatedLeagueData = filterUnique(updatedLeagueData, 'id')
  const sortedLocations = sortLocationsByName(filterLeaguesByLocation(updatedLeagueData, [...state.locations]));

  return {
    ...state,
    fixtures: { ...state.fixtures, [sport_id]: tempUpdatedFix },
    updatedLeague: { ...state.updatedLeague, [sport_id]: sortByPropertyName(updatedLeagueData) },
    updatedLocation: { ...state.updatedLocation, [sport_id]: sortedLocations },
  };
};


export const setFixturesDataByTimeStampMutation = (state, action) => {
    const fixtureFilter = state.filter
    for (const sportDetail of state.sports) {
      const fts_sport_id = sportDetail.id;
      let lengthOfPreMatchListBySportId = false;
  
      if (fixtureFilter === 'All') {
        lengthOfPreMatchListBySportId = state.initFixture &&
          state.initFixture?.[fts_sport_id] &&
          state.initFixture[fts_sport_id].length > 0;
      } else {
        lengthOfPreMatchListBySportId = state.fixtures &&
          state.fixtures?.[fts_sport_id] &&
          state.fixtures[fts_sport_id].length > 0;
      }
  
      if (lengthOfPreMatchListBySportId) {
        const updated_fixture = action.payload.data.fixtures.filter(fd => fd.sport_id === fts_sport_id);
  
        if (updated_fixture.length > 0) {
          const fts_fixtureFilter = fixtureFilter;
          const fts_allInitFixtures = state.initFixture?.[fts_sport_id] ?
            updateAndMergeData(state.initFixture[fts_sport_id], updated_fixture) :
            [];
  
          const fts_tempInitLeague = [...state.leagues];
          const fts_tempInitLocation = [...state.locations];
          let fts_updatedLeagueData = [];
          let fts_updateLocationData = [];
          let fts_tempUpdatedFixture_without_filter = [];
          let fts_tempUpdatedFix = [];
  
          for (const fts_fixture of fts_allInitFixtures) {
            const { league_id, start_date } = fts_fixture;
            if (removePreMatchByBeforeTime(start_date)) {
              const league = fts_tempInitLeague.find(league => league.id === league_id);
              if (league) {
                fts_updatedLeagueData.push(league);
                fts_tempUpdatedFixture_without_filter.push(fts_fixture);
              }
            }
          }
  
          if (fts_fixtureFilter && fts_fixtureFilter !== 'All') {
            fts_updatedLeagueData = [];
            for (const fts_fixture of fts_allInitFixtures) {
              const { league_id, start_date } = fts_fixture;
              if (removePreMatchByBeforeTime(start_date) &&
                  fixtureFilterByTime(fts_fixtureFilter, start_date, fts_fixture)) {
                const league = fts_tempInitLeague.find(league => league.id === league_id);
                if (league) {
                  fts_updatedLeagueData.push(league);
                  fts_tempUpdatedFix.push(fts_fixture);
                }
              }
            }
          }
  
          if (fts_tempUpdatedFixture_without_filter.length > 0 || fts_tempUpdatedFix.length > 0) {
            fts_updatedLeagueData = fts_updatedLeagueData.filter((val, index) =>
              fts_updatedLeagueData.indexOf(val) === index);
          }
  
          for (const updatedLeague of fts_updatedLeagueData) {
            const { location_id } = updatedLeague;
            const location = fts_tempInitLocation.find(location => location.id === location_id);
            if (location) {
              fts_updateLocationData.push(location);
            }
          }
  
          fts_updateLocationData = fts_updateLocationData.filter((val, index) =>
            fts_updateLocationData.indexOf(val) === index);
  
          const fts_sortLocationData = fts_updateLocationData.sort((a, b) =>
            a.name.localeCompare(b.name));
  
          const fts_tempUpdateFixBasedOnFilter = fts_fixtureFilter !== 'All' &&
            fts_tempUpdatedFix.length > 0 ? fts_tempUpdatedFix : fts_tempUpdatedFixture_without_filter;
  
          return {
            ...state,
            initFixture: { ...state.initFixture, [fts_sport_id]: fts_tempUpdatedFixture_without_filter },
            fixtures: { ...state.fixtures, [fts_sport_id]: fts_tempUpdateFixBasedOnFilter },
            updatedLeague: { ...state.updatedLeague, [fts_sport_id]: sortByPropertyName(fts_updatedLeagueData) },
            updatedLocation: { ...state.updatedLocation, [fts_sport_id]: sortByPropertyName(fts_sortLocationData) },
            fixturesTimeStamp: { ...state.fixturesTimeStamp, [fts_sport_id]: moment.utc() },
          };
        }
      } else {
        return { ...state };
      }
    }
  
    return { ...state };
  };
  
  export const getStructuredFixtureWithAllDetails = (payload = {}) =>{
    const { initFixture, sports, leagues, locations, markets } = payload;
    let initData = {...initFixture}
    delete initData['']
    let generalizeTheFixtures = Object.values(initData)
    const data = generalizeTheFixtures?.flat() || []  

    const allInitFixtures = data;
  
    let tempUpdatedFix = [];

    for (let fixture of allInitFixtures) {
      const { start_date, sport_id, locations_id, league_id  } = fixture;
      if (
          removePreMatchByBeforeTime(start_date)
      ) {
            const sportDetail = sports.find((data) => data.id === sport_id)
            const leagueDetail = leagues.find((data) => data.id === league_id)
            const locationDetail = locations.find((data) => data.id === locations_id)
            const fixture_name = `${fixture.participants_home.name} - ${fixture.participants_away.name}`

            fixture = {
                ...fixture,
                "sport_name": sportDetail.name,
                "league_name": leagueDetail.name,
                "location_name": locationDetail.name,
                Markets: markets.map((marketDetail)=> {
                    return {
                        ...marketDetail,
                        Odds: fixture.odds.filter((oddDetails) => oddDetails.market_id === marketDetail.id),
                    }
                }),
                Participants_home: fixture.participants_home,
                Participants_away: fixture.participants_away,
                sidebets: fixture.total_markets,
                fixture_name
            }
            tempUpdatedFix.push(fixture);
      }
    }

    return tempUpdatedFix;
  }
  

  export const getFixtureByTextSearch = (payload = {}) => {
    const { filter = '' } = payload;

    const fixtureList = getStructuredFixtureWithAllDetails(payload)

    const updatedFuse = new Fuse(fixtureList, {
      keys: ['participants_home.name', 'participants_away.name'],
      includeScore: true,
      threshold: 0.1,
    });
    
    let searchResults = updatedFuse.search(filter);
    searchResults = searchResults.map(({ item }) => item);
    searchResults = sortByPropertyName(searchResults, 'start_date')
    return searchResults
  };