import React, { createContext, useEffect, useState } from 'react';
import { fetchData, fetchDataWithAuth } from '../../services/api';

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [systemSetting, setSystemSetiings] = useState([]);

    const login = (userData) => {
        setUser(userData);
    };

    const logout = () => {
        // toast.success('Logout successfully', {
        //     position: 'top-center',
        //     autoClose: 1000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     theme: 'colored',
        // });
        setTimeout(() => {
            localStorage.removeItem('userData');
            setUser(null);
        }, 700);
    };

    const updateBalance = (newBalance) => {
        setUser((prevUser) => ({
            ...prevUser,
            balance: newBalance,
        }));
        localStorage.setItem('userData', JSON.stringify(user));
    };

    const updateuserBalance = async (userData) => {
        if (userData) {
            try {
                const data = {
                    user_token: userData.token,
                    data: {},
                };
                const response = await fetchDataWithAuth(`/user/login/check`, data.user_token);
                const currentData = response.data[0];
                userData.balance = currentData.balance;
                localStorage.setItem('userData', JSON.stringify(userData));
                const updateuserData = JSON.parse(localStorage.getItem('userData'));
                setUser(updateuserData);
            } catch (error) {
                console.log('error :>> ', error);
                localStorage.removeItem('userData');
                setUser(null);
            }
        } else {
            setUser(userData);
        }
    };

    // useEffect(() => {
    //     // Check if the user is stored in localStorage or sessionStorage
    //     const userData = JSON.parse(localStorage.getItem('userData'));
    //     updateuserBalance(userData);
    // }, [window.location.pathname]);

    const fetchSettings = async () => {
        const Settings = await fetchData('/auth/system/setting/list');
        setSystemSetiings(Settings.data[0]);
    };

    useEffect(() => {
        fetchSettings();
    }, [setSystemSetiings]);

    const checkuser = (userData) => {
        if (!user) setUser(userData);
    };
    return (
        <UserContext.Provider
            value={{ user, login, logout, checkuser, updateBalance, systemSetting, updateuserBalance }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };

