import SavingsIcon from '@mui/icons-material/Savings';
import { Box, Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
// import { DepositBankImage } from '../components/Images';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const MiddleDepositsAndWithdrawalsTab = () => {
    const { user, updateuserBalance } = useContext(UserContext);

    useEffect(() => {
        updateuserBalance(user);
    }, []);
    return (
        <Box
            sx={{
                padding: '16px',
                paddingBottom: '20px',
                backgroundColor: 'white.main',
                borderRadius: 2,
                mb: 2,
                border: '1px solid',
                borderColor: 'borderBlueColor.main',
            }}
        >
            <Box width="100%">
                <Stack direction="row" alignItems="center">
                    <SavingsIcon color="black" />
                    <Typography
                        color="black"
                        ml={1}
                        variant="h6"
                        sx={{
                            fontSize: '16px;',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                        }}
                    >
                        Deposits and withdrawals
                    </Typography>
                </Stack>
            </Box>

            <Box className="dTitleRow" sx={{ marginTop: '10px' }}>
                <Typography sx={{ mb: 1, fontWeight: '500', fontSize: '17px' }}>Branch</Typography>

                <Stack direction="row">
                    <Button
                        variant="text"
                        LinkComponent={Link}
                        to="/user/paymentrequest/payments_deposit"
                        sx={{
                            width: '230px',
                            height: '126px',
                            border: '1px solid #B2E576',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img src="/images/branch_in.png" width="87" height="84" className="" alt="Deposit"></img>
                        <Typography fontWeight="500" sx={{ color: '#000', textTransform: 'capitalize' }}>
                            Deposit at Branch
                        </Typography>
                    </Button>

                    <Button
                        variant="text"
                        LinkComponent={Link}
                        to="/user/paymentrequest/payments_withdraw"
                        sx={{
                            width: '230px',
                            height: '126px',
                            border: '1px solid #B2E576',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ml: 3,
                        }}
                    >
                        <img src="/images/branch_out.png" width="87" height="84" className="" alt="Deposit"></img>
                        <Typography fontWeight="500" sx={{ color: '#000', textTransform: 'capitalize' }}>
                            Withdraw at Branch
                        </Typography>
                    </Button>
                </Stack>
            </Box>

            {/* row 2 */}

            <Box className="dTitleRow">
                <Typography sx={{ mb: 1, fontWeight: '500', fontSize: '17px' }}>Deposit</Typography>

                <Stack direction="row">
                    <Button
                        variant="text"
                        LinkComponent={Link}
                        to="/user/paymentrequest/chapa_deposit"
                        sx={{
                            width: '230px',
                            height: '126px',
                            border: '1px solid #B2E576',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img src="/images/chapaImg.png" width="165" height="52" className="" alt="Deposit"></img>
                        <Typography fontWeight="500" sx={{ color: '#000', textTransform: 'capitalize' }}>
                            Deposit via <span style={{ color: '#7DC400' }}>Chapa</span>
                        </Typography>
                    </Button>

                    <Button
                        variant="text"
                        LinkComponent={Link}
                        to="/user/paymentrequest/santimpay_deposit"
                        sx={{
                            width: '230px',
                            height: '126px',
                            border: '1px solid #B2E576',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ml: 3,
                        }}
                    >
                        <img src="/images/santim.png" width="49" height="49" className="" alt="Deposit"></img>
                        <Typography fontWeight="500" sx={{ color: '#000', textTransform: 'capitalize' }}>
                            Deposit via <span style={{ color: '#D5B13B' }}> Santim Pay </span>
                        </Typography>
                    </Button>
                </Stack>
            </Box>

            {/* row 3 */}

            <Box className="dTitleRow">
                <Typography sx={{ mb: 1, fontWeight: '500', fontSize: '17px' }}>Withdraw</Typography>

                <Stack direction="row">
                    <Button
                        variant="text"
                        LinkComponent={Link}
                        to="/user/paymentrequest/chapa_withdraw"
                        sx={{
                            width: '230px',
                            height: '126px',
                            border: '1px solid #B2E576',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img src="/images/chapaImg.png" width="165" height="52" className="" alt="Deposit"></img>
                        <Typography fontWeight="500" sx={{ color: '#000', textTransform: 'capitalize' }}>
                            Withdraw via <span style={{ color: '#7DC400' }}>Chapa</span>
                        </Typography>
                    </Button>

                    <Button
                        variant="text"
                        LinkComponent={Link}
                        to="/user/paymentrequest/santimpay_withdraw"
                        sx={{
                            width: '230px',
                            height: '126px',
                            border: '1px solid #B2E576',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ml: 3,
                        }}
                    >
                        <img src="/images/santim.png" width="49" height="49" className="" alt="Deposit"></img>
                        <Typography fontWeight="500" sx={{ color: '#000', textTransform: 'capitalize' }}>
                            Withdraw via <span style={{ color: '#D5B13B' }}> Santim Pay </span>
                        </Typography>
                    </Button>
                </Stack>
            </Box>

            {/* code stack */}
        </Box>
    );
};

export default MiddleDepositsAndWithdrawalsTab;
