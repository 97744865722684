import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { React, Suspense, lazy, useContext, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AutoLogout from './AutoLogout';

import theme from './Theme.jsx';
// import { UserContext } from './components/contexts/UserContext';
import LoadingOverlay from './components/layout/LoadingOverlay';
import Navigation from './components/layout/Navigation';
import TopHeader from './components/layout/TopHeader';
import Auth from './components/pages/Auth/Auth';
import ForgotPassword from './components/pages/Auth/ForgotPassword';

import { UserContext } from './components/contexts/UserContext';
import UniversalSportBook from './components/layout/UniversalSportBook.js';
import UpdateNotification from './components/multiUse/Notification';
import Payments from './components/pages/Payments/Payments';
import SantimPaymentStatus from './components/pages/Payments/SantimPaymentStatus.js';
import Contact from './components/pages/Static/Contact';
import TermsCondition from './components/pages/Static/TermsCondition';
import User from './components/pages/User/User';
import Aviator from './components/pages/Virtualsgames/Aviator.js';
import Slots from './components/pages/Virtualsgames/Slots.js';
import Virtualsgames from './components/pages/Virtualsgames/Virtualsgames';
import config from './config';
const NotFoundComponent = lazy(() => import('./components/pages/NotFoundComponent'));
const FilteredPreMatch = lazy(() => import('./components/pages/FilteredPreMatch/FilteredPreMatch'));
const Home = lazy(() => import('./components/pages/Home/Home'));
const Detail = lazy(() => import('./components/pages/MatchDetail/Detail'));
const TopBet = lazy(() => import('./components/pages/TopBet/TopBet'));
const LiveGames = lazy(() => import('./components/pages/LiveGames/LiveGames'));

function App({ data }) {
    const [showOverlay, setShowOverlay] = useState(true);
    const { user, checkuser } = useContext(UserContext);
    // Simulating a delay before hiding the overlay
    useEffect(() => {
        setTimeout(() => {
            setShowOverlay(false);
        }, 900);
    }, []);

    // Dispatch the checkUser action on component mount
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData) checkuser(userData);

        const checkScreenSize = () => {
            const userAgent = navigator.userAgent.toLowerCase();
            const screenWidth = window.innerWidth;
            if (userAgent.indexOf('android') > -1) {
                // Redirect to a specific URL for Android devices
                window.location.href = config.mobileUrl;
            } else if (screenWidth < 768) {
                window.location.href = config.mobileUrl; // Replace with the desired URL
            }
        };

        checkScreenSize();

        // Add event listener to check screen size on window resize
        window.addEventListener('resize', checkScreenSize);

        document.addEventListener('contextmenu', (e) => {
            e.preventDefault(); // Prevent the default behavior of right-click
        });
        // Prevent certain key combinations
        document.addEventListener('keydown', (event) => {
            if (
                (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'i')) ||
                (event.ctrlKey && event.shiftKey && (event.key === 'J' || event.key === 'j')) ||
                (event.ctrlKey && event.shiftKey && (event.key === 'C' || event.key === 'c')) ||
                (event.ctrlKey && event.shiftKey && (event.key === 'U' || event.key === 'u')) ||
                event.key === 'F12'
            ) {
                event.preventDefault();
            }
        });

        console.log('%cStop!', 'color: red; font-size: 40px; font-weight: bold;');
        console.log('%cThis is a browser feature intended for developers.', 'font-size: 16px;');
        console.log(
            '%cIf someone told you to copy-paste something here to enable a feature or "hack" someone\'s account, it is a scam.',
            'font-size: 16px;'
        );

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, [checkuser, window.innerWidth]);
    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <UniversalSportBook />
                    <TopHeader />
                    <Navigation />
                    <ToastContainer />
                    <Suspense fallback={<LoadingOverlay />}>
                        {showOverlay ? (
                            <LoadingOverlay />
                        ) : (
                            <Routes>
                                {/* <Route exact path="/" element={<Virtualsgames />} /> */}
                                <Route exact path="/" element={<Home />} />
                                <Route exact path="/:path" element={<Home />} />
                                <Route path="/prematch/detail/:id" element={<Detail type="prematch" />} />
                                {/* <Route path="/live/detail/:id" element={<Detail type="live" />} /> */}
                                {/* <Route path="/offer/topbet/:id" element={<TopBet />} /> */}
                                {/* <Route path="/live" element={<LiveGames />} /> */}
                                <Route path="/virtuals" element={<Virtualsgames />} />
                                <Route path="/slot" element={<Slots />} />
                                <Route path="/aviator" element={<Aviator />} />
                                <Route
                                    path="/searchresult/:searchText"
                                    element={<FilteredPreMatch type="searchresult" />}
                                />
                                <Route
                                    path="/registration"
                                    element={!user ? <Auth path="Register" /> : <Navigate to="/" />}
                                />

                                <Route
                                    path="/forgotpassword"
                                    element={!user ? <ForgotPassword /> : <Navigate to="/" />}
                                />

                                <Route path="/contact" element={<Contact />} />

                                <Route path="/termscondition" element={<TermsCondition />} />

                                {/* User Routes */}
                                <Route
                                    path="/user/tickets"
                                    element={user ? <User path="Tickets" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/VirtualsTicket"
                                    element={user ? <User path="VirtualsTicket" /> : <Navigate to="/" />}
                                />
                                <Route path="/user/support" element={<User path="Support" />} />
                                <Route
                                    path="/user/LiveTickets"
                                    element={user ? <User path="LiveTickets" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/profile"
                                    element={user ? <User path="Profile" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/depositsandwithdrawals"
                                    element={user ? <User path="DepositsAndWithdrawals" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/transactions"
                                    element={user ? <User path="Transactions" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/paymentrequest/payments_deposit"
                                    element={user ? <Payments path="BranchDeposit" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/paymentrequest/payments_withdraw"
                                    element={user ? <Payments path="BranchWithdraw" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/paymentrequest/chapa_deposit"
                                    element={user ? <Payments path="ChapaDeposit" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/paymentrequest/chapa_withdraw"
                                    element={user ? <Payments path="ChapaWithdraw" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/paymentrequest/santimpay_deposit"
                                    element={user ? <Payments path="SantimPayDeposit" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/paymentrequest/santimpay_withdraw"
                                    element={user ? <Payments path="SantimPayWithdraw" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/paymentrequest/transaction/chapa/status"
                                    element={user ? <Payments path="SuccessPayment" /> : <Navigate to="/" />}
                                />
                                <Route
                                    path="/user/paymentrequest/transaction/santim/:status"
                                    element={user ? <SantimPaymentStatus /> : <Navigate to="/" />}
                                />

                                {/* 404 Route */}
                                <Route path="*" element={<NotFoundComponent />} />
                            </Routes>
                        )}
                    </Suspense>
                    <AutoLogout />
                    <UpdateNotification /> 
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
}

const mapStateToProps = (state) => ({
    data: state.data, // Map user state from Redux to the user prop
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
