import SantimPayForm from './SantimPayForm';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { Paper, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useContext, useEffect } from 'react';
import { backToPrivious } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';

const SantimPayDeposit = () => {
    const { user,updateuserBalance } = useContext(UserContext);

    useEffect(() => {
        updateuserBalance(user)
    }, [])
    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                    paddingBottom: '20px',
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Box width="100%">
                    <Stack direction="row" alignItems="center">
                        <Typography
                            color="black"
                            mr={1}
                            variant="h6"
                            sx={{
                                fontSize: '16px;',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                            }}
                        >
                            Deposit via
                        </Typography>

                        <img src="/images/santim.png" width="49" height="49" className="" alt="Deposit"></img>
                        <Button
                            color="primary"
                            onClick={() => backToPrivious()}
                            // variant="text"
                            sx={{ color: '#000', fontWeight: 600, minWidth: '40px', ml: 'auto' }}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Back
                        </Button>
                    </Stack>
                </Box>

                {/* conent start */}
                <Box className="branchList">
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Steps
                    </Typography>
                    <Typography variant="body2">1) Input the amount you wish to deposit and click DEPOSIT.</Typography>
                    <Typography variant="body2">2) You will be redirected to the Santim platform.</Typography>
                    <Typography variant="body2">3) Authorize the transaction and finish the process.</Typography>
                    {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Note: This transaction can be cancelled within 5 minutes if there is a mistake.
                    </Typography> */}
                </Box>

                <Paper elevation={0} variant="outlined" className="branchTable">
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Fees</TableCell>
                                <TableCell>No fees applied</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Processing time</TableCell>
                                <TableCell>Within 5 minutes</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Minimum amount per transaction</TableCell>
                                <TableCell>10.00 ETB</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Maximum amount per transaction</TableCell>
                                <TableCell>Unlimited</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>

                {/* form content */}

                <SantimPayForm type="deposit" />
            </Box>
        </>
    );
};

export default SantimPayDeposit;
