import { Button, FormLabel } from '@mui/material';
import { connect } from 'react-redux';

import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { formatNumber, getBetCalculation, roundto2 } from '../../../../services/helper';
import { UserContext } from '../../../contexts/UserContext';
import PlaceBetModel from '../PlaceBetModel';
import SaveTicketModal from '../SaveTicketModal';
import TicketPreviewModal from '../TicketPreviewModal';

const OddSection = ({ show, items, newBet, odd, market, tab, betType, activeTab, checkBetSlipReducer }) => {
    const location = useLocation();

    const { user, systemSetting } = useContext(UserContext);
    const linkStyle = {
        color: '#e18900',
        textDecoration: 'none',
        fontSize: '14px',
        padding: '5px',
        display: 'block',
        marginTop: '5px',

        // padding:"0"
    };
    const vatPercentage = Number(systemSetting['VAT (%)']);
    const incomeTaxThreshold = Number(systemSetting['Income Tax Threshold']);
    const incomeTaxPercentage = Number(systemSetting['Income Tax (%)']);
    const minimumBetAmount = Number(systemSetting['Minimum Bet Amount']);
    const maximumBetAmount = Number(systemSetting['Maximum Bet Amount']);
    const maximumWinAmount = Number(systemSetting['Maximum Win Amount']);
    const dailyMaxBetAmount = Number(systemSetting['Daily Maximum Bet Amount']);
    const weeklyMaxBetAmount = Number(systemSetting['Weekly Maximum Bet Amount']);
    const monthlyMaxBetAmount = Number(systemSetting['Monthly Maximum Bet Amount']);
    // function for input increase
    const [betAmount, setBetAmount] = useState(minimumBetAmount ? minimumBetAmount : 20);
    const [totalOdds, setTotalOdds] = useState(0.0);
    const [vatAmount, setVatAmount] = useState(0.0);
    const [actualBetAmount, setActualBetAmount] = useState(0.0);
    const [taxAmount, setTaxAmount] = useState(0.0);
    const [winngAmount, setWinngAmount] = useState(0.0);


    const handleIncrement = () => {
        setBetAmount(betAmount + 1);
    };

    const handleDecrement = () => {
        if (betAmount > 10) {
            setBetAmount(betAmount - 1);
        }
    };

    const handleAmount = (amt) => {
        setBetAmount(amt);
    };

    const getSelectedOdd = (oddSelectedId, MarketSelectedId, fixture) => {
        const oddData = fixture.hasOwnProperty('Market')
            ? fixture.Market?.Odds.find((item) => item.id === oddSelectedId)
            : fixture.Markets?.find((market) => market.id === MarketSelectedId).Odds.find(
                  (item) => item.id === oddSelectedId
              );
        return roundto2(oddData?.price);
    };

    useEffect(() => {
        let [totalOdds, vatAmount, actualBetAmount, taxAmount, winngAmount] = getBetCalculation(
            items,
            vatPercentage,
            betAmount,
            incomeTaxThreshold,
            incomeTaxPercentage,
        );

        setTotalOdds(totalOdds);
        setVatAmount(vatAmount);
        setActualBetAmount(actualBetAmount);
        setTaxAmount(taxAmount);
        setWinngAmount(winngAmount);
    }, [
        activeTab,
        tab,
        items,
        betAmount,
        vatPercentage,
        incomeTaxPercentage,
        incomeTaxThreshold
      ]);

    return (
        <div className={'totalOddsHolder' + show}>
            <div className="totalOddsTitle">
                <p className="posWin">TOTAL ODDS</p>

                <p className="posAmount">{formatNumber(totalOdds)}</p>
            </div>

            <div className="betIncreaseRow">
                <div className="betTitle">Total stake</div>
                <div className="betCounter">
                    <Button onClick={handleDecrement} aria-label="Minus">
                        -
                    </Button>
                    <input
                        type="number"
                        className="inputNumberStyle"
                        onChange={(e) => {
                            handleAmount(Number(e.target.value));
                        }}
                        value={betAmount}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]*/g, '');
                        }}
                    />
                    <Button onClick={handleIncrement} aria-label="Plus">
                        +
                    </Button>
                </div>
            </div>
            <div className="betAmountSugg">
                <ul className="betAmuntUl">
                    <li>
                        <Button
                            aria-label="20"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                                handleAmount(20);
                            }}
                        >
                            20
                        </Button>
                    </li>
                    <li>
                        <Button
                            aria-label="50"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                                handleAmount(50);
                            }}
                        >
                            50
                        </Button>
                    </li>

                    <li>
                        <Button
                            aria-label="100"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                                handleAmount(100);
                            }}
                        >
                            100
                        </Button>
                    </li>

                    <li>
                        <Button
                            aria-label="150"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                                handleAmount(150);
                            }}
                        >
                            150
                        </Button>
                    </li>
                </ul>
            </div>

            <div className="totalOddsTitle possibleWIn">
                <p className="posWin">POSSIBLE WIN</p>

                <p className="posAmount">{formatNumber(winngAmount)} ETB</p>
            </div>

            <div className={`loginBadge ${!items.some((item) => item.FixtureStatus === 'non-active') ? 'd-none' : ''}`}>
                <FormLabel style={linkStyle}>Please remove live matches to save or place bet </FormLabel>
            </div>

            {user ? (
                <>
                    {' '}
                    <div className="tktPrevieRow">
                        <ul className="betAmuntUl">
                            <li>
                                <TicketPreviewModal
                                    tab={tab}
                                    items={items}
                                    user={user}
                                    systemSetting={systemSetting}
                                    totalOdds={totalOdds?.toFixed(2)}
                                    winngAmount={winngAmount.toFixed(2)}
                                    betAmount={betAmount.toFixed(2)}
                                    vatAmount={vatAmount.toFixed(2)}
                                    actualBetAmount={actualBetAmount.toFixed(2)}
                                    taxAmount={taxAmount.toFixed(2)}
                                />
                            </li>
                            <li>
                                <PlaceBetModel
                                    type={activeTab}
                                    tab={tab}
                                    items={items}
                                    systemSetting={systemSetting}
                                    totalOdds={totalOdds?.toFixed(2)}
                                    winngAmount={winngAmount.toFixed(2)}
                                    betAmount={betAmount.toFixed(2)}
                                    vatAmount={vatAmount.toFixed(2)}
                                    actualBetAmount={actualBetAmount.toFixed(2)}
                                    taxAmount={taxAmount.toFixed(2)}
                                    checkBetSlipReducer={checkBetSlipReducer}
                                />
                            </li>
                        </ul>
                    </div>
                    {/* <div className="placeButtonRow">
                        <PlaceBetModel
                            items={items}
                            totalOdds={totalOdds.toFixed(2)}
                            winngAmount={winngAmount.toFixed(2)}
                            betAmount={betAmount.toFixed(2)}
                            vatAmount={vatAmount.toFixed(2)}
                            actualBetAmount={actualBetAmount.toFixed(2)}
                            taxAmount={taxAmount.toFixed(2)}
                        />
                    </div> */}
                </>
            ) : (
                <>
                    <div className="tktPrevieRow">
                        <ul className="betAmuntUl">
                            <li>
                                <TicketPreviewModal
                                    items={items}
                                    user={user}
                                    systemSetting={systemSetting}
                                    totalOdds={totalOdds.toFixed(2)}
                                    winngAmount={winngAmount.toFixed(2)}
                                    betAmount={betAmount.toFixed(2)}
                                    vatAmount={vatAmount.toFixed(2)}
                                    actualBetAmount={actualBetAmount.toFixed(2)}
                                    taxAmount={taxAmount.toFixed(2)}
                                />
                            </li>
                            <li className="saveTktBtn">
                                <SaveTicketModal
                                    items={items}
                                    text={'Save Ticket'}
                                    systemSetting={systemSetting}
                                    totalOdds={totalOdds.toFixed(2)}
                                    winngAmount={winngAmount.toFixed(2)}
                                    betAmount={betAmount.toFixed(2)}
                                    vatAmount={vatAmount.toFixed(2)}
                                    actualBetAmount={actualBetAmount.toFixed(2)}
                                    taxAmount={taxAmount.toFixed(2)}
                                    checkBetSlipReducer={checkBetSlipReducer}
                                    tab={tab}
                                />
                            </li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeTab: state.data.activeTab,
    betslip1: state.data.betslip1,
    betslip2: state.data.betslip2,
    betslip3: state.data.betslip3,
    livebetslip: state.data.livebetslip,
    sportbook: state.data.sportbook,
});

export default connect(mapStateToProps)(OddSection);
