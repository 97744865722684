import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Button, FormControl, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import LottieLoader from 'react-lottie-loader';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import sportLoaderData from '../../../loader/sport-loader_2.json';
import { addOrUpdateItem, onDeleteItem, setActiveTab } from '../../../redux/actions';
import { postDataWithoutAuth } from '../../../services/api';
import {
    ToastError,
    formatDate,
    getCurrentBetslip,
    getNext7Days,
    getSportIcon,
    sortObjectArray,
} from '../../../services/helper';
import LiveMatches from '../LiveGames/LiveMatches';

const LivePlayingNowMenu = ({ betslip, onDeleteItem, onAddItem, setActiveTab }) => {
    const [value, setValue] = useState(0);
    const days = getNext7Days();
    const handleTabs = (e, val) => {
        setValue(val);
    };
    const [secheduledate, setSecheduleDate] = useState(days[0].date);
    const [secheduledata, setSecheduleData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState('SORT BY');

    const dropdownStyle = {
        padding: '6.5px',
        background: '#fff',
        margin: '0 2px',
        fontWeight: '600',
        fontSize: '0.875rem',
        border: '1px solid #ccc',
    };

    const handleChange = (event) => {
        setSort(event.target.value);
        sortObjectArray(secheduledata, event.target.value);
    };

    const handleScheduleChange = (value) => {
        setSecheduleDate(value.date);
        fetchScheduleData(value.date);
    };

    const fetchScheduleData = async (value) => {
        toast.dismiss();
        try {
            setLoading(true);
            const fetchedData = await postDataWithoutAuth('/auth/prematch/calendar', {
                data: {
                    date: value,
                },
            });
            const sortedData = sortObjectArray(fetchedData.data, sort);
            setSecheduleData(sortedData);
            setLoading(false);
        } catch (error) {
            console.log('error :>> ', error);
            ToastError(error.response.data.message);
            // toast.error(error.response.data.message, {
            //     position: 'top-center',
            //     autoClose: 1000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     theme: 'colored',
            // });
        }
    };

    useEffect(() => {
        fetchScheduleData(secheduledate);
        setActiveTab('live');
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    p: 2,

                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                    display: 'flex',
                }}
            >
                <Stack width="100%">
                    <Box className="cFpRelative">
                        <Tabs
                            sx={{
                                '& .Mui-selected': { color: '#fff !important', backgroundColor: '#074652' },
                                '& .MuiTabs-indicator': { display: 'none' },
                                alignItems: 'center',
                            }}
                            value={value}
                            className="liveTabUi"
                            onChange={handleTabs}
                        >
                            <Tab
                                label={
                                    <span>
                                        Playing Now <span className="liveBadge">Live</span>{' '}
                                    </span>
                                }
                            />
                            <Tab icon={<CalendarMonthIcon />} iconPosition="start" label="Schedule" />
                        </Tabs>
                        {/* tab panel */}
                        <TabPanel value={value} index={0}>
                            <Box mt={1}>
                                {/* <LiveFavorite /> */}
                                <LiveMatches />
                            </Box>
                        </TabPanel>
                        {/* tab panel 2  */}
                        <TabPanel value={value} index={1}>
                            <Stack direction="row" className="shdeuledBtns">
                                {days.map((day) => (
                                    <Button
                                        aria-label="Day"
                                        sx={{}}
                                        disableElevation
                                        variant="contained"
                                        className={secheduledate === day.date ? 'active' : ''}
                                        color={secheduledate === day.date ? 'primary' : 'secondary'}
                                        onClick={() => {
                                            handleScheduleChange(day);
                                        }}
                                    >
                                        {day.day} {day.dateString}
                                    </Button>
                                ))}
                            </Stack>

                            <Stack direction="row">
                                <Box mt={1} className="sheduleTab">
                                    <Typography
                                        sx={{
                                            fontSize: '16px;',
                                            fontWeight: 'bold',
                                            mb: 1,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Playing Now
                                    </Typography>
                                    {/* <LiveFavorite /> */}
                                    <LiveMatches />
                                </Box>

                                <Box
                                    sx={{
                                        backgroundColor: 'white.main',

                                        flexBasis: '79%',
                                        mt: 1,
                                        ml: 2,
                                        borderRadius: 2,
                                        mb: 2,
                                        p: 1,
                                        border: '1px solid',
                                        borderColor: 'borderBlueColor.main',
                                    }}
                                    className="sheduleLiveMatches"
                                >
                                    {loading ? (
                                        <LottieLoader className="loaderBall" animationData={sportLoaderData} />
                                    ) : (
                                        secheduledata.map((sechedule, index) => (
                                            <Stack
                                                direction="row"
                                                className="liveSportsrow"
                                                justifyContent="space-around"
                                                sx={{ width: '100%' }}
                                            >
                                                <div className="liveTime">
                                                    {formatDate(sechedule.start_date, 'livetime')}
                                                </div>
                                                <div className="livesportname">
                                                    {getSportIcon(sechedule.sport.name)}
                                                    {sechedule.sport.name}
                                                </div>
                                                <div className="liveLegaueName">{sechedule.fixture_name}</div>
                                                <div className="liveLeagueLocation">
                                                    {sechedule.location.name} - {sechedule.league.name}
                                                </div>
                                            </Stack>
                                        ))
                                    )}
                                </Box>
                            </Stack>
                        </TabPanel>

                        <FormControl
                            size="small"
                            className="sortingDrop"
                            sx={{ position: 'absolute', right: 0, top: '4px' }}
                        >
                            <Select
                                id="demo-customized-select"
                                value={sort}
                                onChange={handleChange}
                                style={dropdownStyle}
                                // IconComponent={() => <ArrowRightIcon />}
                                sx={{
                                    boxShadow: 'none',
                                    padding: '5px',
                                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                }}
                            >
                                <MenuItem value={'SORT BY'}>SORT BY</MenuItem>
                                <MenuItem value={'SPORT'}>SPORT</MenuItem>
                                <MenuItem value={'TIME'}>TIME</MenuItem>
                                <MenuItem value={'NAME'}>NAME</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>
            </Box>
        </>
    );
};

function TabPanel(props) {
    const { children, value, index } = props;

    return <>{value === index && <div> {children}</div>}</>;
}

const mapStateToProps = (state) => ({
    tab: state.data.activeTab,
    betslip: state.data[getCurrentBetslip(state.data.activeTab)],
});

const mapDispatchToProps = (dispatch) => ({
    addOrUpdateItem: (item, odd, market, tab) => dispatch(addOrUpdateItem(item, odd, market, tab)),
    onDeleteItem: (itemId, tab) => dispatch(onDeleteItem(itemId, tab)),
    setActiveTab: (tabId) => dispatch(setActiveTab(tabId)), // Add this line to dispatch setActiveTab
});

export default connect(mapStateToProps, mapDispatchToProps)(LivePlayingNowMenu);
// export default LivePlayingNowMenu;
