import axios from 'axios';
import config from '../config';

// Create an Axios instance with a cancel token source
const axiosInstance = axios.create();// const { CancelToken } = axios;
// let cancelTokenSource;

const maxRetries = 3;
const retryDelay = 1000; // 1 second delay between retries

// Add a request interceptor to set the cancel token for each request
axiosInstance.interceptors.request.use((config) => {

  if (config.retry) {
    // If this request is a retry, increase the retry count
    config.retryCount = config.retryCount || 0;
  }


  // Cancel the previous request, if any
  // if (cancelTokenSource) {
  //   cancelTokenSource.cancel('New request initiated');
  // }

  // Create a new cancel token source for the current request
  // cancelTokenSource = CancelToken.source();
  // config.cancelToken = cancelTokenSource.token;

  // Add common headers
//   config.headers['Content-Type'] = 'application/json';
//   config.headers['API-KEY'] = API_KEY;

  return config;
});

// Add a response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (axios.isCancel(error)) {
      // Request was canceled, handle as needed
      console.log('Request canceled:', error.message);
    }

    if (error.config && error.config.retryCount < maxRetries) {
      // Retry the request with an increasing delay
      error.config.retryCount = error.config.retryCount || 0;
      error.config.retryCount += 1;

      console.log(`Retrying request (${error.config.retryCount}/${maxRetries})`);

      await new Promise(resolve => setTimeout(resolve, retryDelay));

      // Retry the request
      return axiosInstance(error.config);
    }
    throw error;
  }
);

// Export the enhanced axios instance
export default axiosInstance;