import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container } from '@mui/system';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { postData, registerUser } from '../../../services/api';
// import { formatDate } from '../../../services/helper';
import { ToastError, ToastSuccess, generateHash } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';
import Footer from '../../layout/Footer';
import OtpModel from '../Auth/OtpModel';

const schema = yup.object().shape({
    // dob: yup
    //     .string()
    //     .nullable()
    //     .required('Date of Birth is required')
    //     .max(new Date(), 'Date of Birth cannot be in the future')
    //     .test('is-adult', 'You must be at least 18 years old', (value) => {
    //         if (!value) return false; // Invalid date
    //         const today = new Date();
    //         const dob = new Date(value);
    //         const age = today.getFullYear() - dob.getFullYear();
    //         if (age > 18) return true;
    //         if (age === 18) {
    //             // Check month and day for 18th birthday
    //             if (today.getMonth() > dob.getMonth()) return true;
    //             if (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate()) return true;
    //         }
    //         return false;
    //     }),

    first_name: yup
        .string()
        .required('First Name is required')
        .matches(/^\S*$/, 'No white space allowed')
        .matches(/^[A-Za-z]+$/, 'No numbers allowed')
        .min(2, 'First Name must be at least 2 characters')
        .max(25),
    last_name: yup
        .string()
        .required('Last Name is required')
        .matches(/^\S*$/, 'No white space allowed')
        .matches(/^[A-Za-z]+$/, 'No numbers allowed')
        .min(2, 'Last Name must be at least 2 characters')
        .max(25),
    username: yup
        .string()
        .required('Username is required')
        // .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters are allowed')
        .matches(/^\S*$/, 'No white space allowed')

        .min(3)
        .max(30),
    phone: yup
        .string()
        .required('Phone Number is required')
        .min(7, 'Phone Number should have atleast 7 digits')
        .max(12, 'maximum 12 digits allowed'),

    // email: yup.lazy((value) => {
    //     if (value) {
    //         return yup
    //             .string()
    //             .email('Email must be a valid email')
    //             .matches(/^\S.*$/, 'No starting space allowed');
    //     }

    //     return yup.string();
    // }),
    // DOB: yup.date().required('Date Of Birth is required'),
    password: yup.string().required('Please Enter your password').min(6),
    // .matches(/^\S.*$/, 'No starting space allowed')
    // .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
    //     'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    // )
    checkbox: yup.bool().oneOf([true], 'You must accept the terms and conditions'),
});
const Register = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [date, setDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [disable, setDisable] = useState(false);
    const [seconds, setSeconds] = useState(5 * 60);
    const [otpVal, setOtpVal] = useState('');
    const [userId, setUserId] = useState([]);
    const [phone, setPhone] = useState(null);
    const [username, setUsername] = useState('');
    const [OtpBtn, setOtpBtn] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const { user, login } = useContext(UserContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control,
        trigger,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (userValues) => {
        setIsClicked(true);
        toast.dismiss();

        const data = {
            data: {
                first_name: userValues.first_name,
                last_name: userValues.last_name,
                username: username,
                password: userValues.password,
                // email: userValues.email,
                country_code: userValues.country_code,
                // dob: userValues.dob,
                phone: userValues.phone,
            },
        };

        const hashSignature = generateHash(data['data']);
        data.data.hash = hashSignature;

        let res = await registerUser('/auth/register', data, setIsClicked);
        setUserId(res.data);
        if (res.data?.status_code === 200) {
            setTimeout(() => {
                setOpen(true);
                setIsClicked(false);
            }, 700);
        }
    };

    const handleOnChange = (value, data) => {
        let country_code = data.dialCode;
        let phone_no = value.slice(data.dialCode.length);
        setValue('phone', phone_no, true);
        setValue('country_code', country_code);
    };

    const today = new Date();
    const todaydate = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear() - 18;

    const onSubmitOtp = async () => {
        try {
            if (otpVal !== '') {
                if (otpVal.length !== 6) {
                    setError('OTP should be 6 digits long.');
                    return;
                }

                if (!/^\d+$/.test(otpVal)) {
                    setError('OTP should contain only numbers.');
                    return;
                }
            } else {
                setError('Please Enter OTP');
                return;
            }
            setOtpBtn(true);

            const data = {
                data: {
                    user_id: userId.data[0].user_id,
                    otp: otpVal,
                },
            };

            const profileData = await postData('/auth/user/verify/otp', data);
            ToastSuccess(profileData.message);
            setError('');
            if (profileData.status_code === 200) {
                setTimeout(() => {
                    reset();
                    setDate('');
                    setPhone('');
                    setOtpBtn(false);
                    setOpen(false);
                    setOtpVal('');
                    reset();
                    localStorage.setItem('userData', JSON.stringify(profileData.data[0]));
                    login(JSON.parse(localStorage.getItem('userData')));
                }, 600);
            }
            if (profileData.status_code === 422) {
                setError('');
            }
        } catch (error) {
            setError('');
            console.log('otp error:>> ', error);
            ToastError(error.response.data.message);
            setTimeout(() => {
                setOtpBtn(false);
            }, 1000);
        }
    };
    const handleClose = async () => {
        toast.dismiss();
        const data = {
            data: {
                user_id: userId.data[0].user_id,
            },
        };
        try {
            const cancleRequestRespone = await postData('/auth/user/cancel', data);
            if (cancleRequestRespone.status_code === 200) {
                setOtpBtn(false);
                setOpen(false);
                setOtpVal('');
                setError('');
                setTimeout(() => {
                    setSeconds(5 * 60);
                    setDisable(false);
                }, 500);
                ToastSuccess(cancleRequestRespone.message);
            }
        } catch (error) {
            console.log('otp error:>> ', error);
        }
        setOpen(false);
    };

    const handleOtpChange = (newValue) => {
        setOtpVal(newValue);
        setError('');
    };
    const onResendOtp = async () => {
        setDisable(true);
        setOtpVal('');
        handleOtpChange(''); // reset the value and error
        const data = {
            data: {
                user_id: userId.data[0].user_id,
            },
        };
        try {
            const profileData = await postData('/auth/user/resent/otp', data);
            if (profileData.status_code === 200) {
                setSeconds(5 * 60);
                ToastSuccess(profileData.message);
                setError('');
                setTimeout(() => {
                    setDisable(false);
                }, 300000);
                setOtpBtn(false);
            }
        } catch (error) {
            console.log('resend :>> ', error);
        }
    };
    // const onchangedate = async (e) => {
    //     if (e !== null) {
    //         const start_date = new Date(e);
    //         const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    //         await trigger('dob');
    //         setValue('dob', utcstartDate);
    //         setDate(dayjs(formatDate(e, 'datepicker')));
    //     }
    // };
    return (
        <>
            <Container maxWidth="lg" className="RegisterPage" sx={{ marginTop: '30px' }}>
                <Box
                    sx={{
                        backgroundColor: 'white.main',
                        borderRadius: 2,
                        mb: 4,
                        p: 2,
                        border: '1px solid',
                        borderColor: 'borderBlueColor.main',
                    }}
                >
                    <Box display="flex">
                        {' '}
                        <PersonIcon color="black" />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                mb: 1,
                                ml: 1,
                            }}
                        >
                            Register for new account
                        </Typography>{' '}
                    </Box>

                    <Grid container spacing={1} pl={2} pr={2}>
                        <Grid item xs={12} sm={12}>
                            <Box component="form" mt={2} onSubmit={handleSubmit(onSubmit)}>
                                <Stack direction="row" spacing={2} mb="20px">
                                    <Box width="50%">
                                        <TextField
                                            color="black"
                                            sx={{}}
                                            {...register('first_name')}
                                            name="first_name"
                                            fullWidth
                                            variant="outlined"
                                            label="First Name"
                                            placeholder="Enter First Name"
                                            disableUnderline
                                            autoFocus="autofocus"
                                        />
                                        <Typography variant="body2" color="error">
                                            {errors.first_name?.message}
                                        </Typography>
                                    </Box>

                                    {/* box2 */}
                                    <Box width="50%">
                                        <TextField
                                            color="black"
                                            sx={{}}
                                            placeholder="Enter Last Name"
                                            {...register('last_name')}
                                            name="last_name"
                                            fullWidth
                                            variant="outlined"
                                            label="Last Name"
                                            validateOnLoad={true}
                                            disableUnderline
                                        ></TextField>

                                        <Typography variant="body2" color="error">
                                            {errors.last_name?.message}
                                        </Typography>
                                    </Box>
                                </Stack>

                                <Stack mb="20px" direction="row" spacing={2}>
                                    <Box width="50%">
                                        <TextField
                                            color="black"
                                            validateOnLoad={false}
                                            sx={{}}
                                            placeholder="Enter Username"
                                            {...register('username')}
                                            name="username"
                                            secondary
                                            fullWidth
                                            value={username}
                                            variant="outlined"
                                            label="Username"
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const sanitizedValue = inputValue.replace(/[^A-Za-z0-9]/g, '');
                                                setUsername(sanitizedValue);
                                            }}
                                            disableUnderline
                                        />
                                        <Typography variant="body2" color="error">
                                            {errors.username?.message}
                                        </Typography>
                                    </Box>
                                    <Box width="50%">
                                        <PhoneInput
                                            color="black"
                                            inputProps={{
                                                name: 'phone',
                                                autoFocus: true,
                                            }}
                                            style={{
                                                backgroundColor: '#fff',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                            }}
                                            inputStyle={{
                                                background: 'none',
                                                border: 'none',
                                                fontWeight: 'normal',
                                                width: '100%',
                                            }}
                                            variant="outlined"
                                            placeholder="Phone No"
                                            size="large"
                                            country={'et'}
                                            value={phone}
                                            onChange={handleOnChange}
                                        />
                                        <Typography variant="body2" color="error">
                                            {errors.phone?.message}
                                        </Typography>
                                    </Box>
                                    {/* <Box width="50%">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Controller
                                                name="dob"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        sx={{ width: '100%' }}
                                                        maxDate={dayjs(`${month}-${todaydate}-${year}`)}
                                                        value={date}
                                                        // {...register('dob')}
                                                        onChange={(newValue) => {
                                                            field.onChange(newValue);
                                                            onchangedate(newValue);
                                                        }}
                                                        format="DD-MM-YYYY"
                                                        slotProps={{
                                                            textField: {
                                                                error: false,
                                                            },
                                                        }}
                                                        label="Date Of Birth"
                                                        renderInput={(params) => (
                                                            <TextField {...params} size="medium" marginBottom="20px" />
                                                        )}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        <Typography variant="body2" color="error">
                                            {errors.dob?.message}
                                        </Typography>
                                    </Box> */}
                                </Stack>
                                <Stack mb="20px" direction="row" spacing={2}>
                                    {/* <Box width="50%">
                                        <TextField
                                            color="black"
                                            sx={{}}
                                            placeholder="Enter Email"
                                            {...register('email')}
                                            name="email"
                                            secondary
                                            fullWidth
                                            variant="outlined"
                                            label="Email"
                                            autoComplete="new-password"
                                            disableUnderline
                                        ></TextField>

                                        <Typography variant="body2" color="error">
                                            {errors.email?.message}
                                        </Typography>
                                    </Box> */}
                                </Stack>

                                <Stack mb="20px" direction="row" spacing={2}>
                                    <Box width="50%">
                                        <TextField
                                            color="black"
                                            placeholder="Enter Password"
                                            {...register('password')}
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            sx={{}}
                                            // helperText="Required"
                                            autoComplete={'off'}
                                            fullWidth
                                            variant="outlined"
                                            label="Your Password"
                                            // required
                                            // disableUnderline
                                            InputProps={{
                                                autoComplete: 'new-password',
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        <Typography variant="body2" color="error">
                                            {errors.password?.message}
                                        </Typography>
                                    </Box>
                                </Stack>

                                <Stack mb="20px" display={'flex'} flexDirection="row">
                                    <Box>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...register('checkbox')}
                                                        type="checkbox"
                                                        name="checkbox"
                                                    />
                                                }
                                                // label="I have read and accepted the General Terms and Conditions"
                                            />
                                        </FormGroup>
                                    </Box>
                                    <Box style={{ marginTop: '8px', marginLeft: '-12px' }}>
                                        I have read and accepted the General{' '}
                                        <Link to="/termscondition" style={{ fontSize: '15px' }}>
                                            {' '}
                                            Terms and Conditions
                                        </Link>{' '}
                                        <Typography variant="body2" color="error">
                                            {errors.checkbox?.message}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack alignItems="flex-end">
                                    <Button
                                        aria-label="Register"
                                        variant="contained"
                                        type="submit"
                                        disabled={isClicked}
                                        sx={{
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                            marginTop: '20px',
                                            fontWeight: '600',
                                            marginBottom: '20px',
                                            paddingLeft: '40px',
                                            paddingRight: '40px',
                                            backgroundColor: '#074652',
                                            color: '#fff',
                                            '&:hover': { backgroundColor: '#074652' },
                                        }}
                                    >
                                        Register
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>
                        <OtpModel
                            open={open}
                            handleClose={handleClose}
                            OtpBtn={OtpBtn}
                            onSubmitOtp={onSubmitOtp}
                            handleOtpChange={handleOtpChange}
                            otpVal={otpVal}
                            onResendOtp={onResendOtp}
                            setSeconds={setSeconds}
                            seconds={seconds}
                            disable={disable}
                            error={error}
                        />
                        {/* <Grid item xs={12} sm={4}>
                            <Box className="stepInstruction">
                                <Paper elevation={0} sx={{ padding: '10px', marginTop: '10px' }}>
                                    <span className="stepNo"> 1 </span>
                                    <Typography variant="body2">
                                        Enter your First name and last name .Think of your Username, which is not used
                                        by any other bettor yet. It must have at least 2 characters. You can combine
                                        both letters and numbers.
                                    </Typography>
                                </Paper>

                                <Paper elevation={0} sx={{ padding: '10px', marginTop: '10px' }}>
                                    <span className="stepNo"> 2 </span>
                                    <Typography variant="body2">
                                        if all fields are valid, finish the registration by clicking on the REGISTER
                                        button. You will receive a SMS message with the login details on your phone
                                    </Typography>
                                </Paper>

                                <Paper elevation={0} sx={{ padding: '10px', marginTop: '10px' }}>
                                    <span className="stepNo"> 3 </span>
                                    <Typography variant="body2">We wish you good luck !</Typography>
                                </Paper>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default Register;
