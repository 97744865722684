import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { postData } from '../../../services/api';
import { ToastError, formatNumber } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';

const schema = yup.object().shape({
    amount: yup
        .string()
        .required('Amount is required')
        // .min(2, 'Amount should have atleast 2 digits')
        .test('greater-than-ten', 'Value must be greater than or equal to 10 ETB', (value) => {
            return parseInt(value) >= 10;
        })
        .max(8, 'maximum 8 digits allowed'),
    phone: yup
        .string()
        .required('Phone Number is required')
        .min(9, 'Phone Number should have atleast 9 digits')
        .max(10, 'maximum 10 digits allowed'),
});

const SantimPayForm = ({ type }) => {
    const { user, login, updateBalance } = useContext(UserContext);
    const [isClicked, setIsClicked] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onIntiateDeposit = async (deposit) => {
        toast.dismiss();
        setIsClicked(true);
        // if (Number(deposit.amount) >= 1) {
        try {
            const data = {
                user_token: user.token,
                data: {
                    payment_type: 2,
                    phone: `+251${deposit.phone}`,
                    amount: deposit.amount,
                },
            };

            const response = await postData('/user/deposit/checkout', data);
            if (response.data[0].hasOwnProperty('checkout_url')) {
                window.location = response.data[0].checkout_url;
                const transactionId = response.data[0].tx_ref; // Replace with the actual transaction ID
                localStorage.setItem('transactionId', transactionId);
                updateBalance(response.data[0].user_balance);
            } else {
                setIsClicked(false);
                ToastError('Something went wrong try again later');
            }
        } catch (error) {
            ToastError(error.response.data.message);
        }
        // } else {
        //     toast.error(`Deposit amount must be greater than  20 ETB`, {
        //         position: 'top-center',
        //         autoClose: 1000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         theme: 'colored',
        //     });
        // }
        setIsClicked(false);
    };

    return (
        <Box
            component="form"
            sx={{
                backgroundColor: '#e3fbff',
                padding: 1,
                margin: '0 auto',
                marginTop: '20px',
                border: '1px solid #B2E576',
                borderRadius: '4px',
            }}
            onSubmit={handleSubmit(onIntiateDeposit)}
        >
            {/* <img src="/images/SantimPay.png" className="" alt="Deposit"></img> */}
            <FormControl fullWidth sx={{ m: 1 }}>
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        marginBottom: '10px',
                        borderBottom: '1px solid #B2E576',
                        paddingBottom: '10px',
                    }}
                >
                    Your current account balance is :{' '}
                    <span style={{ fontSize: '17px' }}> {formatNumber(user.balance)} ETB</span>{' '}
                </Typography>

                <Stack direction="row" alignItems="flex-start">
                    <Typography
                        sx={{
                            fontSize: '16px;',
                            fontWeight: '500',
                            position: 'relative',
                            width: '210px',
                            marginTop: '11px',

                            //padding:'10px'
                        }}
                    >
                        REQUEST AMOUNT
                    </Typography>

                    <Box>
                        <Box marginBottom="12px">
                            <TextField
                                aria-describedby="Request Phone"
                                aria-label="Request Phone"
                                className="withdrawTxtFld"
                                sx={{ backgroundColor: '#fff' }}
                                id="outlined-adornment-amount"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+251</InputAdornment>,
                                }}
                                placeholder="Phone No"
                                {...register('phone')}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]*/g, '');
                                }}
                            ></TextField>

                            <Typography variant="body2" color="error">
                                {errors.phone?.message}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <TextField
                                    aria-describedby="Request Amount"
                                    aria-label="Request Amount"
                                    className="withdrawTxtFld"
                                    sx={{ backgroundColor: '#fff' }}
                                    id="outlined-adornment-amount"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">&nbsp;ETB</InputAdornment>,
                                    }}
                                    placeholder="Amount"
                                    {...register('amount')}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]*/g, '');
                                    }}
                                ></TextField>

                                <Typography variant="body2" color="error">
                                    {errors.amount?.message}
                                </Typography>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    disabled={isClicked}
                                    type="submit"
                                    sx={{ fontWeight: '500', ml: 2, paddingTop: '13px', paddingBottom: '13px' }}
                                >
                                    {type}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </FormControl>
        </Box>
    );
};

export default SantimPayForm;
