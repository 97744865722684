import React from 'react';

import { Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function RightAddBanner() {
    const image = {
        alt: 'Side banner',
        //height: 200, // Replace with the actual height of the image
        src: '/images/liveScorebanner.jpg', // Replace with the actual path to your image
        // width: 300, // Replace with the actual width of the image
    };
    return (
        <>
            <Box className="bannerImageRight">
                <LazyLoadImage
                    alt={image.alt}
                    width="250"
                    height="264"
                    // height={image.height}
                    src={image.src} // use normal <img> attributes as props
                    // width={image.width}
                />
                {/* <img src="/images/liveScorebanner.png" alt="bannerImage" className="maxWith100"></img> */}
            </Box>
        </>
    );
}

export default RightAddBanner;
