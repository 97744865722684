import { combineReducers } from 'redux';
import dataReducer from './reducers'; // Import the dataReducer
import socketReducer from './socketReducer';
import sportsBookReducer from './SportBook/reducer';

const rootReducer = combineReducers({
    data: dataReducer, // Assign dataReducer to the 'data' state key
    socket: socketReducer,
    sportsBook: sportsBookReducer
});

export default rootReducer;
