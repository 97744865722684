import { Typography } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import DialogBox from './DialogBox';

const Slots = () => {
    const { user,updateuserBalance } = useContext(UserContext);
    const [open, setopen] = useState(false);
    const [gameId, setGameId] = useState('');
    const handleDialog = (id) => {
        setGameId(id);
        setopen(true);
    };
    useEffect(() => {
        updateuserBalance(user)
    }, [])
    return (
        <>
            <DialogBox open={open} setopen={setopen} gameId={gameId} />
            <Container maxWidth="false">
                {/* {user?.token ? ( */}
                    <Stack
                        className="slotPage"
                        flexDirection="row"
                        flexWrap="wrap"
                        sx={{
                            mb: 2,
                            // mt: 1,
                            // backgroundColor: '#fff',
                            // borderRadius: 2,
                            // border: '1px solid',

                            // borderColor: 'borderBlueColor.main',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2,
                            '& .MuiBox-root': { width: '256px', margin: '5px', position: 'relative', marginBottom: 0 },
                            '& img': {
                                // maxWidth: '256px',
                                width: '254px',
                                height: '144px',
                                borderRadius: '10px',
                                // objectFit: 'cover',
                                border: '1px solid rgba(252, 252, 252, 0)',
                            },
                        }}
                    >
                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(700)}>
                            <img src="/images/slots/solarQueen700.jpeg" alt="solarQueen" />
                        </Box>

                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(701)}>
                            <img src="/images/slots/burningWins701.jpeg" alt="burningWins" />
                            <Typography component="span" class="NewTag Activate">
                                New
                            </Typography>
                        </Box>

                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(702)}>
                            <img src="/images/slots/bookOfGold702.jpeg" alt="bookOfGold" />
                        </Box>

                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(703)}>
                            <img src="/images/slots/bookOfGold703.jpeg" alt="bookOfGold703" />
                        </Box>

                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(704)}>
                            <img src="images/slots/buffaloPower704.jpeg" alt="buffaloPower" />
                        </Box>

                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(705)}>
                            <img src="images/slots/burningWinsTwo705.jpeg" alt="burningWinsTwo" />
                        </Box>

                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(706)}>
                            <img src="/images/slots/diamondFortunator706.jpg" alt="diamondFortunator" />
                        </Box>

                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(707)}>
                            <img src="/images/slots/diamondWins707.jpg" alt="diamondWins" />
                        </Box>
                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(708)}>
                            <img src="/images/slots/eaglePower708.jpg" alt="eaglePower" />
                        </Box>
                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(709)}>
                            <img src="/images/slots/fruits_jokers709.jpg" alt="fruits_jokers" />
                        </Box>
                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(710)}>
                            <img src="/images/slots/fruit_jokers710.jpg" alt="fruit_jokers710" />
                        </Box>
                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(711)}>
                            <img src="/images/slots/hotCoins711.jpg" alt="hotCoins711" />
                        </Box>
                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(712)}>
                            <img src="/images/slots/imperialFruits712.jpg" alt="imperialFruits712" />
                        </Box>
                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(713)}>
                            <img src="/images/slots/jokerCoins713.jpg" alt="jokerCoins713" />
                        </Box>
                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(714)}>
                            <img src="/images/slots/legendOfCleopatra714.jpg" alt="legendOfCleopatra714" />
                        </Box>
                        <Box sx={{}} className="LobbyCard-container" onClick={() => handleDialog(715)}>
                            <img src="/images/slots/royalCoins715.jpg" alt="royalCoins715" />
                        </Box>
                    </Stack>
                {/* ) : (
                    <Typography
                        textAlign="center"
                        marginTop="50px"
                        variant="h3"
                        sx={{ fontSize: '28px', fontWeight: '600' }}
                    >
                        Please Login in to Continue
                    </Typography>
                )} */}
            </Container>
        </>
    );
};

export default Slots;
