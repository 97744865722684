// actions.js
import axios from 'axios';
import config from '../config';
import { ToastError, ToastSuccess } from '../services/helper';

// const API_URL = 'http://192.168.1.4:5001/api';
const API_URL = config.apiUrl;
const API_KEY = config.apiKey;
// const API_URL = 'http://api.onlinegamblingtech.com/api';
// const API_KEY = '35969c8e0fba4';

export const FETCH_TOP_LEAGUES_SUCCESS = 'FETCH_TOP_LEAGUES_SUCCESS';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const FETCH_TOP_LEAGUES_FAILURE = 'FETCH_TOP_LEAGUES_FAILURE';
export const FETCH_TOPBET_SUCCESS = 'FETCH_TOPBET_SUCCESS';
export const FETCH_TOPBET_FAILURE = 'FETCH_TOPBET_FAILURE';
export const ADD_OR_UPDATE_ITEM = 'ADD_OR_UPDATE_ITEM';
export const CHECK_BET_SLIP = 'CHECK_BET_SLIP';
export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ALL_ITEMS = 'DELETE_ALL_ITEMS';
export const UPDATE_ITEM_IN_BETSLIP = 'UPDATE_ITEM_IN_BETSLIP';
export const UPDATE_BETSLIP = 'UPDATE_BETSLIP';
// In actions.js or actionTypes.js
export const UPDATE_BETSLIP_ITEM = 'UPDATE_BETSLIP_ITEM';

export const ADD_ITEM_TO_TAB = 'ADD_ITEM_TO_TAB';
export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB';
export const ADD_ITEM = 'ADD_ITEM';

export const SET_CURRENT_PATH = 'SET_CURRENT_PATH';
export const SET_SPORTBOOK = 'SET_SPORTBOOK';
export const UPDATE_CHECKBOX_VALUE = 'UPDATE_CHECKBOX_VALUE';
export const FETCH_LEAGUE_DATA_REQUEST = 'FETCH_LEAGUE_DATA_REQUEST';
export const FETCH_LEAGUE_DATA_SUCCESS = 'FETCH_LEAGUE_DATA_SUCCESS';
export const FETCH_LEAGUE_DATA_FAILURE = 'FETCH_LEAGUE_DATA_FAILURE';

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const SET_BETSLIP = 'SET_BETSLIP';

export const CHECKBOXS_CHECK_SELECTION = 'CHECKBOXS_CHECK_SELECTION';
// In a separate file, e.g., actionTypes.js
export const SET_CHECKBOX_VALUE = 'SET_CHECKBOX_VALUE';
export const CLEAR_ALL_CHECKBOX_VALUES = 'CLEAR_ALL_CHECKBOX_VALUES';

// Time Filter
export const SET_TIME_FILTER = 'SET_TIME_FILTER';
export const SET_FILTER_DATE_VALUE = 'SET_FILTER_DATE_VALUE';

// Last Minute Odds
export const SET_LAST_MINUTE_ODDS = 'SET_LAST_MINUTE_ODDS';

// Pre Match Odds
export const SET_PRE_MATCH_ODDS = 'SET_PRE_MATCH_ODDS';
export const SET_LIVE_MATCH_ODDS = 'SET_LIVE_MATCH_ODDS';

// for indexDB data set start
export const SET_SPORT_LOCATIONS = 'SET_SPORT_LOCATIONS';
export const SET_LOCATION_LEAGUES = 'SET_LOCATION_LEAGUES';
export const SET_HOME_PAGE_MARKETS = 'SET_HOME_PAGE_MARKETS';
export const SET_FIXTURES_DATA = 'SET_FIXTURES_DATA';
export const SET_FIXTURES_BY_TIMESTAMP_DATA = 'SET_FIXTURES_BY_TIMESTAMP_DATA';
export const FILTER_ON_FIXTURES_DATA = 'FILTER_ON_FIXTURES_DATA';
export const SOCKET_INIT = 'SOCKET_INIT';
export const SYNC_BET_SLIP_ODD = 'SYNC_BET_SLIP_ODD'; 
// for indexDB data set end

// Action Creators
export const socketInit = (data = true) => ({
    type: SOCKET_INIT,
    payload: { data },
});

export const preMatchBetSlipUpdate = (data) => ({
    type: SYNC_BET_SLIP_ODD,
    payload: { data },
});

export const fetchData = async (request_url) => {
    try {
        const response = await axios.get(`${API_URL}${request_url}`, {
            headers: {
                'Content-Type': 'application/json',
                // API_KEY: API_KEY,
                'API-KEY': API_KEY,
            },
        });
        return fetchDataSuccess(response.data);
    } catch (error) {
        return fetchDataFailure(error.message);
    }
};

export const fetchDataRequest = () => ({
    type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
});

export const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error,
});

export const setCheckboxValue = (checkboxName, value) => ({
    type: SET_CHECKBOX_VALUE,
    payload: { checkboxName, value },
});

export const clearAllCheckboxValues = () => {
    return {
        type: CLEAR_ALL_CHECKBOX_VALUES,
    };
};

export const loginUser = (userData) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${API_URL}/login`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                    // API_KEY: API_KEY,
                    'API-KEY': API_KEY,
                },
            });
            ToastSuccess(response.data.message);
            return response.data.user;
        } catch (error) {
            ToastError(error.response.data.message);
            return null;
        }
    };
};

export const registerUser = (userData) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${API_URL}/register`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                    // API_KEY: API_KEY,
                    'API-KEY': API_KEY,
                },
            });
            ToastSuccess(response.data.message);
            return response.data.user;
        } catch (error) {
            ToastError(error.response.data.message);
            return null;
        }
    };
};

export const checkUser = () => {
    return (dispatch) => {
        dispatch(fetchDataSuccess({ username: 'exampleUser' }));
    };
};

export const postDataWithoutAuth = async (request_url, payload) => {
    try {
        const response = await axios.post(`${API_URL}${request_url}`, payload.data, {
            headers: {
                'Content-Type': 'application/json',
                // API_KEY: API_KEY,
                'API-KEY': API_KEY,
            },
        });
        return fetchDataSuccess(response.data);
    } catch (error) {
        return fetchDataFailure(error.message);
    }
};

export const setBetslip = (betslip) => ({
    type: SET_BETSLIP,
    payload: betslip,
});

export const checkboxsCheckSelection = (sportbookCheckboxValues) => ({
    type: CHECKBOXS_CHECK_SELECTION,
    payload: sportbookCheckboxValues,
});
export const updateboxsCheckSelection = (sportbookCheckboxValues, currentItem) => ({
    type: UPDATE_CHECKBOX_VALUE,
    payload: sportbookCheckboxValues,
    currentItem: currentItem,
});
export const fetchLeagueDataRequest = () => ({
    type: 'FETCH_LEAGUE_DATA_REQUEST',
});

export const fetchLeagueDataSuccess = (data) => ({
    type: 'FETCH_LEAGUE_DATA_SUCCESS',
    payload: data,
});

export const fetchLeagueDataFailure = (error) => ({
    type: 'FETCH_LEAGUE_DATA_FAILURE',
    payload: error,
});

export const setSportbooks = (response) => {
    return {
        type: SET_SPORTBOOK,
        payload: response,
    };
};

// Action Creators for treacking path
export const setCurrentPath = (path) => {
    return {
        type: SET_CURRENT_PATH,
        payload: path,
    };
};

export const changeActiveTab = (tabIndex) => {
    return {
        type: 'CHANGE_ACTIVE_TAB',
        payload: tabIndex,
    };
};

export const onChangeActiveTab = (tabIndex) => {
    return {
        type: CHANGE_ACTIVE_TAB,
        payload: tabIndex,
    };
};

export const onDeleteItem = (itemId, tab) => {
    return {
        type: DELETE_ITEM,
        payload: { itemId, tab },
    };
};

export const addOrUpdateItem = (newBet, odd, market, tab) => {
    return {
        type: ADD_OR_UPDATE_ITEM,
        payload: { newBet, odd, market, tab },
    };
};

export const checkBetSlip = (tab, newbetslip) => {
    return {
        type: CHECK_BET_SLIP,
        payload: { currenttab: tab, newbetslip },
    };
};

export const deleteAllItems = (tab) => {
    return {
        type: DELETE_ALL_ITEMS,
        payload: {
            tab,
        },
    };
};

export const fetchTopLeagues = () => async (dispatch) => {
    try {
        const response = await fetchData(`/auth/topleagues/get`);
        const topLeagues = response.payload.data;
        dispatch({ type: FETCH_TOP_LEAGUES_SUCCESS, payload: topLeagues });
    } catch (error) {
        dispatch({ type: FETCH_TOP_LEAGUES_FAILURE, payload: error });
    }
};

export const setActiveTab = (tabId) => ({
    type: SET_ACTIVE_TAB,
    payload: tabId,
});

export const setTimeFilter = (value) => ({
    type: SET_TIME_FILTER,
    payload: value,
});

export const setFilterDateValue = (value) => ({
    type: SET_FILTER_DATE_VALUE,
    payload: value,
});

export const setLastMinuteOdds = (value) => ({
    type: SET_LAST_MINUTE_ODDS,
    payload: value,
});

export const setPreMatchOdds = (preMatchOdds, extra_data) => ({
    type: SET_PRE_MATCH_ODDS,
    payload: { preMatchOdds: preMatchOdds, extra_data: extra_data },
});

export const setLiveMatchOdds = (liveMatchOdds) => ({
    type: SET_LIVE_MATCH_ODDS,
    payload: liveMatchOdds,
});
