import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import rootReducer from './rootReducer';

// Configuration for redux-persist
const persistConfig = {
    key: 'root', // key is required
    storage: storage('myDB'), // storage is required
    whitelist: ['sportsBook'] // only navigation will be persisted
  };

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer,applyMiddleware(thunk));
const persistor = persistStore(store);

export { store, persistor };
