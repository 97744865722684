import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Stack } from '@mui/system';
import React, { useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postDataWithoutAuth } from '../../../services/api';
import { ToastError } from '../../../services/helper';

const SaveTicketModal = ({
    items,
    totalOdds,
    winngAmount,
    betAmount,
    vatAmount,
    actualBetAmount,
    taxAmount,
    text,
    systemSetting,
    checkBetSlipReducer,
    tab,
}) => {
    const [open, setOpen] = useState(false);
    const [pbet_uid, setPbetUid] = useState(0);
    const [isClicked, setIsClicked] = useState(false);
    const [btnText, setBtnText] = useState('Save Ticket');

    const vatPercentage = Number(systemSetting['VAT (%)']);
    const incomeTaxThreshold = Number(systemSetting['Income Tax Threshold']);
    const incomeTaxPercentage = Number(systemSetting['Income Tax (%)']);
    const minimumBetAmount = Number(systemSetting['Minimum Bet Amount']);
    const maximumBetAmount = Number(systemSetting['Maximum Bet Amount']);
    const maximumWinAmount = Number(systemSetting['Maximum Win Amount']);
    const dailyMaxBetAmount = Number(systemSetting['Daily Maximum Bet Amount']);
    const weeklyMaxBetAmount = Number(systemSetting['Weekly Maximum Bet Amount']);
    const monthlyMaxBetAmount = Number(systemSetting['Monthly Maximum Bet Amount']);

    const checkBetSlipAPI = async () => {
        toast.dismiss();
        const betslip = JSON.parse(localStorage.getItem('selectedOdds'));

        try {
            if (betslip?.length > 0) {
                const fetchedData = await postDataWithoutAuth(`/auth/check/odds`, {
                    data: {
                        odds: betslip.map((item) => {
                            const odd =
                                item?.odds && item?.odds?.length > 0
                                    ? item.odds.find((odd) => odd.id === item.oddSelectedId)
                                    : item.Markets.reduce((arr, data) => [...arr, ...data.Odds], []).find(
                                          (odd) => odd.id === item.oddSelectedId
                                      );
                            return {
                                fixture_id: item.id,
                                market_id: item.MarketSelectedId,
                                odd_id: item.oddSelectedId,
                                odd_price: odd.price,
                            };
                        }),
                        isPlaceBet: true,
                    },
                });
                checkBetSlipReducer(tab, fetchedData.data);
                return fetchedData;
            }
        } catch (error) {
            console.log('error ==:>> ', error);
            // ToastError(error?.response?.data?.message);
            return;
        }
    };

    const handleOpen = async () => {
        if (parseFloat(betAmount) < parseFloat(minimumBetAmount)) {
            ToastError(`Stake amount should be minimum ${minimumBetAmount}`);
            // toast.error(`Stake amount should be minimum ${minimumBetAmount}`, {
            //     position: 'top-center',
            //     autoClose: 1000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     theme: 'colored',
            // });
            return false;
        }

        if (parseFloat(betAmount) > parseFloat(winngAmount)) {
            ToastError(`Win must be bigger than stake ${betAmount} ETB`);
            // toast.error(`Win must be bigger than stake ${betAmount} ETB`, {
            //     position: 'top-center',
            //     autoClose: 1000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     theme: 'colored',
            // });
            return false;
        }

        if (betAmount > maximumBetAmount) {
            ToastError(`stake ${betAmount} ETB should be less than ${maximumBetAmount} ETB`);
            // toast.error(`stake ${betAmount} ETB should be less than ${maximumBetAmount} ETB`, {
            //     position: 'top-center',
            //     autoClose: 1000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     theme: 'colored',
            // });
            return false;
        }

        if (winngAmount > maximumWinAmount) {
            ToastError(`possible winning ${winngAmount} ETB should be less than ${maximumWinAmount} ETB`);
            // toast.error(`possible winning ${winngAmount} ETB should be less than ${maximumWinAmount} ETB`, {
            //     position: 'top-center',
            //     autoClose: 1000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     theme: 'colored',
            // });
            return false;
        }

        setIsClicked(true);
        toast.dismiss();
        checkBetSlipAPI()
            .then(async (response) => {
                const { isOddUpdated = false } = response.message;
                if (isOddUpdated) {
                    ToastError('Your odds has changed, Please Confirm!', { autoClose: 3000 });
                    setBtnText('Confirm');
                    return;
                }

                try {
                    let data = {
                        data: {
                            fixture_id: items.map((item) => item.id),
                            stake_amount: betAmount,
                            total_odds: totalOdds,
                            vat_amount: vatAmount,
                            total_amount: actualBetAmount,
                            win_amount: winngAmount,
                            tax_amount: taxAmount,
                            odd_id: items.map((item) => ({
                                fixture_id: item.id,
                                odd_id: item.oddSelectedId,
                                // odd_price: getSelectedOdd(item.oddSelectedId, item.MarketSelectedId, item),
                            })),
                        },
                    };
                    const response = await postDataWithoutAuth('/auth/save/bet', data);
                    setPbetUid(response.data[0].pbet_uid);
                    setOpen(true);
                } catch (error) {
                    console.log('error :>> ', error);
                    ToastError(error.response.data.message);
                } finally {
                    setIsClicked(false);
                    setBtnText('Save Ticket');
                }
            })
            .finally(() => {
                setIsClicked(false);
            });
    };

    const handleClose = () => setOpen(false);
    const image = {
        alt: 'Logo Main',
        //height: 200, // Replace with the actual height of the image
        src: '/images/primeLogo.png', // Replace with the actual path to your image
        // width: 300, // Replace with the actual width of the image
    };
    const titleLogo = useMemo(() => {
        return (
            <>
                <LazyLoadImage
                    className="maxWith100"
                    alt={image.alt}
                    width="106"
                    height="47"
                    rel="preload"
                    as="image"
                    src={image.src}
                />
            </>
        );
    }, [image.src]);

    return (
        <>
            <div>
                <Button
                    aria-label="Open modal"
                    onClick={handleOpen}
                    disabled={isClicked || items.some((item) => item.FixtureStatus === 'non-active')}
                    variant="contained"
                    disableElevation
                    className="saveTktBtn"
                >
                    {isClicked ? <CircularProgress color="inherit" size={22} /> : btnText || text}
                </Button>
                <Modal open={open} onClose={handleClose}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '540px',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                            textAlign: 'center',
                        }}
                    >
                        <Box>
                            <Box className="ModalNewLogo">{titleLogo}</Box>
                            <CheckCircleIcon fontSize="large" color="primary" />
                        </Box>
                        <Typography component="h3" variant="body2" sx={{ textTransform: 'uppercase' }}>
                            Your Bet has been saved
                        </Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ backgroundColor: '#B2E576', mt: 2 }}
                        >
                            {' '}
                            <Typography component="h3" variant="body2" sx={{}}>
                                Temp Ticket Number :
                            </Typography>
                            <Typography component="h1" ml={1} my={0.5} variant="h6" fontWeight="500">
                                #{pbet_uid}
                            </Typography>
                        </Stack>

                        <Link
                            to="#"
                            onClick={() => setOpen(false)}
                            className="popCloseicon"
                            style={{ color: grey[900] }}
                        >
                            <CancelIcon fontSize="medium" />
                        </Link>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default SaveTicketModal;
