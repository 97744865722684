import React from 'react';

import ContentLayout from '../../layout/ContentLayout';

const User = ({ path }) => {
   
    return (
        <>
            <ContentLayout leftSection="profile" selectedTab={path} content={`Middle${path}Tab`} />
        </>
    );
};

export default User;
