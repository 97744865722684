import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { backToPrivious, formatDate } from '../../../../services/helper';
const LiveMatchDetailHeader = ({ liveMatchId, livematchDetails }) => {
    const [value, setValue] = useState(0);

    const handleTabs = (e, val) => {
        setValue(val);
    };

    // console.log('livematchDetails=====', livematchDetails);

    return (
        <>
            <Box className="liveMatchDetailHeader">
                <Stack
                    className=""
                    direction="horizontal"
                    alignItems="center"
                    justifyContent="space-between"
                    backgroundColor="#074652"
                    sx={{ color: '#fff', borderRadius: '8px 8px 0 0' }}
                >
                    <Box display="flex" alignItems="center">
                        <Button
                            aria-label="Back"
                            onClick={() => backToPrivious()}
                            variant="text"
                            sx={{ color: '#fff', padding: '10px 20px' }}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Back
                        </Button>
                        <Typography mr={2}>{formatDate(livematchDetails?.start_date, 'ticketpreview')}</Typography>
                        <Typography>2ND HALF 91 </Typography>
                    </Box>
                    <Box>
                        <Tabs
                            value={value}
                            onChange={handleTabs}
                            sx={{
                                '& .Mui-selected': { color: '#fff !important' },
                                '& .MuiTabs-indicator': { backgroundColor: '#fff' },
                            }}
                        >
                            <Tab label="Score " sx={{ color: '#fff' }} />
                            <Tab label="STATISTICS" sx={{ color: '#fff' }} />
                            <Tab label="Info" sx={{ color: '#fff' }} />
                        </Tabs>
                    </Box>
                </Stack>
                {/* top row ends here */}
                <Box className="detailHeaderContentBox">
                    <TabPanel value={value} index={0}>
                        <Box className="scoreBoardContainer">
                            <div className="scrollTitleGridContainer">
                                <div className="scoreGridItem scoreTitle textAlignLeft">
                                    {' '}
                                    {`${livematchDetails?.league_name}-${livematchDetails?.location_name}`}
                                </div>
                                <div className="scoreGridItem">
                                    {' '}
                                    <img src="/images/sportbook/img3.png" alt="" />
                                </div>
                                <div className="scoreGridItem">{'\u00A0'} </div>
                                <div className="scoreGridItem"> 1</div>
                                <div className="scoreGridItem"> 2</div>
                                <div className="scoreGridItem"> 3</div>
                                {/* {livematchDetails?.Live_score?.periods.map((item, index) => (
                                    <div key={index} className="scoreGridItem">
                                        {index + 1}
                                    </div>
                                ))} */}
                            </div>

                            {/* table content */}

                            <div className="scrollContentRptGrid">
                                <div className="scoreGridContentIten scoreItemWithImage">
                                    <div className="scoreImgDv">
                                        {' '}
                                        <img src="/images/sportbook/ico-tennis-big.png" alt="" />
                                    </div>
                                    <div className="scoreText textAlignLeft">
                                        <span>{livematchDetails?.Participants_home.name}</span>
                                        <span>{livematchDetails?.Participants_away.name}</span>
                                    </div>
                                </div>
                                <div className="scoreGridContentIten"> 0 </div>
                                <div className="scoreGridContentIten">
                                    <div className="scoreWBall scoreGridContentIten ">
                                        <span className="scoreImg">
                                            {' '}
                                            <img src="/images/sportbook/img3.png" alt="" />
                                        </span>
                                        <span className="scoreRound"> 30 </span>
                                    </div>
                                </div>
                                <div className="scoreGridContentIten"> 0</div>
                                <div className="scoreGridContentIten">5</div>
                                <div className="scoreGridContentIten">0</div>
                            </div>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Item two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Item three
                    </TabPanel>
                </Box>
            </Box>
        </>
    );
};
function TabPanel(props) {
    const { children, value, index } = props;
    return <>{value === index && <div>{children}</div>}</>;
}

export default LiveMatchDetailHeader;
