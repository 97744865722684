import {
    Box,
    Button,
    CircularProgress,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';

import { Stack } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../contexts/UserContext';

import { fetchDataWithAuth } from '../../../services/api';
import { ToastError } from '../../../services/helper';

const SantimFailedPayment = () => {
    var deviceHeightn = window.innerHeight - 44;
    const { user, updateBalance } = useContext(UserContext);
    const [transaction, setTransaction] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchDataFromApi = async (storedTransactionId) => {
        toast.dismiss();
        try {
            setLoading(true);
            const fetchedData = await fetchDataWithAuth(`/user/transaction/check/${storedTransactionId}`, user.token);
            console.log('fetchedData.data[0] :>> ', fetchedData.data[0]);
            setTransaction(fetchedData.data[0]);
            updateBalance(fetchedData.data[0].user_balance);
            setLoading(false);
        } catch (error) {
            console.log('error :>> ', error);
            ToastError(error.response.data.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        const storedTransactionId = localStorage.getItem('transactionId');

        if (storedTransactionId) {
            fetchDataFromApi(storedTransactionId);
            localStorage.removeItem('transactionId');
        } else {
            navigate('/');
        }
    }, []);
    return (
        <Container className="content-body">
            <Box component={Paper} className="match-body-selection" style={{ height: `${deviceHeightn}px` }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Box sx={{ width: '600px', margin: '0 auto' }}>
                        <Stack alignItems="center" sx={{ marginBottom: '20px' }}>
                            <img src="/images/failed.png" width="121" height="124" className="" alt="status"></img>
                            <Typography
                                variant="h6"
                                sx={{ color: '#e25b5b', textAlign: 'center', textTransform: 'capitalize' }}
                            >
                                Failed
                                <Typography variant="subtitle2" sx={{ color: '#656565' }}>
                                    Thank you for using our services
                                </Typography>
                            </Typography>
                        </Stack>

                        <>
                            <Paper elevation={0} variant="outlined" className="branchTable">
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Transaction Ref</TableCell>
                                            <TableCell>
                                                <b> {transaction?.tx_ref} </b>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>User Email</TableCell>
                                            <TableCell>
                                                <b>{transaction?.email}</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                            <Button
                                component={Link}
                                to="/"
                                variant="contained"
                                disableElevation
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#5FD3E9',
                                    },
                                    margin: '30px auto 0 auto ',
                                    display: 'block',
                                    width: '180px',
                                }}
                            >
                                Back to home page
                            </Button>
                        </>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default SantimFailedPayment;
