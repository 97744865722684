import { SOCKET_INIT } from "./actions";

const initSocket = {
    isSocketInit : false,
    isSocketConnected: false
}

const socketReducer = (state = initSocket, action) => {
    switch (action.type) {
        case SOCKET_INIT:
            return {
                ...state,
                isSocketInit: action?.payload?.data
            }
        default:
            return state;
    }
}

export default socketReducer