import { Icon } from '@iconify/react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PersonIcon from '@mui/icons-material/Person';
import SavingsIcon from '@mui/icons-material/Savings';
import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { Link } from 'react-router-dom';

const UserSidebar = ({ activeTab }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '16px;',
                        fontWeight: 'bold',
                        mb: 1,
                        textTransform: 'uppercase',
                        p: 2,
                        pb: 0,
                    }}
                >
                    User Profile
                </Typography>

                <Box className="usericonList">
                    <List sx={{ pt: 0 }}>
                        <ListItem
                            color="inherit"
                            component={Link}
                            to="../user/Profile"
                            disableGutters
                            disablePadding
                            className={activeTab === 'Profile' ? 'user_active' : ''}
                        >
                            <ListItemButton
                                aria-label="Profile"
                                disableGutters
                                disablePadding
                                sx={{ paddingLeft: '10px' }}
                            >
                                <ListItemIcon disableGutters disablePadding>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            color="inherit"
                            component={Link}
                            to="../user/DepositsAndWithdrawals"
                            disableGutters
                            disablePadding
                            className={activeTab === 'DepositsAndWithdrawals' ? 'user_active' : ''}
                        >
                            <ListItemButton
                                aria-label="Deposits and withdrawals"
                                disableGutters
                                disablePadding
                                sx={{ paddingLeft: '10px' }}
                            >
                                <ListItemIcon disableGutters disablePadding>
                                    <SavingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Deposits and Withdrawals" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem
                            color="inherit"
                            component={Link}
                            to="../user/Transactions"
                            disableGutters
                            disablePadding
                            className={activeTab === 'Transactions' ? 'user_active' : ''}
                        >
                            <ListItemButton
                                disableGutters
                                aria-label="Transactions"
                                disablePadding
                                sx={{ paddingLeft: '10px' }}
                            >
                                <ListItemIcon disableGutters disablePadding>
                                    <AttachMoneyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Transactions" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem
                            color="inherit"
                            component={Link}
                            to="../user/Tickets"
                            disableGutters
                            disablePadding
                            className={activeTab === 'Tickets' ? 'user_active' : ''}
                        >
                            <ListItemButton
                                aria-label="Tickets"
                                disableGutters
                                disablePadding
                                sx={{ paddingLeft: '10px' }}
                            >
                                <ListItemIcon disableGutters disablePadding>
                                    <ConfirmationNumberIcon />
                                </ListItemIcon>
                                <ListItemText primary="Tickets" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            color="inherit"
                            component={Link}
                            to="../user/VirtualsTicket"
                            disableGutters
                            disablePadding
                            className={activeTab === 'VirtualsTicket' ? 'user_active' : ''}
                        >
                            <ListItemButton
                                aria-label="VirtualsTicket"
                                disableGutters
                                disablePadding
                                sx={{ paddingLeft: '10px' }}
                            >
                                <ListItemIcon disableGutters disablePadding>
                                    <BookOnlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Virtual Tickets" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem
                            color="inherit"
                            component={Link}
                            to="../user/liveTickets"
                            disableGutters
                            disablePadding
                            className={activeTab === 'LiveTickets' ? 'user_active' : ''}
                        >
                            <ListItemButton
                                aria-label="Live tickets"
                                disableGutters
                                disablePadding
                                sx={{ paddingLeft: '10px' }}
                            >
                                <ListItemIcon disableGutters disablePadding sx={{ minWidth: '40px' }}>
                                    <AspectRatioIcon />
                                </ListItemIcon>
                                <ListItemText primary="Live Tickets" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem
                            color="inherit"
                            component={Link}
                            to="/user/support"
                            disableGutters
                            disablePadding
                            className={activeTab === 'Support' ? 'user_active' : ''}
                        >
                            <ListItemButton
                                aria-label="Support"
                                disableGutters
                                disablePadding
                                sx={{ paddingLeft: '10px' }}
                            >
                                <ListItemIcon disableGutters disablePadding sx={{ minWidth: '40px' }}>
                                    <Icon icon="mynaui:support" fontSize="25px" />
                                </ListItemIcon>
                                <ListItemText primary="Support" />
                            </ListItemButton>
                        </ListItem>

                        {/* <ListItem
                            color="inherit"
                            component={Link}
                            to="../user/VirtualTickets"
                            disableGutters
                            disablePadding
                            className={activeTab === 'VirtualTickets' ? 'user_active' : ''}
                        >
                            <ListItemButton disableGutters disablePadding sx={{ paddingLeft: '10px' }}>
                                <ListItemIcon disableGutters disablePadding>
                                    <BookOnlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Virtual tickets" />
                            </ListItemButton>
                        </ListItem> */}

                        {/* <ListItem
                            color="inherit"
                            component={Link}
                            to="../user/DogRaceTickets"
                            disableGutters
                            disablePadding
                            className={activeTab === 'DogRaceTickets' ? 'user_active' : ''}
                        >
                            <ListItemButton disableGutters disablePadding sx={{ paddingLeft: '10px' }}>
                                <ListItemIcon disableGutters disablePadding>
                                    <PetsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dog race tickets" />
                            </ListItemButton>
                        </ListItem> */}
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default UserSidebar;
