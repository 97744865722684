import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const IconImageImage = {
    alt: 'soccers',
    height: 25,
    width: 25,
    src: '/images/tabs/soccer.png', // Replace with the actual path to your image
};

const IconImage2Image = {
    alt: 'soccers',
    height: 25,
    width: 25,
    src: '/images/tabs/tennis.png', // Replace with the actual path to your image
};

const StatsImageImage = {
    alt: 'StatsImage',
    height: 27,
    width: 28,
    src: '/images/statitcs.svg', // Replace with the actual path to your image
};

const LockImageImage = {
    alt: 'Lock',
    height: 25,
    width: 25,
    src: '/images/lock.png', // Replace with the actual path to your image
};

const ClockImageImage = {
    alt: 'ClockImage',
    height: 25,
    width: 25,
    src: '/images/clock.png', // Replace with the actual path to your image
};

const FootballImageImage = {
    alt: 'soccers',
    height: 25,
    width: 25,
    src: '/images/sportbook/img1.png', // Replace with the actual path to your image
};

function IconImage() {
    return (
        <>
            <LazyLoadImage
                alt={IconImageImage.alt}
                height={IconImageImage.height}
                width={IconImageImage.width}
                src={IconImageImage.src} // use normal <img> attributes as props
            />
            {/* <img src="/images/tabs/soccer.png" alt="logo"></img> */}
        </>
    );
}

function IconImage2() {
    return (
        <>
            <LazyLoadImage
                alt={IconImage2Image.alt}
                height={IconImage2Image.height}
                width={IconImage2Image.width}
                src={IconImage2Image.src} // use normal <img> attributes as props
            />
            {/* <img src="/images/tabs/tennis.png" alt="logo"></img> */}
        </>
    );
}

function StatsImage() {
    return (
        <>
            <LazyLoadImage
                alt={StatsImageImage.alt}
                height={StatsImageImage.height}
                width={StatsImageImage.width}
                src={StatsImageImage.src} // use normal <img> attributes as props
            />
            {/* <img src="/images/statIcon.png" alt="logo"></img> */}
        </>
    );
}

function LockImage() {
    return (
        <>
            <LazyLoadImage
                alt={LockImageImage.alt}
                height={LockImageImage.height}
                width={LockImageImage.width}
                src={LockImageImage.src} // use normal <img> attributes as props
            />

            {/* <img src="/images/lock.png" alt=""></img> */}
        </>
    );
}

function ClockImage() {
    return (
        <>
            <LazyLoadImage
                alt={ClockImageImage.alt}
                height={ClockImageImage.height}
                width={ClockImageImage.width}
                src={ClockImageImage.src} // use normal <img> attributes as props
            />
            {/* <img src="/images/clock.png" alt=""></img> */}
        </>
    );
}

// last minute odd images //

function FootballImage() {
    return (
        <>
            <LazyLoadImage
                alt={FootballImageImage.alt}
                height={FootballImageImage.height}
                width={FootballImageImage.width}
                src={FootballImageImage.src} // use normal <img> attributes as props
            />
            {/* <img src="/images/sportbook/img1.png" alt=""></img> */}
        </>
    );
}

export default IconImage;
export { ClockImage, FootballImage, IconImage2, LockImage, StatsImage };
