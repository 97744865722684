import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { setFilterDateValue } from '../../../redux/actions';
import PickerWithButtonField from './PickerWithButtonField';

const SideTimeFilter = ({ timeFilter, setTimeFilter, filterDateValue, setFilterDateValue }) => {
    const handleFilterChange = (option, dateValue) => {
        setTimeFilter(option, dateValue);
        if (option !== 'Date') setFilterDateValue(null);
    };

    return (
        <>
            <Box
                className="timeFilterbx"
                sx={{
                    backgroundColor: 'white.main',
                    borderRadius: 2,
                    mb: 2,
                    p: 2,
                    border: '1px solid',
                    borderColor: 'borderBlueColor.main',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '16px;',
                        fontWeight: 'bold',
                        mb: 1,
                    }}
                >
                    TIME FILTER
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} className="timeFtBtn">
                    <Button
                        aria-label="Today"
                        disableElevation
                        sx={{
                            backgroundColor: 'grey.100',
                            fontSize: '12px',
                            '&:hover': { backgroundColor: '#074652' },
                        }}
                        variant={timeFilter === 'TODAY' ? 'contained' : 'text'}
                        className={timeFilter === 'TODAY' ? 'active' : ''}
                        // style={buttonStyle}
                        color={timeFilter === 'TODAY' ? 'primary' : 'black'}
                        onClick={() => handleFilterChange('TODAY')}
                    >
                        TODAY
                    </Button>
                    <Button
                        aria-label="TOMORROW"
                        disableElevation
                        sx={{
                            backgroundColor: 'grey.100',
                            mx: 1,
                            fontSize: '12px',
                            '&:hover': { backgroundColor: '#074652' },
                        }}
                        variant={timeFilter === 'TOMORROW' ? 'contained' : 'text'}
                        className={timeFilter === 'TOMORROW' ? 'active' : ''}
                        // style={buttonStyle}
                        color={timeFilter === 'TOMORROW' ? 'primary' : 'black'}
                        onClick={() => handleFilterChange('TOMORROW')}
                    >
                        TOMORROW
                    </Button>
                    <Button
                        aria-label="All"
                        disableElevation
                        sx={{
                            backgroundColor: 'grey.100',
                            fontSize: '12px',
                            '&:hover': { backgroundColor: '#074652' },
                        }}
                        variant={timeFilter === 'All' ? 'contained' : 'text'}
                        className={timeFilter === 'All' ? 'active' : ''}
                        // style={buttonStyle}
                        color={timeFilter === 'All' ? 'primary' : 'black'}
                        onClick={() => handleFilterChange('All')}
                    >
                        All
                    </Button>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" className="timeFtBtn" mb={1}>
                    <Button
                        aria-label="A3 HRS"
                        disableElevation
                        sx={{
                            backgroundColor: 'grey.100',
                            fontSize: '12px',
                            '&:hover': { backgroundColor: '#074652' },
                        }}
                        variant={timeFilter === 3 ? 'contained' : 'text'}
                        className={timeFilter === 3 ? 'active' : ''}
                        // style={buttonStyle}
                        color={timeFilter === 3 ? 'primary' : 'black'}
                        onClick={() => handleFilterChange(3)}
                    >
                        3 HRS
                    </Button>
                    <Button
                        aria-label="6 HRS"
                        disableElevation
                        sx={{
                            backgroundColor: 'grey.100',
                            mx: 1,

                            fontSize: '12px',
                            '&:hover': { backgroundColor: '#074652' },
                        }}
                        variant={timeFilter === 6 ? 'contained' : 'text'}
                        className={timeFilter === 6 ? 'active' : ''}
                        // style={buttonStyle}
                        color={timeFilter === 6 ? 'primary' : 'black'}
                        onClick={() => handleFilterChange(6)}
                    >
                        6 HRS
                    </Button>
                    <PickerWithButtonField
                        aria-label="Date"
                        name="Date"
                        // style={buttonStyle}
                        value={filterDateValue}
                        setValue={setFilterDateValue}
                        formattedDate={handleFilterChange}
                    />
                </Box>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => ({
    timeFilter: state.data.timeFilter,
    filterDateValue: state.data.filterDateValue,
});

const mapDispatchToProps = (dispatch) => ({
    setFilterDateValue: (value) => dispatch(setFilterDateValue(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SideTimeFilter);

// export default SideTimeFilter;
