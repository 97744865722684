import { yupResolver } from '@hookform/resolvers/yup';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Button, TextField, Typography } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { postDataWithoutAuth } from '../../../services/api';
import { ToastError, ToastSuccess } from '../../../services/helper';

const schema = Yup.object().shape({
    username: Yup.lazy((value) => {
        if (value !== undefined && value !== '') {
            return Yup.string()
                .matches(/^\S.*$/, 'No starting space allowed')
                .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters are allowed');
        }
        return Yup.string();
    }),
    phone: Yup.lazy((value) => {
        if (value !== undefined && value !== '') {
            return Yup.string()
                .required('Phone Number is required')
                .min(7, 'Please enter minimum 7 numbers')
                .max(12, 'Please enter maximum 12 numbers');
        }
        return Yup.string();
    }),
});

const DminHeight = window.innerHeight * 0.5;

const ForgotPassword = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [country_code, setCountryCode] = useState('');
    const [error_message, setErrorMessage] = useState('');

    const handlePhoneChange = (value, data) => {
        setPhone(value);
        const phone = value ? value.slice(data.dialCode.length) : '';
        const country_code = data.dialCode;
        setValue('phone', phone);
        setCountryCode(country_code);
    };

    const onSubmit = async (values) => {
        toast.dismiss();
        if (username || phone) {
            if ((username.length >= 3 && username.length <= 30) || phone.length) {
                setErrorMessage('');
                const data = {
                    data: {
                        country_code: country_code.length ? country_code : '',
                        username_or_phone: username ? username : values.phone,
                    },
                };
                try {
                    const forgotPassword = await postDataWithoutAuth('/auth/forgot/password', data);
                    if (forgotPassword.status_code === 200) {
                        ToastSuccess(forgotPassword.message);

                        setTimeout(() => {
                            navigate('/');
                        }, 1200);
                    }
                } catch (error) {
                    console.log('forgotPassword ==> ', error);
                    ToastError(error.response.data.message);
                }
            } else {
                if (username.length <= 3) {
                    setErrorMessage(' username must be at least 3 characters');
                } else {
                    setErrorMessage('username must be at most 30 characters');
                }
            }
        } else {
            setErrorMessage('Please Enter Username or Mobile number');
        }
    };

    return (
        <>
            <Container maxWidth="sm" sx={{ minHeight: `${DminHeight}px` }}>
                <Box
                    className="profilePage"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        mb: 2,
                        border: '1px solid',
                        borderColor: 'borderBlueColor.main',
                        p: 2,
                        mt: 4,
                        mb: 50,
                    }}
                >
                    <Box>
                        <Stack direction="row" alignItems="center">
                            <LockOpenIcon color="black" />
                            <Typography
                                color="black"
                                ml={1}
                                sx={{
                                    fontSize: '16px;',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                }}
                            >
                                Recover your password
                            </Typography>
                        </Stack>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Box mt={4}>
                                <TextField
                                    color="black"
                                    name="username"
                                    fullWidth
                                    placeholder="Username"
                                    disableUnderline
                                    label="Username"
                                    type="text"
                                    onFocus={() => {
                                        setPhone('');
                                        setCountryCode('');
                                        clearErrors();
                                        reset();
                                    }}
                                    value={username}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const sanitizedValue = inputValue.replace(/[^A-Za-z0-9]/g, '');
                                        setUsername(sanitizedValue);
                                    }}
                                />
                                <Typography variant="body2" color="error">
                                    {errors.email?.message ? errors.email?.message : error_message}
                                </Typography>
                            </Box>
                            <Typography textAlign="center" sx={{ color: '#ccc' }} my={2}>
                                Or
                            </Typography>

                            <Box>
                                <PhoneInput
                                    color="black"
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true,
                                    }}
                                    variant="outlined"
                                    placeholder="Phone No"
                                    fullWidth
                                    country={'et'}
                                    onChange={handlePhoneChange}
                                    onFocus={() => {
                                        setErrorMessage('');
                                        setUsername('');
                                        clearErrors();
                                        reset();
                                    }}
                                    value={phone}
                                />
                                <Typography variant="body2" color="error">
                                    {errors.phone?.message}
                                </Typography>
                            </Box>
                            <Stack alignItems="flex-end">
                                <Button
                                    aria-label="Restore Password"
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        marginTop: '20px',
                                        fontWeight: '600',
                                        marginBottom: '20px',
                                        paddingLeft: '40px',
                                        paddingRight: '40px',
                                        backgroundColor: '#074652',
                                        color: '#fff',
                                        '&:hover': { backgroundColor: '#074652' },
                                    }}
                                >
                                    Restore Password
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default ForgotPassword;
