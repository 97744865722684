import {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSocket } from '../contexts/SocketContext';
import { clearWebStorageData } from '../../services/helper';

const Notification = (props) => {
    const [open, setOpen] = useState(false);
    const socket = useSocket();

    const { message = "Refresh for new update.", vertical =  'top', horizontal = 'center' } = props;

    const handleRefreshData = () =>{
      localStorage.setItem("isClientNeedToRefresh", false)
      clearWebStorageData()
    }

    useEffect(() => {
      socket.on('clientUpdated',async (flag) => {
        if(flag.data){
          localStorage.setItem("isClientNeedToRefresh", true)
          setOpen(flag.data)
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

    useEffect(() =>{
        if(localStorage.getItem("isClientNeedToRefresh")){
          setOpen(true)
        }
    },[])

  return ReactDOM.createPortal(
    <Snackbar 
        anchorOrigin={{ vertical, horizontal }}
        open={open} 
        message={message} 
        action={
        <>
            <IconButton title='Click Here' color="inherit" onClick={() => handleRefreshData()}>
                <RefreshIcon />
            </IconButton>
        </>
      } />,
    document.body // Render the notification at the end of the document body
  );
};

export default Notification;
