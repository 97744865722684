import React from 'react';
import SearchResultPreMatch from './SearchResultPreMatch';
import SelectionsPreMatch from './SelectionsPreMatch';

const MiddleContentFilteredPreMatch = ({ type }) => {
    if (type === 'searchresult') return <SearchResultPreMatch />;
    else return <SelectionsPreMatch />;
};

export default MiddleContentFilteredPreMatch;
