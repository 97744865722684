import { Icon } from '@iconify/react';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function FootballIcon() {
    return <Icon width={25} height={25} icon="fluent-emoji-flat:soccer-ball" className="sportsIcon" />;
}

export function SquashIcon() {
    const image = {
        alt: 'Squash_Racquet',
        height: 22,
        width: 22,
        src: '/images/sport_thumb/Squash_Racquet.png', // Replace with the actual path to your image
    };
    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/sport_thumb/Squash_Racquet.png" alt="Squash_Racquet" /> */}
        </>
    );
}

export function AustralianRulesIcon() {
    const image = {
        alt: 'aussierules',
        height: 22,
        width: 22,
        src: '/images/sport_thumb/aussierules.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/sport_thumb/aussierules.png" alt="aussierules" /> */}
        </>
    );
}

export function BadmintonIcon() {
    return (
        <img
            loading="lazy"
            src="/images/sport_thumb/badminton.svg"
            height="25"
            width="25"
            alt="Badminton_Shuttlecock"
        />
    );
}

export function BaseballIcon() {
    const image = {
        alt: 'Baseball_Ball',
        height: 22,
        width: 22,
        src: '/images/sport_thumb/Baseball_Ball.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/sport_thumb/Baseball_Ball.png" alt="Baseball_Ball" /> */}
        </>
    );
}

export function BasketballIcon() {
    return <Icon width={25} height={25} icon="streamline-emojis:basketball" className="sportsIcon" />;
}

export function BowlsIcon() {
    const image = {
        alt: 'Bowls_Balls',
        height: 22,
        width: 22,
        src: '/images/sport_thumb/Bowls_Balls.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />
            {/* <img loading="lazy" src="/images/sport_thumb/Bowls_Balls.png" alt="Bowls_Balls" /> */}
        </>
    );
}

export function BoxingIcon() {
    // const image = {
    //     alt: 'Boxing_Gloves',
    //     height: 22,
    //     width: 22,
    //     src: '/images/sport_thumb/Boxing_Gloves.png', // Replace with the actual path to your image
    // };

    // return (
    //     <>
    //         <LazyLoadImage
    //             visibleByDefault={true}
    //             alt={image.alt}
    //             rel="preload"
    //             as="image"
    //             height={image.height}
    //             width={image.width}
    //             src={image.src} // use normal <img> attributes as props
    //         />

    //         {/* <img loading="lazy" src="/images/sport_thumb/Boxing_Gloves.png" alt="Boxing_Gloves" /> */}
    //     </>
    // );

    return <Icon width={25} height={25} icon="noto:boxing-glove" />
}

export function CricketIcon() {
    // const image = {
    //     alt: 'Cricket_Ball',
    //     height: 22,
    //     width: 22,
    //     src: '/images/sport_thumb/Cricket_Ball.png', // Replace with the actual path to your image
    // };

    // return (
    //     <>
    //         <LazyLoadImage
    //             visibleByDefault={true}
    //             alt={image.alt}
    //             rel="preload"
    //             as="image"
    //             height={image.height}
    //             width={image.width}
    //             src={image.src} // use normal <img> attributes as props
    //         />

    //         {/* <img loading="lazy" src="/images/sport_thumb/Cricket_Ball.png" alt="Cricket_Ball" /> */}
    //     </>
    // );

    return <Icon width={25} height={25} icon="bxs:cricket-ball"  style={{'color': '#9e0000'}} />
}

export function DartsIcon() {
    const image = {
        alt: 'Darts_Dart',
        height: 22,
        width: 22,
        src: '/images/sport_thumb/Darts_Dart.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/sport_thumb/Darts_Dart.png" alt="Darts_Dart" /> */}
        </>
    );
}

export function HandballIcon() {
    const image = {
        alt: 'Handball_Ball',
        height: 22,
        width: 22,
        src: '/images/sport_thumb/Handball_Ball.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/sport_thumb/Handball_Ball.png" alt="Handball_Ball" /> */}
        </>
    );
}

export function IceHockeyIcon() {
    return <Icon width={25} height={25} icon="emojione:ice-hockey" className="" />;
}

export function RugbyLeagueIcon() {
    return <Icon width={25} height={25} icon="twemoji:rugby-football" className="" />;
}

export function TennisIcon() {
    return <Icon width={25} height={25} icon="emojione-v1:tennis" className="sportsIcon" />;
}

export function UFC_Gloves() {
    return <Icon width={25} height={25} icon="simple-icons:ufc" className="sportsIcon" />;
}

export function VolleyballIcon() {
    return <Icon width={25} height={25} icon="fluent-emoji:volleyball" className="sportsIcon" />;
}

export function WaterpoloIcon() {
    return <Icon width={25} height={25} icon="icon-park:waterpolo" className="sportsIcon" />;
}

//icons for tab bar

export function Tabsporticon() {
    const image = {
        alt: 'sporticon',
        height: 26,
        width: 26,
        src: '/images/maintab/sporticon.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />
            {/* <img loading="lazy" src="/images/maintab/sporticon.png" alt="sporticon" width="26" height="26" /> */}
        </>
    );
}

export function Tabtoplegueicon() {
    const image = {
        alt: 'Tabtoplegueicon',
        height: 26,
        width: 26,
        src: '/images/maintab/toplegueicon.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/maintab/toplegueicon.png" alt="toplegueicon" width="26" height="26" /> */}
        </>
    );
}

export function Tabverrtualicon() {
    const image = {
        alt: 'Tabverrtualicon',
        height: 26,
        width: 26,
        src: '/images/maintab/verrtualicon.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/maintab/verrtualicon.png" alt="verrtualicon" width="26" height="26" /> */}
        </>
    );
}

// promotional banner//

export function Promobanner1() {
    return (
        <>
            <img src="/images/hero_banners/note.jpg" width="1590" height="392" alt="promobanner1" />
        </>
    );
}

export function Promobanner2() {
    return (
        <>
            <img src="/images/hero_banners/banner1.jpg" width="1590" height="392" alt="promobanner2" />
        </>
    );
}

export function Promobanner3() {
    return (
        <>
            <img src="/images/hero_banners/banner2.jpg" width="1590" height="392" alt="promobanner2" />
        </>
    );
}

export function Promobanner4() {
    return (
        <>
            <img src="/images/hero_banners/banner3.jpg" width="1590" height="392" alt="promobanner2" />
        </>
    );
}

//vertual games//

export function Dograce() {
    const image = {
        alt: 'dograce',
        height: 178,
        width: 161,
        src: '/images/vertual/dograce.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/vertual/dograce.png" width="178" height="161" alt="dograce" /> */}
        </>
    );
}

export function Lucky() {
    const image = {
        alt: 'lucky',
        height: 178,
        width: 161,
        src: '/images/vertual/lucky.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />
            {/* <img loading="lazy" src="/images/vertual/lucky.png" width="178" height="161" alt="lucky" /> */}
        </>
    );
}

export function Football() {
    const image = {
        alt: 'football',
        height: 178,
        width: 161,
        src: '/images/vertual/football.png', // Replace with the actual path to your image
    };

    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/vertual/football.png" width="178" height="161" alt="football" /> */}
        </>
    );
}

export function Spin() {
    const image = {
        alt: 'spin',
        height: 178,
        width: 161,
        src: '/images/vertual/spin.png', // Replace with the actual path to your image
    };
    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/vertual/spin.png" width="178" height="161" alt="spin" /> */}
        </>
    );
}

export function Cricket() {
    const image = {
        alt: 'cricket',
        height: 178,
        width: 161,
        src: '/images/vertual/cricket.png', // Replace with the actual path to your image
    };

    return (
        <>
            {/* <Icon width={25} height={25} icon="bxs:cricket-ball" className="sportsIcon" /> */}
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/vertual/cricket.png" width="178" height="161" alt="cricket" /> */}
        </>
    );
}

export function Tennis() {
    const image = {
        alt: 'tennis',
        height: 178,
        width: 161,
        src: '/images/vertual/tennis.png', // Replace with the actual path to your image
    };
    return (
        <>
            <LazyLoadImage
                alt={image.alt}
                rel="preload"
                as="image"
                height={image.height}
                width={image.width}
                src={image.src} // use normal <img> attributes as props
            />

            {/* <img loading="lazy" src="/images/vertual/tennis.png" width="178" height="161" alt="tennis" /> */}
        </>
    );
}

// detail page icons//

export function Pin() {
    return (
        <>
            <img loading="lazy" src="/images/pin.png" width="10" height="13" alt="pin" />
        </>
    );
}

export function Clock() {
    return (
        <>
            <img loading="lazy" src="/images/clock.png" width="11" height="11" alt="clock" />
        </>
    );
}

export function TableTennisIcon() {
    return <Icon width={25} height={25} icon="fa6-solid:table-tennis-paddle-ball" className="sportsIcon" color="#f93b2b" />;
}

export function Baseball() {
    return <Icon icon="fluent-emoji:baseball" className="sportsIcon" />;
}

export function AussieRules() {
    return <Icon icon="fluent-emoji:rugby-football" className="sportsIcon" />;
}

export function EGamesIcon() {
    return <Icon icon="noto-v1:video-game" className="sportsIcon" />;
}

export function GolfIcon() {
    return <Icon icon="twemoji:man-golfing-medium-light-skin-tone" className="sportsIcon" />;
}

export function HockeyIcon() {
    return <Icon icon="twemoji:field-hockey" className="sportsIcon" />;
}

export function MMAIcon() {
    return <Icon icon="material-symbols:sports-mma-rounded" className="sportsIcon" color="#ff0000" />
}

export function MotorSportsIcon() {
    return <Icon icon="emojione-v1:motorcycle" className="sportsIcon" />;
}

export function WrestlingIcon() {
    return <Icon icon="twemoji:men-wrestling" className="sportsIcon" />
}