import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { SocketProvider } from './components/contexts/SocketContext';
import { UserProvider } from './components/contexts/UserContext';
import {persistor, store} from './redux/store'; // Import your Redux store

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <UserProvider>
                <SocketProvider>
                    <App />
                </SocketProvider>
            </UserProvider> 
        </PersistGate>
    </Provider>
);
