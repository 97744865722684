import crypto from 'crypto-js';
import moment from 'moment/moment';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import FootballIcon, {
    AussieRules,
    AustralianRulesIcon,
    BadmintonIcon,
    Baseball,
    BasketballIcon,
    BowlsIcon,
    BoxingIcon,
    CricketIcon,
    DartsIcon,
    EGamesIcon,
    GolfIcon,
    HandballIcon,
    HockeyIcon,
    IceHockeyIcon,
    MMAIcon,
    MotorSportsIcon,
    RugbyLeagueIcon,
    SquashIcon,
    TableTennisIcon,
    TennisIcon,
    VolleyballIcon,
    WaterpoloIcon,
    WrestlingIcon
} from '../components/Images';
import config from '../config';
import { store } from '../redux/store';
import { convertUtcToLocal } from './localMoment';
import { sortByDate } from './objectManipulation';

// import {pushPathHistory } from '../../src/components/layout/Sidebar/SideTopLeagues';

export const getSportIcon = (sport_name) => {
    let iconComponent = null;

    const sports = [
        { name: 'Soccer', icon: <FootballIcon /> },
        { name: 'Football', icon: <FootballIcon /> },
        { name: 'Basketball', icon: <BasketballIcon /> },
        // { name: 'American Football', icon: <AmericanFootballIcon /> },
        { name: 'Badminton', icon: <BadmintonIcon /> },
        // { name: 'Beach Volleyball', icon: <BeachVolleyballIcon /> },
        { name: 'Rugby Union', icon: <RugbyLeagueIcon /> },
        { name: 'Rugby League', icon: <RugbyLeagueIcon /> },
        { name: 'Rugby', icon: <RugbyLeagueIcon /> },
        { name: 'Aussie-Rules', icon: <AussieRules /> },
        // { name: 'Snooker', icon: <SnookerIcon /> },
        // { name: 'Equine Sports', icon: <EquineSportsIcon /> },
        { name: 'Ice Hockey', icon: <IceHockeyIcon /> },
        { name: 'Cricket', icon: <CricketIcon /> },
        { name: 'Baseball', icon: <Baseball /> },
        { name: 'Australian Rules', icon: <AustralianRulesIcon /> },
        { name: 'Boxing', icon: <BoxingIcon /> },
        { name: 'Tennis', icon: <TennisIcon /> },
        { name: 'Darts', icon: <DartsIcon /> },
        // { name: 'Futsal', icon: <FutsalIcon /> },
        // { name: 'Horse Racing', icon: <HorseRacingIcon /> },
        // { name: 'Formula 1', icon: <Formula1Icon /> },
        { name: 'Golf', icon: <GolfIcon /> },
        // { name: 'Nascar', icon: <NascarIcon /> },
        { name: 'E-Sports', icon: <EGamesIcon /> },
        // { name: 'Supercars', icon: <SupercarsIcon /> },
        // { name: 'Netball', icon: <NetballIcon /> },
        // { name: 'Surfing', icon: <SurfingIcon /> },
        // { name: 'Greyhounds', icon: <GreyhoundsIcon /> },
        // { name: 'Cycling', icon: <CyclingIcon /> },
        // { name: 'Trotting', icon: <TrottingIcon /> },
        // { name: 'Gaelic Sports', icon: <GaelicSportsIcon /> },
        // { name: 'Speedway', icon: <SpeedwayIcon /> },
        // { name: 'Biathlon', icon: <BiathlonIcon /> },
        // { name: 'MotoGP', icon: <MotoGPIcon /> },
        // { name: 'Motorbikes', icon: <MotorbikesIcon /> },
        // { name: 'Chess', icon: <ChessIcon /> },
        // { name: 'Athletics', icon: <AthleticsIcon /> },
        { name: 'Squash', icon: <SquashIcon /> },
        { name: 'Basketball 3X3', icon: <BasketballIcon /> },
        // { name: 'Sumo', icon: <SumoIcon /> },
        // { name: 'Virtual sports', icon: <VirtualSportsIcon /> },
        // { name: 'Politics', icon: <PoliticsIcon /> },
        // { name: 'Weather', icon: <WeatherIcon /> },
        // { name: 'TV-Games', icon: <TVGamesIcon /> },
        // { name: 'Lottery', icon: <LotteryIcon /> },
        { name: 'Bowls', icon: <BowlsIcon /> },
        // { name: 'Poker', icon: <PokerIcon /> },
        // { name: 'Sailing', icon: <SailingIcon /> },
        // { name: 'Hurling', icon: <HurlingIcon /> },
        // { name: 'Ski Jumping', icon: <SkiJumpingIcon /> },
        // { name: 'Indy', icon: <IndyIcon /> },
        // { name: 'Lacrosse', icon: <LacrosseIcon /> },
        // { name: 'Olympics', icon: <OlympicsIcon /> },
        // { name: 'Softball', icon: <SoftballIcon /> },
        // { name: 'Kabaddi', icon: <KabaddiIcon /> },
        // { name: 'AUDL', icon: <AUDLIcon /> },
        // { name: 'Padel', icon: <PadelIcon /> },
        // { name: 'Triathlon', icon: <TriathlonIcon /> },
        // { name: 'Cross-country Skiing', icon: <CrossCountrySkiingIcon /> },
        { name: 'Waterpolo', icon: <WaterpoloIcon /> },
        // { name: 'Alpine Skiing', icon: <AlpineSkiingIcon /> },
        // { name: 'Bandy', icon: <BandyIcon /> },
        // { name: 'Curling', icon: <CurlingIcon /> },
        { name: 'MMA', icon: <MMAIcon /> },
        { name: 'Motorsports', icon: <MotorSportsIcon /> },
        { name: 'Hockey', icon: <HockeyIcon /> },
        // { name: 'Floorball', icon: <FloorballIcon /> },
        { name: 'Handball', icon: <HandballIcon /> },
        { name: 'Volleyball', icon: <VolleyballIcon /> },
        { name: 'Table Tennis', icon: <TableTennisIcon /> },
        { name: 'Wrestling', icon: <WrestlingIcon />}
    ];
    sports.forEach((sport) => {
        if (sport.name === sport_name) {
            iconComponent = sport.icon;
        }
    });
    return iconComponent;
};

export const formatDate = (value, from) => {
    const date = new Date(value);
    // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
    // const day = days[date.getDay()];
    // const hr = date.getHours();
    // let min = date.getMinutes();
    // const sec = date.getSeconds();
    // if (min < 10) {
    //     min = '0' + min;
    // }

    const datenum = date.getDate();
    // const month = months[date.getMonth()];
    const monthnum = Number(date.getMonth()) + 1;
    const year = date.getFullYear();

    if (from === 'datepicker')
        return `${year}-${monthnum < 10 ? `0${monthnum}` : monthnum}-${datenum < 10 ? `0${datenum}` : datenum}`;
    else if (from === 'clockdate') return `${date?.toLocaleDateString(`en-ET`, { timeZone: date.getTimeZone })}`;
    else if (from === 'clocktime')
        return `${date?.toLocaleTimeString(`en-ET`, { timeStyle: 'long', timeZone: date.getTimeZone })}`;
    else if (from === 'livetime')
        return `${date?.toLocaleTimeString(`en-ET`, { timeStyle: 'short', timeZone: date.getTimeZone })}`;
    else if (from === 'ticketpreview')
        return `${date?.toLocaleString(`en-ET`, {
            dateStyle: 'medium',
            timeStyle: 'short',
            timeZone: date.getTimeZone,
        })}`;
    else if (from === 'fixturedate')
        return `${date?.toLocaleDateString(`en-ET`, {
            day: '2-digit',
            month: '2-digit',
            timeZone: date.getTimeZone,
        })}`;
    else if (from === 'fixturedetaildate')
        return `${date?.toLocaleDateString(`en-ET`, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: date.getTimeZone,
        })}`;
    else if (from === 'fixturetime')
        return `${date?.toLocaleTimeString(`en-ET`, { timeStyle: 'short', timeZone: date.getTimeZone })}`;
    else if (from === 'transaction date')
        return `${date
            ?.toLocaleString(`en-ET`, { dateStyle: 'short', timeStyle: 'medium', timeZone: date.getTimeZone })
            .replace(',', '')}`;
    else if (from === 'ticket datetime')
        return `${date
            ?.toLocaleString(`en-ET`, { dateStyle: 'short', timeStyle: 'medium', timeZone: date.getTimeZone })
            .replace(',', '')}`;
};

export function getTimeZone() {
    let offset = new Date().getTimezoneOffset(),
        o = Math.abs(offset);
    return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
}

export const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length !== 0 && Object.values(objectName).filter((item) => item === true).length > 0;
};

export const transformToObjectArray = (data) => {
    const result = [];
    const ordered = Object.keys(data)
        .sort()
        .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
        }, {});
    for (const key in ordered) {
        if (data.hasOwnProperty(key)) {
            const [prefix, sportId, locationId, leagueId] = key.split('_');

            if (prefix === 'subitem') {
                const sport = result.find((item) => item.id === Number(sportId));
                const location = sport?.Locations.find((loc) => loc.id === Number(locationId));

                if (location) {
                    const league = Number(leagueId);
                    // const league = {
                    //     id: Number(leagueId),
                    //     status: data[key],
                    // };

                    location.Leagues.push(league);
                } else {
                    const newLocation = {
                        id: Number(locationId),
                        // status: true,
                        Leagues: [
                            Number(leagueId),
                            // {
                            //     id: Number(leagueId),
                            //     status: data[key],
                            // },
                        ],
                    };

                    sport?.Locations.push(newLocation);
                }
            } else {
                const newSport = {
                    id: Number(prefix),
                    Locations: [
                        {
                            id: Number(sportId),
                            // status: data[key],
                            Leagues: [],
                        },
                    ],
                };

                if (hasKeyValue(result, 'id', prefix) === false) result.push(newSport);
            }
        }
    }

    const cleanedArray = removeDuplicateSportIds(result);
    return cleanedArray;
};

function removeDuplicateSportIds(array) {
    const uniqueSportIds = [];

    return array.filter((obj) => {
        if (!uniqueSportIds.includes(obj.id)) {
            uniqueSportIds.push(obj.id);
            return true;
        }
        return false;
    });
}

function hasKeyValue(array, key, value) {
    return array.some((obj) => obj.hasOwnProperty(key) && obj[key] === value);
}

export const findMatchingObjects = (data1, data2) => {
    const matches = [];


    data1.forEach((item1) => {
        const match = data2.find((item2) => item2.id === item1.id); // sports
        if (match) {
            const locations = item1.Locations.map((location1) => {
                const location2 = match.Locations.find((loc) => loc.id === location1.id);
                if (location2) {
                    const leagues = location1.Leagues.map((league1) => {
                        const league2 = location2.Leagues.find((league) => league.id === league1);
                        return league2 ? { ...league1, ...league2 } : null;
                    });
                    return { ...location2, Leagues: leagues.filter((l) => l !== null) };
                }
                return null;
            });
            matches.push({ ...match, Locations: locations.filter((loc) => loc !== null) });
        }
    });

    return matches;
};

export const findMatchingObjects_new = (selectedSportData, AllSportBookData) => {
    const { updatedLocation, updatedLeague, sports, fixtures, markets } = AllSportBookData;
    const sportBookData = [];

    selectedSportData.forEach((selectedSport) => {
        const selectedSportDetails = sports.find((sport) => sport.id === selectedSport.id); // sports
        if (selectedSportDetails) {
            const locations = selectedSport.Locations.map((selectedLocation) => {
                const selectedLocationDetails = updatedLocation?.[selectedSport.id].find((loc) => loc.id === selectedLocation.id);
                if (selectedLocationDetails) {
                    let leagues = selectedLocation.Leagues.map((selectedLeague) => {
                        const selectedLeagueDetails = updatedLeague[selectedSport.id].find((league) => league.id === selectedLeague);
                        return selectedLeagueDetails ? { ...selectedLeague, ...selectedLeagueDetails } : {};
                    });
                    //debugger
                    leagues = leagues.map((leagueData)=>{
                        let fixturesDataList = fixtures?.[selectedSport?.id]?.filter((fixtureDetails) => fixtureDetails.league_id === leagueData?.id) || []
                        //debugger
                        if(fixturesDataList && fixturesDataList?.length > 0){
                            fixturesDataList = fixturesDataList.map((fd)=> {
                                return {
                                    ...fd,
                                    Markets: markets.map((marketDetail)=> {
                                        return {
                                            ...marketDetail,
                                            Odds: fd.odds.filter((oddDetails) => oddDetails.market_id === marketDetail.id),
                                        }
                                    }),
                                    location_name: selectedLocationDetails.name,
                                    league_name: leagueData.name,
                                    sport_name: selectedSportDetails.name,
                                    Participants_home: fd.participants_home,
                                    Participants_away: fd.participants_away,
                                    sidebets: fd.total_markets
                                }
                            })
                            //debugger
                            leagueData.Fixtures = sortByDate(fixturesDataList)
                            return leagueData
                        }
                        else{
                            return null
                        }
                    })
                    //debugger
                    if(leagues && leagues.length > 0){
                        return { ...selectedLocationDetails, Leagues: leagues.filter((l) => l !== null) };
                    }
                    return null
                }
                return null;
            });
            if(locations && locations?.length > 0){
                sportBookData.push({ ...selectedSportDetails, Locations: locations.filter((loc) => loc !== null) });
            }
        }
    });
    // console.log("sportBookData", sportBookData);
    return sportBookData;
};

export const calculateMinutes = (startDateTime) => {
    let startDate = new Date(startDateTime);
    let currentDate = new Date();

    let startTime = startDate.getTime();
    let currentTime = currentDate.getTime();

    let timeDifference = currentTime - startTime;
    let minutesDifference = Math.floor(timeDifference / (1000 * 60)); // Convert milliseconds to minutes

    return minutesDifference;
};

export const getNext7Days = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date();
    const next7Days = [];

    for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(today.getDate() + i);

        const day = i === 0 ? 'Today' : i === 1 ? 'Tomorrow' : days[date.getDay()];

        next7Days.push({
            dateString: date.toLocaleDateString(`en-ET`, {
                dateStyle: 'medium',
                timeZone: 'Africa/Addis_Ababa',
            }),
            day: day,
            date: formatDate(date, 'datepicker'),
        });
    }

    return next7Days;
};

export const sortObjectArray = (arr, sortby) => {
    arr.sort((a, b) => {
        switch (sortby) {
            case 'SPORT':
                // Sort by sport.name
                if (a.sport.name < b.sport.name) {
                    return -1;
                }
                if (a.sport.name > b.sport.name) {
                    return 1;
                }
                break;
            case 'TIME':
                // Sort by start_date (date part)
                const dateA = new Date(a.start_date).setHours(0, 0, 0, 0);
                const dateB = new Date(b.start_date).setHours(0, 0, 0, 0);
                if (dateA < dateB) {
                    return -1;
                }
                if (dateA > dateB) {
                    return 1;
                }
                break;
            case 'NAME':
                // Sort by name
                if (a.fixture_name < b.fixture_name) {
                    return -1;
                }
                if (a.fixture_name > b.fixture_name) {
                    return 1;
                }
                break;

            default:
                return 0;
        }
        return 0;
    });

    return arr;
};

export const fetchOddsByOption = (data, option) => {
    // Iterate over each object in the data array
    for (const obj of data) {
        // Find the market with the desired option
        const matchodd = obj.Odds.find((odd) => odd.name === option);

        // If a market with the desired option is found, return its Odds
        if (matchodd) {
            return matchodd;
        }
    }

    // If no market with the desired option is found, return null or handle the case as needed
    return null;
};

export const backToPrivious = () => {
    window.history.back();
};

export const getCurrentBetslip = (activeTab) => {
    switch (activeTab) {
        case 0:
            return 'betslip1';
        case 1:
            return 'betslip2';
        case 2:
            return 'betslip3';
        case 'live':
            return 'livebetslip';
        default:
            return 'betslip1';
    }
};

export const UpdateSelectionByTab = (props, pathname, id, activeTab) => {
    // Remove "active" class from all items in .eventContentWraperRow > .oddbg
    const allItems = document.querySelectorAll('.eventContentWraperRow > .oddbg');
    allItems &&
        allItems.forEach((item) => {
            item.classList.remove('active');
            item.querySelector('span').classList.remove('active');
        });

    // Check if there's active data in the current tab
    // Add "active" class to specific items in the .eventContentWraperRow > .oddbg
    // const activeTabItem = props[getCurrentBetslip(activeTab)].find((item) => item.id === id);
    if (pathname.includes('detail') && id) {
        const activeTabItem = props[getCurrentBetslip(activeTab)];
        const getOddSelected =
            activeTabItem && activeTabItem.length > 0
                ? activeTabItem.find((item) => item.id === Number(id)) !== undefined
                    ? document.getElementById(activeTabItem.find((item) => item.id === Number(id)).oddSelectedId)
                    : null
                : null;
        getOddSelected && getOddSelected.classList.add('active');
    } else {
        const activeTabItems = props[getCurrentBetslip(activeTab)];
        activeTabItems &&
            activeTabItems.each((item) => {
                if (document.getElementById(item.oddSelectedId))
                    document.getElementById(item.oddSelectedId).classList.add('active');
            });
    }
};

export const sortMarkets = (markets) => {
    return markets.sort((a, b) => {
        if (a.name === '1X2' && b.name !== '1X2') {
            return -1; // "1X2" comes before other names
        } else if (a.name !== '1X2' && b.name === '1X2') {
            return 1; // Other names come after "1X2"
        } else {
            return 0; // No change in order for markets with the same name
        }
    });
};

export const handleNavigation = (path) => {
    const currentState = store.getState();
    const sportbookCheckboxValues = currentState.data.sportbookCheckboxValues;
    const isAnyCheckboxSelected = Object.values(sportbookCheckboxValues).some((value) => value === true);
    const hasSubitemCheckbox = Object.keys(sportbookCheckboxValues).some((item) => item.includes('subitem'));
    if (window.location.pathname === '/selections' && path !== '/selections') {
        // Allow navigation but don't clear checkbox selections
        window.history.pushState(null, null, path);
    } else if (window.location.pathname !== '/selections' && path === '/selections') {
        // Allow coming back to /selections and show checkbox selections
        window.history.pushState(null, null, path);
    } else if (isAnyCheckboxSelected && hasSubitemCheckbox) {
        // If any checkbox is checked, navigate to /selections
        window.history.pushState(null, null, '/selections');
    }
};

const orderMap = {
    12: ['1', '2'],
    'Next Goal': ['1', 'No Goal', '2'],
    'First Team To Score': ['1', 'No Goal', '2'],
    'Highest Scoring Half': ['1', 'No Goal', '2'],
    'Last Team To Score': ['1', 'No Goal', '2'],
    'Will A Goal Be Scored In Both Halves?': ['Yes', 'No'],
};

const marketOrder = [
    '1X2',
    'Double Chance',
    'Under/Over',
    'Both Teams To Score',
    'Handicaps',
    'Odd/Even',
    'Correct Score'
];

function mergeAndSortOdds(market, nameOrder) {
    const result = [];
    const dataMap = {};

    nameOrder.forEach((name) => {
        const odds = market.Odds.filter((item) => item.name === name);
        dataMap[name] = odds;
    });

    const minLength = Math.min(...nameOrder.map((name) => dataMap[name].length));

    for (let i = 0; i < minLength; i++) {
        nameOrder.forEach((name) => {
            result.push(dataMap[name][i]);
        });
    }

    nameOrder.forEach((name) => {
        result.push(...dataMap[name].slice(minLength));
    });

    market.Odds = result;
}

function customSort(a, b) {
    const indexA = marketOrder.indexOf(a.name);
    const indexB = marketOrder.indexOf(b.name);

    // Check if both market names are exact matches
    if (a.name === b.name) {
        return 0;
    }

    // If one of the market names is an exact match, prioritize it
    if (indexA !== -1 && indexB === -1) {
        return -1;
    } else if (indexA === -1 && indexB !== -1) {
        return 1;
    }

    // If both market names are in the specified order, sort them based on that order
    if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
    }

    // If none of the market names are exact matches, sort them alphabetically
    return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
}

export const sortDetailMarkets = (Markets) => {
    const customSortMarket = Markets.map((market) => {
        const order = orderMap[market.name];

        /*
        1X2
        Double Chance 
        Under/Over
        Both Team to score
        Handicaps
        Odd/Even
        Correct Score
         */

        if (order) {
            market.Odds = market.Odds.slice().sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
        } else if (market.name.includes('Under/Over')) {
            mergeAndSortOdds(market, ['Under', 'Over']);
        } else if (market.name.includes('Under/Exactly/Over')) {
            mergeAndSortOdds(market, ['Under', 'Exactly', 'Over']);
        } else if (market.name.includes('European Handicap')) {
            mergeAndSortOdds(market, ['1', 'X', '2']);
        } else if (market.name.includes('Odd/Even')) {
            mergeAndSortOdds(market, ['Odd', 'Even']);
        } else if (market.name.includes('Double Chance')) {
            mergeAndSortOdds(market, ['1X', 'X2', '12']);
        } else if (
            market.name.includes('Away Team') ||
            market.name.includes('Both Teams') ||
            market.name.includes('Home Team')
        ) {
            mergeAndSortOdds(market, ['Yes', 'No']);
        } else if (market.name.includes('1st Period Race To')) {
            mergeAndSortOdds(market, ['1', 'X', '2']);
        } else if (market.name.includes('HT/FT')) {
            mergeAndSortOdds(market, ['1/1', 'X/1', '2/1', '1/X', 'X/X', '2/X', '1/2', 'X/2', '2/2']);
        } else if (market.name.includes('1X2') || market.name.includes('Period Winner')) {
            mergeAndSortOdds(market, ['1', 'X', '2']);
        }

        return market;
    });

    return customSortMarket.sort(customSort);

};

export const mapOddNameToLabel = (Odd, item) => {
    const oddNameMap = {
        1: item.Participants_home.name,
        X: 'Draw',
        2: item.Participants_away.name,
        '1X': `${item.Participants_home.name}/Draw`,
        X2: `Draw/${item.Participants_away.name}`,
        12: 'No Draw',
        '1/1': `${item.Participants_home.name}/${item.Participants_home.name}`,
        'X/1': `Draw/${item.Participants_home.name}`,
        '2/1': `${item.Participants_away.name}/${item.Participants_home.name}`,
        '1/X': `${item.Participants_home.name}/Draw`,
        'X/X': 'Draw/Draw',
        '2/X': `${item.Participants_away.name}/Draw`,
        '1/2': `${item.Participants_home.name}/${item.Participants_away.name}`,
        'X/2': `Draw/${item.Participants_away.name}`,
        '2/2': `${item.Participants_away.name}/${item.Participants_away.name}`,
    };

    return oddNameMap[Odd.name] || Odd.name;
};

export const UTCAddMinutes = () => {
    const dateTime = moment().add(30, 'minutes');
    return dateTime.toISOString();
};

export const EndOfDayWithTimezone = (day) => {
    const currentUtcDate = moment.utc();
    const endOfDay = currentUtcDate.clone().add(day, 'days').endOf('day');
    return endOfDay.toISOString();
};

export const UTCAddHours = (hours) => {
    const dateTime = moment().add(30, 'minutes').add(hours, 'hours');
    return dateTime.toISOString();
};

export const StartOfDayDateWiseWithTimezone = (date) => {
    const dateIn = moment.utc(moment(date).format('YYYY-MM-DD'));
    const endOfDay = dateIn.clone().subtract(1, 'days').endOf('day');
    return endOfDay.toISOString();
};

export const EndOfDayDateWiseWithTimezone = (date) => {
    const dateIn = moment.utc(moment(date).format('YYYY-MM-DD'));
    const endOfDay = dateIn.clone().endOf('day');
    return endOfDay.toISOString();
};

export const getMarketClass = (marketName) => {
    // Define a mapping of market names to class names
    const marketClassMap = {
        'One on One': 'oneOnOne buttonFlex',
        '1X2': 'one_x_2 buttonFlex',
        12: 'one_or_two buttonFlex',
        'First Period Odd/Even': 'firstPrd_odd_even buttonFlex',
        'First Period Winner': 'firstPrd_Winner buttonFlex',
        'Second Period Odd/Even': 'second_nd_Period_Odd_Even buttonFlex',
        'Second Period Winner': 'second_nd_Period_Winner buttonFlex',
        'Away Team to Score 1st Half': 'Away_Team_to_Score_1st_Half buttonFlex',
        'Correct Score 1st Period': 'Correct_Score_1st_Period buttonFlex',
        'Correct Score 2nd Period': 'Correct_Score_2nd_Period buttonFlex',
        'Double Chance 1st Period': 'Double_Chance_1st_Period buttonFlex',
        'Double Chance 2nd Period': 'Double_Chance_2nd_Period buttonFlex',
        'Next Goal': 'Next_Goal buttonFlex',
        'Odd/Even': 'Odd_Even buttonFlex',
        'Under/Over': 'Under_Over buttonFlex',
        'Under/Over Away Team': 'Under_Over_Away_Team buttonFlex',
        'Under/Over Home Team': 'Under_Over_Home_Team buttonFlex',
        'Under/Over 1st Period': 'Under_Over_1st_Period buttonFlex',
        'Under/Over 1st Period Away Team': 'Under_Over_1st_Period_Away_Team buttonFlex',
        'Under/Over 1st Period Home Team': 'Under_Over_1st_Period_Home_Team buttonFlex',
        'Under/Over 2nd Period': 'Under_Over_2nd_Period buttonFlex',
        'Under/Over 2nd Period Home Team': 'Under_Over_2nd_Period_Home_Team buttonFlex',
    };

    // Check if the market name exists in the map and return the corresponding class name
    if (marketClassMap.hasOwnProperty(marketName)) {
        return marketClassMap[marketName];
    } else {
        // Return a default class name or handle the case when the market name is not found
        return 'vakeelbhai_you_miss_this_market';
    }
};

export const isDecimalWithoutTrailingZeroes = (value) => {
    const variable = Number(value).toFixed(1);
    if (typeof variable === 'number') {
        const stringValue = variable.toString();

        if (stringValue.includes('.')) {
            const decimalPart = stringValue.split('.')[1];
            return decimalPart && decimalPart.match(/0*$/) === null;
        }
    }

    return false;
};

export const removeLinesWithDecimalZero = (data) => {
    // Filter out objects where 'line' does not end with '.0'
    const filteredData = data.filter((item) => !item.line.endsWith('.0'));
    return filteredData;
};

export const compareAndFlagChanges = (currentArray, newArray) => {
  
    const updatedArray = [];

    for (const NewObj of newArray) {
        const matchingObj = currentArray.find((Obj) => Obj.id === NewObj.id);

        if (!matchingObj) {
            updatedArray.push(NewObj);
        } else {
            const { Markets: currentMarkets } = matchingObj;
            const { Markets: newMarkets } = NewObj;

            if (!currentMarkets || !newMarkets) {
                // Handle cases where 'Markets' might not be defined in the objects.
                continue;
            }

            for (const currentMarket of currentMarkets) {
                const matchingMarket = newMarkets.find((market) => market.id === currentMarket.id);

                if (!matchingMarket) {
                    continue;
                }

                for (const currentOdd of currentMarket?.Odds) {
                    const matchingOdd = matchingMarket.Odds.find((odd) => odd.id === currentOdd.id);

                    if (!matchingOdd) {
                        continue;
                    }

                    if (parseFloat(matchingOdd.price).toFixed(2) < parseFloat(currentOdd.price).toFixed(2)) {
                        matchingOdd.is_updated = 'down';
                    } else if (parseFloat(matchingOdd.price).toFixed(2) > parseFloat(currentOdd.price).toFixed(2)) {
                        matchingOdd.is_updated = 'up';
                    }
                }
            }

            updatedArray.push(matchingObj);
        }
    }
    return updatedArray;
};

export const comparePrice = (fixture, oddSelectedId, newPrice) => {
    // Find the odd object in the Odds array by oddSelectedId

    const odd = fixture.Market.Odds.find((item) => item.id === oddSelectedId);
    // Check if the odd object exists and has a price
    if (odd) {
        // Compare the prices
        if (parseFloat(odd.price).toFixed(2) > parseFloat(newPrice).toFixed(2)) {
            return 'down';
        } else if (parseFloat(odd.price).toFixed(2) < parseFloat(newPrice).toFixed(2)) {
            return 'up';
        } else {
            return '';
        }
    }
};

export function convertToUTC(newValue) {
    const start_date = new Date(newValue[0]);
    const endDate = new Date(newValue[1]);
    let startdate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    let enddate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    enddate.setHours(endDate.getHours() + 24);
    const date = {
        start_date: startdate,
        end_date: enddate,
    };
    return date;
}

export const ToastError = (msg) => {
    return toast.error(msg, {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: 'colored',
    });
};

export const ToastSuccess = (msg) => {
    toast.success(msg, {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: 'colored',
    });
};

// export const formatNumber = (number) => {
//     if (number >= 1e6) {
//         // Divide by 1 million and format it to display 1 decimal place followed by 'M'.
//         return (number / 1e6).toFixed(1) + 'M';
//     } else {
//         // For numbers less than 1 million, just format them as regular numbers.
//         return number?.toLocaleString('en-US', { maximumFractionDigits: 2 });
//     }
// };

// Function to generate MD5 hash and encode in Base64
export const generateHash = (params) => {
    // Concatenate all request parameters as a string
    const paramString = Object.keys(params)
        .sort()
        .map((key)=> {
            if (Array.isArray(params[key])) {
                return `${key}=${JSON.stringify(params[key])}`;
            }
            return `${key}=${params[key]}`;
        })
        .join('&')
        .replace('&&', '&');
    // Append the secret key to the parameter string
    const data = paramString + `&secret_key=${config.secretKey}`;
    // Generate MD5 hash
    const hash = crypto.SHA256(data).toString(crypto.enc.Base64);
    // const hash = crypto.createHash('sha256').update(data).digest('base64');

    return hash;
};

export function formatNumber(number) {
    const roundedNumber = Number(number).toFixed(2);
    return numeral(roundedNumber).format('0,0.00');
}

export const getSelectedOdd = (oddSelectedId, MarketSelectedId, fixture) => {
    const oddData = fixture.hasOwnProperty('Market')
        ? fixture.Market?.Odds.find((item) => item.id === oddSelectedId)
        : fixture.Markets?.find((market) => market.id === MarketSelectedId).Odds.find(
              (item) => item.id === oddSelectedId
          );
    return oddData?.price?.toFixed(2);
};


export const timeDiffForApiCall = (prevDate, type = 'ONLY_DATE') => {
    // Get the current date and time
    const currentDate = moment();
    const targetDate = moment(prevDate);

    if (type === 'ONLY_DATE') {
        // Set the time portion to the beginning of the day
        const startOfCurrentDate = currentDate.startOf('day');
        const startOfTargetDate = targetDate.startOf('day');

        // Calculate the difference in days
        const daysDifference = startOfCurrentDate.diff(startOfTargetDate, 'days');

        // Check if the difference is greater than 0 (indicating a different date)
        return daysDifference > 0;
    } else {
        // Calculate the difference in minutes
        const minutesDifference = currentDate.diff(targetDate, 'minutes');

        // Check if the difference is greater than 24 hours or 24 hours in minutes
        return minutesDifference > 1440;
    }
};

export const removePreMatchByBeforeTime = (timestamp) =>{
    const targetTime = convertUtcToLocal(timestamp);
    const currentUtcTime = convertUtcToLocal(moment().utc());
    // Calculate the timestamp threshold (30 minutes ago)
    const thresholdTime = currentUtcTime.clone().add(30, 'minutes');

    // Get the difference in minutes(check whether a thresholdTime is after a particular date(targetTime))
    const isShowData = targetTime.isAfter(thresholdTime);

    // Check if the difference is less than 30 minutes
    if (isShowData) {
        return true
    } else {
        return false
    }
}

export const fixtureFilterByTime = (filterKey = '', fixtureTimestamp, extra_data) =>{
    const currentUtcTime = convertUtcToLocal(moment().utc());
    const itemTime = convertUtcToLocal(fixtureTimestamp);

    // Case 1: Today Data
    function isTodayData() {
        const startOfDay = currentUtcTime.clone().add(30, "minutes"); //startOf("day");
        const endOfDay = currentUtcTime.clone().endOf("day");
        // console.log("isTodayData", startOfDay, endOfDay);
        return itemTime.isBetween(startOfDay, endOfDay, null, "[)");
    }

    // Case 2: 3 Hours Data
    function is3HoursData() {
        const endTime = currentUtcTime.clone().add(3, "hours");
        return itemTime.isBetween(currentUtcTime, endTime, null, "[)");
    }

    // Case 3: 6 Hours Data
    function is6HoursData() {
        const endTime = currentUtcTime.clone().add(6, "hours");
        return itemTime.isBetween(currentUtcTime, endTime, null, "[)");
    }

    // Case 4: Tomorrow Data
    function isTomorrowData() {
        const tomorrow = currentUtcTime.add(1, "day").startOf("day");
        const endOfDayTomorrow = tomorrow.clone().endOf("day");
        return itemTime.isBetween(tomorrow, endOfDayTomorrow, null, "[)");
    }

    // Case 5: Time variation with Current and End Time
    function lastMinuteMatch() {
        const startTime = currentUtcTime.clone().add(30, "minutes");
        const endTime = startTime.clone().add(30, "minutes");
    
        return itemTime.isBetween(startTime, endTime, null, "[)");
    }

    // Case 6: Time variation with Current and End Time
    function preMatchByDays() {
        const startTime = currentUtcTime.clone().add(60, "minutes");
        const endTime = startTime.clone().add(7, "day").endOf("day");
    
        return itemTime.isBetween(startTime, endTime, null, "[)");
    }

    // Case 7: Custom Date Data
    function isCustomDateData(customDate) {
        const startOfDay = convertUtcToLocal(customDate).startOf("day");
        const endOfDay = convertUtcToLocal(customDate).endOf("day");
        return itemTime.isBetween(startOfDay, endOfDay, null, "[)");
    }

    // Switch-case statement
    let flag;
    switch (filterKey) {
    case 'TODAY':
        flag = isTodayData();
        break;

    case '3':
        flag = is3HoursData();
        break;

    case '6':
        flag = is6HoursData();
        break;

    case 'TOMORROW':
        flag = isTomorrowData();
        break;

    case 'LAST_MINUTES':
        flag = lastMinuteMatch();
        break;

    case 'PRE_MATCH_DAYS':
        flag = preMatchByDays();
        break;

    default:
        // 'custom' case
        flag = isCustomDateData(filterKey);
    }

    return flag


}

export const clearWebStorageData = () =>{
    // Clear local storage
   window.localStorage.clear();

   // Clear session storage
   window.sessionStorage.clear();

   // Clear IndexedDB
   const indexedDB = window.indexedDB || window?.mozIndexedDB || window?.webkitIndexedDB || window?.msIndexedDB;
   const request = indexedDB.deleteDatabase('myDB');

   request.onsuccess = () => {
       console.log('IndexedDB successfully deleted');
   };

   request.onerror = (event) => {
       console.error('Error deleting IndexedDB:', event.target.errorCode);
   };

   // Perform a full hard refresh
   window.location.reload(true);
   return true;
}

export const getLocalStorage = (key) => {
    return localStorage.getItem(key);
};

export const setLocalStorage = (key, value) => {
    return localStorage.setItem(key, value);
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const roundto2 = (num) => Math.round(num * 100) / 100;

export const getBetCalculation = (items, vatPercentage, betAmount, incomeTaxThreshold, incomeTaxPercentage) => {
    let totalOdds = items?.reduce((accumulator, object) => {
        return accumulator * getSelectedOdd(object.oddSelectedId, object.MarketSelectedId, object);
    }, 1);
    totalOdds = roundto2(totalOdds);
    // const vatAmount = (vatPercentage / 100) * betAmount;
    // const actualBetAmount = betAmount - vatAmount;
    const taxDivisor = 1 + vatPercentage / 100;
    let actualBetAmount = betAmount / taxDivisor;
    actualBetAmount = roundto2(actualBetAmount);
    let vatAmount = betAmount - actualBetAmount;
    vatAmount = roundto2(vatAmount);
    let winngAmount = totalOdds * actualBetAmount;
    winngAmount = roundto2(winngAmount);
    let taxAmount = winngAmount > incomeTaxThreshold ? (incomeTaxPercentage / 100) * winngAmount : 0;
    taxAmount = roundto2(taxAmount);
    winngAmount = taxAmount > 0 ? winngAmount - taxAmount : winngAmount;
    
    return [totalOdds, vatAmount, actualBetAmount, taxAmount, winngAmount];
}