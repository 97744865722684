import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";

const Virtuals = (props) => {

    const { user,updateuserBalance } = useContext(UserContext);

    useEffect(() => {
        updateuserBalance(user)
    }, [])
    return (
        <div className="vertualIframe">
            <iframe
                title="My iFrame"
                src={props.iFrameSrc}
                style={{ width: '100%', minHeight: '850px', overflowY: 'scroll' }}
                frameBorder="0"
                allowFullScreen
                className="iframe-placeholder"
            ></iframe>
        </div>
    );
};

export default Virtuals;
