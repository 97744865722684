// dataReducer.js
import { isDate } from 'moment';
import { comparePrice, getCurrentBetslip, removePreMatchByBeforeTime } from '../services/helper';
import {
    ADD_ITEM,
    ADD_OR_UPDATE_ITEM,
    CHANGE_ACTIVE_TAB,
    CHECK_BET_SLIP,
    CLEAR_ALL_CHECKBOX_VALUES,
    DELETE_ALL_ITEMS,
    DELETE_ITEM,
    FETCH_DATA_FAILURE,
    FETCH_DATA_REQUEST,
    FETCH_DATA_SUCCESS,
    FETCH_LEAGUE_DATA_FAILURE,
    FETCH_LEAGUE_DATA_REQUEST,
    FETCH_LEAGUE_DATA_SUCCESS,
    FETCH_TOPBET_FAILURE,
    FETCH_TOPBET_SUCCESS,
    FETCH_TOP_LEAGUES_SUCCESS,
    SET_ACTIVE_TAB,
    SET_BETSLIP,
    SET_CHECKBOX_VALUE,
    SET_CURRENT_PATH,
    SET_FILTER_DATE_VALUE,
    SET_LAST_MINUTE_ODDS,
    SET_LIVE_MATCH_ODDS,
    SET_PRE_MATCH_ODDS,
    SET_SPORTBOOK,
    SET_TIME_FILTER,
    SYNC_BET_SLIP_ODD,
    UPDATE_CHECKBOX_VALUE,
    UPDATE_ITEM_IN_BETSLIP,
} from './actions';

const initialState = {
    loading: false,
    items: [],
    sportbook: [],
    intialSportbook: [],
    error: null,
    sportbookCheckboxValues: {},
    currentPath: '/',
    betslip1: [], // This property is defined
    betslip2: [],
    betslip3: [],
    livebetslip: [],
    activeItemIds: {},
    timeFilter: 'All',
    filterDateValue: null,
    activeTab: 0, // Set the active tab initially
    lastMinuteOdds: [],
    preMatchOdds: [],
    preMatchPagging: {},
    liveMatchOdds: [],
    liveMatchPagging: {},
};

const dataReducer = (state = initialState, action) => {
    // console.log('actions', action);
    switch (action.type) {
        case FETCH_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CHANGE_ACTIVE_TAB:
            // Set the active tab index based on the action's payload
            return {
                ...state,
                activeTab: action.payload,
            };
        case SET_SPORTBOOK:
            return {
                ...state,
                sportbookCheckboxValues: {},
                sportbook: action.payload,
                intialSportbook: action.payload,
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload, // Update data with the received payload
                error: null,
            };
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload,
            };
        case SET_CHECKBOX_VALUE:
            return {
                ...state,
                sportbookCheckboxValues: {
                    ...state.sportbookCheckboxValues,
                    [action.payload.checkboxName]: action.payload.value,
                },
            };
        case UPDATE_CHECKBOX_VALUE:
            const allitems = action.payload;
            const sportId = action.currentItem.split('_')[1];
            const locationId = action.currentItem.split('_')[2];

            const subitemsexist =
                Object.keys(allitems).length > 0
                    ? Object.keys(allitems).filter((item) => item.includes(`subitem_${sportId}_${locationId}`))
                    : [];
            if (
                !subitemsexist.length > 0 &&
                allitems.hasOwnProperty(`${sportId}_${locationId}`) &&
                action.currentItem.includes('subitem')
            ) {
                const updatedSportbookCheckboxValuesWithoutUnchecked = { ...allitems };
                delete updatedSportbookCheckboxValuesWithoutUnchecked[`${sportId}_${locationId}`];
                return {
                    ...state,
                    sportbookCheckboxValues: updatedSportbookCheckboxValuesWithoutUnchecked,
                };
            } else {
                return {
                    ...state,
                    sportbookCheckboxValues: action.payload,
                };
            }
        case SET_BETSLIP:
            return {
                ...state,
                [action.payload.tab]: action.payload.betslip,
            };
        case CLEAR_ALL_CHECKBOX_VALUES:
            return {
                ...state,
                sportbookCheckboxValues: {},
                timeFilter: 'All'
            };
        // Assuming you are using FETCH_LEAGUE_DATA_REQUEST for league-specific data fetching
        case FETCH_LEAGUE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_LEAGUE_DATA_SUCCESS:
            return {
                ...state,
                leagueData: action.payload, // Update 'leagueData' with the received payload
                loading: false,
                error: null,
            };
        case FETCH_LEAGUE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SET_CURRENT_PATH: // treacking path with reducer
            return {
                ...state,
                currentPath: action.payload,
                //   pathHistory : action.payload,
            };
        case SYNC_BET_SLIP_ODD:

            for(let betTab = 1; betTab <= 3; betTab++){
                
                const getBetSlip = state[getCurrentBetslip(betTab)];
                const updatedBetslipAfterUpdate = getBetSlip.map((data)=>{
                    const currentFixture = action?.payload?.data?.find((fixtureData) => fixtureData.id === data.id ) //latest odds

                    if(currentFixture?.id){
                        data.odds = currentFixture.odds
                        data.Market.Odds = data.Market?.Odds?.map((mData) => {

                            const currentOdd = currentFixture.odds.find((oddData) => oddData.id === mData.id && oddData.market_id === mData.market_id)
                            return {...mData, ...currentOdd};
                        })
                    }
                    
                    return {
                        ...data,
                        FixtureStatus:(!removePreMatchByBeforeTime(data.start_date)
                        ? 
                        'non-active' 
                        : 
                        ''),
                    }                    
                })
                state[getCurrentBetslip(betTab)] = updatedBetslipAfterUpdate || []
            }

            return{
                ...state,
            }
        case ADD_ITEM:
            return {
                ...state,
                items: [...state.items, action.payload],
            };
        case UPDATE_ITEM_IN_BETSLIP:
            const { item: updatedItem, tab: updateItemTab } = action.payload;
            const betslipToUpdate = state[getCurrentBetslip(updateItemTab)];

            if (!betslipToUpdate) {
                return state; // Tab does not exist in state, do nothing
            }

            // Create a new betslip with updated items
            const updatedBetslipAfterUpdate = betslipToUpdate.map((bet) =>
                bet.id === updatedItem.id
                    ? {
                          ...bet,
                          oddSelectedId: updatedItem.oddSelectedId,
                          MarketSelectedId: updatedItem.MarketSelectedId,
                      }
                    : bet
            );

            // Return the updated state with the modified betslip for the corresponding tab
            return {
                ...state,
                [updateItemTab]: updatedBetslipAfterUpdate,
            };
        case ADD_OR_UPDATE_ITEM:
            const { newBet, odd, market, tab } = action.payload;
            const betslip = state[getCurrentBetslip(tab)];

            if (!betslip) {
                return state; // Tab does not exist in state, do nothing
            }

            const existingBetIndex = betslip.findIndex((bet) => bet.id === newBet.id);
            const marketData = newBet.Markets.find((marketdata) => marketdata.id === market);
            // delete newBet.Markets;
            if (existingBetIndex !== -1) {
                const updatedBetslip = betslip.map((bet, index) =>
                    index === existingBetIndex
                        ? {
                              ...bet,
                              oddSelectedId: odd || bet.oddSelectedId,
                              MarketSelectedId: market,
                              Market: marketData,
                              Markets: null,
                              FixtureStatus: '',
                          }
                        : bet
                );
                return {
                    ...state,
                    [getCurrentBetslip(tab)]: updatedBetslip,
                    activeItemIds: {
                        ...state.activeItemIds,
                        [getCurrentBetslip(tab)]: newBet.id, // Update the active item ID for the tab
                    },
                };
            } else {
                const updatedBetslip = [
                    ...betslip,
                    {
                        ...(newBet || {}),
                        oddSelectedId: odd,
                        MarketSelectedId: market,
                        Market: marketData,
                        Markets: null,
                        FixtureStatus: '',
                    },
                ];

                return {
                    ...state,
                    [getCurrentBetslip(tab)]: updatedBetslip,
                    activeItemIds: {
                        ...state.activeItemIds,
                        [getCurrentBetslip(tab)]: newBet.id, // Update the active item ID for the tab
                    },
                };
            }
        case CHECK_BET_SLIP:
            const { currenttab, newbetslip } = action.payload;
            const currentbetslip = state[getCurrentBetslip(currenttab)];
            const updatedBetslip = currentbetslip.map((fixture) => {
                const newdata = newbetslip.find((betdata) => betdata.fixture_id === fixture.id);
                // console.log(')))))))))))))))))))', comparePrice(fixture, newdata.odd_id, newdata.odd_price));
                const result = newdata ? comparePrice(fixture, newdata.odd_id, newdata.odd_price) : '';
                if (result !== '') {
                    fixture.odds = fixture.odds.map((oddData) => {
                        if(oddData.id === newdata.odd_id){
                            oddData.price = newdata.odd_price;
                        }
                        return oddData;
                    })
                    const marketData = fixture.Market;
                    marketData.Odds = fixture.Market.Odds.map((odd) => {
                        const updatedOdd = odd;
                        if (odd.id === newdata.odd_id) {
                            updatedOdd.price = newdata.odd_price;
                            updatedOdd.is_updated = result;
                        }
                        return updatedOdd;
                    });
                    return {
                        ...fixture,
                        Market: marketData,
                        FixtureStatus:
                            newdata?.fixture_status === 1 && newdata?.fixture_status !== 2 ? 'non-active' : '',
                    };
                } else {
                    return {
                        ...fixture,
                        FixtureStatus:
                            newdata?.fixture_status === 1 && newdata?.fixture_status !== 2 ? 'non-active' : '',
                    };
                }
            });
            return {
                ...state,
                [getCurrentBetslip(currenttab)]: updatedBetslip,
                activeItemIds: {
                    ...state.activeItemIds,
                    [getCurrentBetslip(currenttab)]: newbetslip.id, // Update the active item ID for the tab
                },
            };
        case SET_ACTIVE_TAB:
            const newActiveTab = action.payload;
            return {
                ...state,
                activeTab: newActiveTab, // Update the active tab
            };
        case DELETE_ITEM:
            const { itemId: deleteItemId, tab: deleteItemTab } = action.payload;
            const updatedItemsAfterDelete = state[getCurrentBetslip(deleteItemTab)].filter(
                (item) => item.id !== deleteItemId
            );
            const deleteItemEle = document.getElementById(deleteItemId);
            const elements = deleteItemEle?.querySelectorAll('.eventContentWraperRow>.oddbg');
            elements &&
                elements.forEach((element) => {
                    element.classList.remove('active');
                    element.querySelector('span').classList.remove('active');
                });

            return {
                ...state,
                [getCurrentBetslip(deleteItemTab)]: updatedItemsAfterDelete,
                activeItemIds: {
                    ...state.activeItemIds,
                    [getCurrentBetslip(deleteItemTab)]: null, // Reset the active item ID for the tab
                },
            };
        case DELETE_ALL_ITEMS:
            const { tab: deleteTab } = action.payload;
            const betslipToDelete = state[getCurrentBetslip(deleteTab)];

            // Remove "active" class from elements
            const element = document.querySelectorAll('.eventContentWraperRow>.oddbg');
            element &&
                element.forEach((element) => {
                    element.classList.remove('active');
                    element.querySelector('span').classList.remove('active');
                });

            if (!betslipToDelete) {
                return state; // Tab does not exist in state, do nothing
            }

            // Create a new empty betslip for the corresponding tab
            const updatedBetslipAfterDelete = [];

            // Return the updated state with the modified betslip for the corresponding tab
            return {
                ...state,
                [getCurrentBetslip(deleteTab)]: updatedBetslipAfterDelete,
            };
        case FETCH_TOPBET_SUCCESS:
            return { ...state, topBetData: action.payload, loading: false, error: null };
        case FETCH_TOPBET_FAILURE:
            return { ...state, topBetData: null, loading: false, error: action.payload };
        case FETCH_TOP_LEAGUES_SUCCESS:
            return {
                ...state,
                topLeagues: action.payload,
            };
        case SET_TIME_FILTER:
            const updatedSportbook = state.intialSportbook.filter((sport) => {
                return sport.Locations.some((location) => {
                    return location.Leagues.some((league) => {
                        return league.Fixtures.some((fixture) => {
                            const fixtureStartDate = new Date(fixture.start_date);
                            const timeFilter =
                                action.payload === 'Date' ? new Date(state.filterDateValue) : action.payload;

                            if (timeFilter === 'TODAY' || timeFilter === 'TOMORROW') {
                                const today = new Date();
                                const tomorrow = new Date();
                                tomorrow.setDate(today.getDate() + 1);
                                const startTime = timeFilter === 'TODAY' ? today : tomorrow;
                                startTime.setHours(0, 0, 0, 0);

                                const endTime = startTime;
                                endTime.setDate(startTime.getDate() + 1);

                                return fixtureStartDate >= startTime && fixtureStartDate < endTime;
                            } else if (typeof timeFilter === 'number') {
                                const endTime = fixtureStartDate;
                                endTime.setHours(fixtureStartDate.getHours() + timeFilter);

                                return fixtureStartDate >= new Date() && fixtureStartDate <= endTime;
                            } else if (isDate(timeFilter)) {
                                const startOfDay = new Date(timeFilter);
                                startOfDay.setHours(0, 0, 0, 0);

                                const endOfDay = new Date(startOfDay);
                                endOfDay.setDate(startOfDay.getDate() + 1);

                                return fixtureStartDate >= startOfDay && fixtureStartDate < endOfDay;
                            } else {
                                return fixtureStartDate >= new Date();
                            }
                        });
                    });
                });
            });

            return {
                ...state,
                timeFilter: action.payload,
                sportbook: updatedSportbook,
            };
        case SET_FILTER_DATE_VALUE:
            return {
                ...state,
                filterDateValue: action.payload,
            };
        case SET_LAST_MINUTE_ODDS:
            return {
                ...state,
                lastMinuteOdds: action.payload,
            };
        case SET_PRE_MATCH_ODDS:
            return {
                ...state,
                preMatchOdds: action.payload.preMatchOdds,
                preMatchPagging: action.payload.extra_data,
            };
        case SET_LIVE_MATCH_ODDS:
            return {
                ...state,
                liveMatchOdds: action.payload,
            };
        default:
            return state;
    }
};

export default dataReducer;
