import moment from "moment-timezone";
import { SET_FILTER_VALUE, SET_FIXTURES_DATA, SET_FIXTURES_DATA_BY_FILTER, SET_FIXTURES_DATA_BY_TIMESTAMP, SET_SPORTS_BOOK_DATA } from "./types";
import { setFixturesDataByFilterMutation, setFixturesDataByTimeStampMutation, setFixturesDataMutation } from "./sportBookMutation";

const initialSportsBookState = {
  sports: [],
  sportsTimeStamp: null,
  locations: [],
  updatedLocation: [],
  leagues: [],
  updatedLeague: [],
  markets: [],
  initFixture: [],
  fixtures: [],
  fixturesTimeStamp: null,
  filter: 'All',
};

const sportsBookReducer = (state = initialSportsBookState, action) => {
  switch (action.type) {
    case SET_SPORTS_BOOK_DATA:
      return {
        ...state,
        sports: action.payload.sports,
        locations: action.payload.locations,
        leagues: action.payload.leagues,
        markets: action.payload.markets,
        sportsTimeStamp: moment.utc(),
      };

    case SET_FIXTURES_DATA:
      return setFixturesDataMutation(state, action);

    case SET_FIXTURES_DATA_BY_FILTER:
      return setFixturesDataByFilterMutation(state, action);

    case SET_FIXTURES_DATA_BY_TIMESTAMP:
      return setFixturesDataByTimeStampMutation(state, action);
    
    case SET_FILTER_VALUE:
      return{
        ...state,
        filter: action.payload.data
      }
    default:
      return state;
  }
};

export default sportsBookReducer;
