import { yupResolver } from '@hookform/resolvers/yup';
import { Button, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useForm } from 'react-hook-form';
import { postDataWithoutAuth } from '../../../services/api';
import { ToastError } from '../../../services/helper';
import { UserContext } from '../../contexts/UserContext';
import TicketDetails from '../../pages/User/TicketDetails';
const schema = yup.object().shape({
    // pbet_uid: yup.string().matches(/^[0-9]+$/, 'Only numbers are allowed'),
    // .length(10, 'Must be exactly 10 digits'),
});
const RightCheckTkt = () => {
    const [pbet_uid, setPbetUid] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [btndisabled, setBtnStatus] = useState(true);
    const defaultData = {
        items: [],
        totalOdds: 0.0,
        winningAmount: 0.0,
        betAmount: 0.0,
        vatAmount: 0.0,
        actualBetAmount: 0.0,
        status: 1,
    };
    const [modalData, setModalData] = useState(defaultData);
    const { systemSetting } = useContext(UserContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const handleClose = () => {
        // Close the modal and reset the data
        setIsOpen(false);
        setModalData(defaultData);
        // setPbetUid('');
        // setBtnStatus(true);
    };
    const getTicketDetail = async (BetId) => {
        toast.dismiss();
        let data = {
            data: {
                pbet_uid: pbet_uid,
            },
        };
        try {
            const response = await postDataWithoutAuth(`/auth/ticket/detail`, data);
            setIsOpen(true);
            const ticketObject = {
                items: response.data[0].Fixtures,
                totalOdds: response.data[0].total_odds,
                winningAmount: response.data[0].win_amount,
                betAmount: response.data[0].stake_amount,
                vatAmount: response.data[0].vat_amount,
                taxAmount: response.data[0].tax_amount,
                actualBetAmount: response.data[0].total_amount,
                status: response.data[0].playerbet_status,
                incomeTaxPercentage: Number(systemSetting['Income Tax (%)']),
                vatPercentage: Number(systemSetting['VAT (%)']),
            };

            setModalData(ticketObject);
        } catch (error) {
            console.log('error2 :>> ', error);
            ToastError(error.response.data.message);
        }
    };
    const onHandleChange = async (event) => {
        const inputValue = event.target.value;
        const sanitizedValue = inputValue.replace(/\D/g, '');
        setPbetUid(sanitizedValue);
    };
    return (
        <>
            <TicketDetails isOpen={isOpen} onClose={handleClose} data={modalData} />
            <div className="chkTktHolder">
                {/* <Typography
                    sx={{
                        fontSize: '16px;',
                        fontWeight: 'bold',
                        marginBottom: '0',
                        textTransform: 'uppercase',
                    }}
                >
                    TICKET NUMBER
                </Typography> */}
                <InputLabel
                    sx={{
                        fontSize: '16px;',
                        fontWeight: 'bold',
                        marginBottom: '0',
                        textTransform: 'uppercase',
                    }}
                    htmlFor="Check Ticket"
                >
                    TICKET NUMBER
                </InputLabel>

                <form onSubmit={handleSubmit(getTicketDetail)}>
                    <TextField
                        aria-label="Check Ticket"
                        name="Check Ticket"
                        type="text"
                        id="outlined-basic"
                        className="chkTkt"
                        variant="outlined"
                        fullWidth
                        {...register('pbet_uid')}
                        onChange={onHandleChange}
                        value={pbet_uid}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">BP</InputAdornment>,
                        }}
                    />
                    <Typography variant="body2" color="error">
                        {errors.pbet_uid?.message}
                    </Typography>
                    <Button
                        name="Check Ticket"
                        aria-label="Check Ticket"
                        disableElevation
                        className="btnMrgTop"
                        type="submit"
                        disabled={pbet_uid.length < 10}
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: '#074652',
                            color: '#fff',
                            fontWeight: '600',
                            '&:hover': { backgroundColor: '#074652' },
                        }}
                        // onClick={() => getTicketDetail(pbet_uid)}
                    >
                        Check Ticket
                    </Button>
                </form>
            </div>
        </>
    );
};

export default RightCheckTkt;
