import PropTypes from 'prop-types';
import * as React from 'react';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { formatDate } from '../../../services/helper';


function ButtonField(props) {
    const {
        setOpen,
        label,
        id,
        disabled,
        InputProps: { ref } = {},
        inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    const buttonStyle = {
        fontSize: '11px',
        flexGrow: '1',
        paddingBottom: '5px',
        backgroundColor: '#f5f5f5',
        paddingLeft: '11px',
        paddingRight: '11px',
    };

    return (
        <Button
            name="pick-date"
            disableElevation
            variant={props.value != null ? 'contained' : 'text'}
            className={props.value != null ? 'active calanderBtn' : ''}
            id={id}
            disabled={disabled}
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen?.((prev) => !prev)}
            style={buttonStyle}
            color={props.value != null ? 'primary' : 'black'}
        >
            {label ?? 'Pick a date'}
        </Button>
    );
}

ButtonField.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    inputProps: PropTypes.shape({
        'aria-label': PropTypes.string,
    }),
    InputProps: PropTypes.shape({
        endAdornment: PropTypes.node,
        startAdornment: PropTypes.node,
    }),
    label: PropTypes.node,
    setOpen: PropTypes.func,
};

function ButtonDatePicker(props) {
    const [open, setOpen] = React.useState(false);
    const today = new Date();
    today.setDate(today.getDate() +1);
    return (
        <DatePicker
            slots={{ field: ButtonField, ...props.slots }}
            slotProps={{ field: { setOpen } }}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            // disablePast
            minDate={dayjs(today)}
        />
    );
}

ButtonDatePicker.propTypes = {
    /**
     * Overridable component slots.
     * @default {}
     */
    slots: PropTypes.any,
};

const PickerWithButtonField = ({ value, setValue, formattedDate }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ButtonDatePicker
                label={value == null ? <CalendarMonthIcon></CalendarMonthIcon> : formatDate(value, 'datepicker')}
                value={value}
                onChange={async (newValue) => {
                    setValue(newValue);
                    const start_date = new Date(newValue);
                    // const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
                    formattedDate('Date', start_date);
                }}
            />
        </LocalizationProvider>
    );
};

export default PickerWithButtonField;
