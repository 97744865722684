import { Icon } from '@iconify/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { grey } from '@mui/material/colors';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { io } from 'socket.io-client';
// import { addOrUpdateItem, onDeleteItem } from '../../../../redux/reducers';
import { addOrUpdateItem, checkBetSlip, onDeleteItem } from '../../../../redux/actions';
import {
    getCurrentBetslip,
    getMarketClass,
    mapOddNameToLabel,
    removeLinesWithDecimalZero,
    sortMarkets,
} from '../../../../services/helper';
import { UserContext } from '../../../contexts/UserContext';
import { useSocket } from "../../../contexts/SocketContext";


const LiveMatchMainBets = ({ tab, setLiveMatchId, betslip, state }) => {
    const [liveMatches, setLiveMatches] = useState([]);
    const { user } = useContext(UserContext);
    const params = useParams();
    const socket = useSocket()

    const isSelected = betslip.find((betitem) => betitem.id === liveMatches[0].id)
        ? betslip.find((betitem) => betitem.id === liveMatches[0].id).oddSelectedId
        : null;

    useEffect(() => {
        setLiveMatchId(params);

        const GetInPlayMatch = () => {
            socket.emit('GetInPlayMatch', { room: 'room2', fixture_id: params.id });
        };

        socket.on('GetInPlayMatchData', (fixtures) => {
            fixtures[0].Markets = sortMarkets(fixtures[0]?.Markets);
            setLiveMatches(fixtures);
            checkBetSlip('live', fixtures);
        });

        setInterval(GetInPlayMatch, 1000);
    }, [socket]);
    const getType = (PreMatchMarket) => {
        if (PreMatchMarket.name === 'Under/Exactly/Over') return 'columnThree';
        if (PreMatchMarket.name.includes('Under/Over')) return 'columnTwo';
        if (PreMatchMarket.name.includes('1st Period Race To')) return 'columnThree';
        if (PreMatchMarket.name.includes('HT/FT Including Overtime')) return 'columnThree';
        if (PreMatchMarket.name.includes('European Handicap')) return 'columnThree';
        else if (PreMatchMarket.Odds.length % 2 === 0) return 'columnTwo';
        else if (PreMatchMarket.Odds.length % 3 === 0) return 'columnThree';
        else return 'columnThree';
    };

    const handleAddItem = (item, odd, market) => {
        addOrUpdateItem(item, odd, market, 'live');
    };

    const activeOdd = (target) => {
        const elements = document.getElementById(liveMatches[0]?.id).querySelectorAll('.eventContentWraperRow>.oddbg');
        elements.forEach((element) => {
            element.classList.remove('active');
        });
        target.classList.add('active');
    };
    // console.log('isSelected===live,atchhhh', isSelected);
    return (
        <>
            <Box sx={{ backgroundColor: '#fff' }} mt={1} borderRadius={2} padding={1} className="LiveMatchMainContent">
                <Accordion disableGutters defaultExpanded={true} square={false} sx={{ backgroundColor: grey[200] }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" fontWeight="bolder">
                            All Markets
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box className="oneOnOne" mt="0">
                            {liveMatches[0]?.Markets.map((markets, index) => (
                                <>
                                    {removeLinesWithDecimalZero(markets?.Odds).length ? (
                                        <Accordion disableGutters defaultExpanded={true} disableElevation>
                                            <AccordionSummary
                                                disableElevation
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography variant="body1" fontWeight="bolder">
                                                    {markets?.name}
                                                </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails disableElevation>
                                                <Stack
                                                    direction="row"
                                                    className={`eventContentWraperRow ${getType(
                                                        markets
                                                    )} ${getMarketClass(markets.name)}`}
                                                    flexWrap="wrap"
                                                    mt="5px"
                                                >
                                                    {removeLinesWithDecimalZero(markets.Odds).map(
                                                        (odd, index) =>
                                                            odd.bet_status === 1 ? (
                                                                <Button
                                                                    className={`buttonFlex eventWraperColumn oddbg odsnActive ${
                                                                        isSelected && isSelected === odd.id
                                                                            ? 'active'
                                                                            : ''
                                                                    }`}
                                                                    // variant="text"
                                                                    key={odd.id}
                                                                    sx={{
                                                                        color: grey[900],
                                                                        backgroundColor: '#fff',
                                                                        marginRight: '5px',
                                                                        textTransform: 'none',
                                                                    }}
                                                                    id={odd.id}
                                                                    onClick={(e) => {
                                                                        if (!e.target.classList.contains('active')) {
                                                                            activeOdd(e.target);
                                                                            handleAddItem(
                                                                                liveMatches[0].Markets,
                                                                                odd.id,
                                                                                markets?.id
                                                                            );
                                                                        } else {
                                                                            e.target.classList.remove('active');
                                                                            onDeleteItem(liveMatches[0]?.id, 'live');
                                                                        }
                                                                    }}
                                                                    fullWidth
                                                                >
                                                                    <span className="odTxt">
                                                                        {mapOddNameToLabel(odd, liveMatches[0])}{' '}
                                                                        {odd.line !== '' ? odd.line : ''}
                                                                    </span>
                                                                    <span
                                                                        className="odCnt"
                                                                        style={{
                                                                            fontWeight: 'Bold',
                                                                        }}
                                                                    >
                                                                        {odd.price.toFixed(2)}{' '}
                                                                    </span>
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className={`buttonFlex eventWraperColumn oddbg odsnActive lockedOds ${
                                                                        isSelected && isSelected === odd.id
                                                                            ? 'active'
                                                                            : ''
                                                                    }`}
                                                                    variant="text"
                                                                    sx={{
                                                                        color: grey[900],
                                                                        backgroundColor: '#fff',
                                                                        marginRight: '5px',
                                                                        textTransform: 'none',
                                                                    }}
                                                                    id={odd.id}
                                                                    fullWidth
                                                                >
                                                                    <span className="odTxt">
                                                                        {mapOddNameToLabel(odd, liveMatches[0])}{' '}
                                                                        {odd.line !== '' ? odd.line : ''}
                                                                    </span>
                                                                    <span
                                                                        className="odCnt"
                                                                        style={{
                                                                            fontWeight: 'Bold',
                                                                        }}
                                                                    >
                                                                        <span className="odsEmptySpan">
                                                                            <Icon icon="material-symbols:lock" />
                                                                        </span>
                                                                    </span>
                                                                </Button>
                                                            )

                                                        // <div
                                                        //     key={odd.id}
                                                        //     className={`buttonFlex eventWraperColumn oddbg odsnActive lockedOds ${
                                                        //         isSelected && isSelected === odd.id ? 'active' : ''
                                                        //     }`}
                                                        //     id={odd.id}
                                                        // >
                                                        //     <span className="odTxt">
                                                        //         {mapOddNameToLabel(odd, liveMatches[0])}
                                                        //         {odd.line !== '' ? odd.line : ''}
                                                        //     </span>
                                                        //     <span
                                                        //         className="odCnt"
                                                        //         style={{
                                                        //             fontWeight: 'Bold',
                                                        //         }}
                                                        //     >
                                                        //         <span className="odsEmptySpan">
                                                        //             <Icon icon="material-symbols:lock" />
                                                        //         </span>
                                                        //     </span>
                                                        // </div>
                                                        // )
                                                    )}
                                                    {/* <Button
                                                            className="buttonFlex eventWraperColumn oddbg odsnActive"
                                                            variant="text"
                                                            sx={{
                                                                color: grey[900],
                                                                backgroundColor: '#fff',
                                                                marginRight: '5px',
                                                                textTransform: 'none',
                                                            }}
                                                            fullWidth
                                                        >
                                                            <span>Draw</span>
                                                            <span> 2.15 </span>
                                                        </Button>
                                                        <Button
                                                            className="buttonFlex eventWraperColumn oddbg odsnActive"
                                                            variant="text"
                                                            sx={{
                                                                color: grey[900],
                                                                backgroundColor: '#fff',
                                                                marginRight: '5px',
                                                                textTransform: 'none',
                                                            }}
                                                            fullWidth
                                                        >
                                                            <span>Montpellier</span>
                                                            <span> 2.15 </span>
                                                        </Button> */}
                                                </Stack>
                                                {/* stakc 2 row */}
                                                {/* <Stack direction="horizontal" mt="5px">
                                                        <Button
                                                            className="buttonFlex eventWraperColumn oddbg odsnActive "
                                                            variant="text"
                                                            sx={{
                                                                color: grey[900],
                                                                backgroundColor: '#fff',
                                                                marginRight: '5px',
                                                                textTransform: 'none',
                                                            }}
                                                            fullWidth
                                                        >
                                                            <span>Wong,Hong Kit</span>
                                                            <span> 11.50</span>
                                                        </Button>
                                                        <Button
                                                            className="buttonFlex eventWraperColumn oddbg odsnActive"
                                                            variant="text"
                                                            sx={{
                                                                color: grey[900],
                                                                backgroundColor: '#fff',
                                                                marginRight: '5px',
                                                                textTransform: 'none',
                                                            }}
                                                            fullWidth
                                                        >
                                                            <span>Vashleborn Eric</span>
                                                            <span> 1.01 </span>
                                                        </Button>
                                                    </Stack> */}
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => ({
    tab: state.data.activeTab,
    state: state.data,
    betslip: state.data[getCurrentBetslip(state.data.activeTab)],
});

const mapDispatchToProps = (dispatch) => ({
    addOrUpdateItem: (item, odd, market, tab) => {
        dispatch(addOrUpdateItem(item, odd, market, tab));
    },
    onDeleteItem: (itemId, tab) => {
        dispatch(onDeleteItem(itemId, tab));
    },
    checkBetSlip: (tab, newbetslip) => dispatch(checkBetSlip(tab, newbetslip)),
    // ... Other mapDispatchToProps functions
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveMatchMainBets);
// export default LiveMatchMainBets;
