import moment from 'moment-timezone'

export const convertUtcToLocal = (utcDateString) => {
  // Get the system's local timezone
  const systemTimezone = moment.tz.guess();

  // Create a moment object in UTC
  const utcMoment = moment.utc(utcDateString);

  // Convert to system's local timezone
  const localMoment = utcMoment.clone().tz(systemTimezone);

  return localMoment;
}