import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import CachedIcon from '@mui/icons-material/Cached';
import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Box, Button, Container, IconButton, Stack, TextField, Typography } from '@mui/material';
import { default as Grid, default as Item } from '@mui/material/Unstable_Grid2';
import { default as React, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { loginUser } from '../../services/api';
import { formatNumber } from '../../services/helper';
import { UserContext } from '../contexts/UserContext';

const schema = yup.object().shape({
    username: yup
        .string()
        .required('Username is required')
        .matches(/^\S.*$/, 'No starting space allowed'),
    password: yup
        .string()
        .required('Password is required')
        .matches(/^\S.*$/, 'No starting space allowed'),
});

const TopHeader = ({ loggedIn, setUser }) => {
    const image = {
        alt: 'Logo Main',
        width: 106,
        height: 72,
        src: '/images/primeLogo.png', // Replace with the actual path to your image
    };
    const location = useLocation();
    const { user, login, logout, updateuserBalance } = useContext(UserContext);
    const [hide, setHide] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        setDisableBtn(true);
        toast.dismiss();
        let userData = await loginUser('/auth/login', data, setDisableBtn);

        if (userData !== null) {
            setTimeout(() => {
                setDisableBtn(false);
                reset();
                localStorage.setItem('userData', JSON.stringify(userData));
                login(JSON.parse(localStorage.getItem('userData')));
            }, 700);
            setHide(false);
        } else {
            setHide(true);
        }
    };

    const RefreshBalance = async () => {
        setIsClicked(true);
        const data = await updateuserBalance(user);
        setIsClicked(false);
    };
    if (user)
        return (
            <>
                <Box backgroundColor="" py={1} sx={{ backgroundColor: 'customPrimary.main' }}>
                    <Container maxWidth={false} className="ContainerBig">
                        <Grid direction="row" spacing={2} alignItems="center">
                            <Grid container alignItems="center">
                                <Grid xs={1}>
                                    <Item>
                                        <Link
                                            to="/"
                                            style={{
                                                color: 'inherit',
                                                textDecoration: 'inherit',
                                            }}
                                            aria-label="IDEMO SPORT"
                                        >
                                            <LazyLoadImage
                                                className="logoWidth"
                                                alt={image.alt}
                                                rel="preload"
                                                as="image"
                                                height={image.height}
                                                width={image.width}
                                                src={image.src} // use normal <img> attributes as props
                                            />

                                            {/* <img src="/images/primeLogo.png" className="logoWidth" alt="logo"></img> */}
                                        </Link>
                                    </Item>
                                </Grid>
                                <Grid xs={8} ml="auto">
                                    <Item display="flex" alignItems="center">
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{
                                                backgroundColor: '#fff',
                                                borderRadius: '6px',
                                                marginRight: '10px',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                            }}
                                        >
                                            {' '}
                                            <Box>
                                                <Button
                                                    aria-label="username"
                                                    className="buttonDevider b1"
                                                    size="large"
                                                    variant="text"
                                                    startIcon={<PermIdentityIcon />}
                                                    sx={{ color: '#000', textTransform: 'none' }}
                                                    component={Link}
                                                    to="/user/profile"
                                                >
                                                    {user.username}
                                                </Button>
                                            </Box>
                                            {location.pathname !== '/slot' &&
                                                location.pathname !== '/virtuals' &&
                                                location.pathname !== '/aviator' && (
                                                    <>
                                                        <Box>
                                                            <Button
                                                                aria-label="User Balance"
                                                                className="buttonDevider b2"
                                                                variant="text"
                                                                color="black"
                                                                sx={{ textTransform: 'none' }}
                                                                component={Link}
                                                                to="/user/transactions"
                                                            >
                                                                {formatNumber(user.balance)}{' '}
                                                                <span className="priceExr">ETB</span>
                                                                {/* {user.balance} <span className="priceExr">ETB</span> */}
                                                            </Button>
                                                        </Box>
                                                        <Box>
                                                            <Button
                                                                aria-label="Price"
                                                                className="buttonDevider b3"
                                                                variant="text"
                                                                color="black"
                                                                sx={{ textTransform: 'none' }}
                                                            >
                                                                <IconButton
                                                                    className={isClicked ? 'clicked' : ''}
                                                                    sx={{ padding: '0' }}
                                                                    onClick={RefreshBalance}
                                                                >
                                                                    {!isClicked ? (
                                                                        <CachedIcon color="black" />
                                                                    ) : (
                                                                        <Icon
                                                                            fontSize="20px"
                                                                            icon="line-md:loading-twotone-loop"
                                                                            color="black"
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </Button>
                                                        </Box>
                                                    </>
                                                )}
                                        </Stack>

                                        {/* <Clock /> */}
                                        <Box>
                                            <IconButton
                                                aria-label="Logout"
                                                size="small"
                                                color="black"
                                                onClick={() => logout()}
                                            >
                                                <LogoutIcon />
                                            </IconButton>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </>
        );
    return (
        <>
            <Box backgroundColor="" py={1} sx={{ backgroundColor: 'customPrimary.main' }}>
                <Container maxWidth={false} className="ContainerBig">
                    <Grid container alignItems="center">
                        <Grid xs={1}>
                            <Item>
                                <Link
                                    to="/"
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'inherit',
                                    }}
                                    aria-label="IDEMO SPORT"
                                >
                                    <LazyLoadImage
                                        className="logoWidth"
                                        alt={image.alt}
                                        rel="preload"
                                        as="image"
                                        width={image.width}
                                        height={image.height}
                                        src={image.src} // use normal <img> attributes as props
                                    />
                                    {/* <img src="/images/primeLogo.png" className="logoWidth" alt="logo"></img> */}
                                </Link>
                            </Item>
                        </Grid>

                        <Grid display="flex" justifyContent="flex-end" ml="auto">
                            <Item>
                                <Stack>
                                    <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={1}>
                                        <Button
                                            variant="text"
                                            component={Link}
                                            to="/forgotpassword"
                                            sx={{ color: 'black.main', textDecoration: 'none' }}
                                        >
                                            Forgot Password?
                                        </Button>
                                        <Button
                                            aria-label="Register"
                                            component={Link}
                                            to="/registration"
                                            variant="contained"
                                            size="small"
                                            disableElevation
                                            sx={{
                                                ml: 2,
                                                backgroundColor: '#074652',
                                                color: '#fff',
                                                '&:hover': { backgroundColor: '#074652' },
                                            }}
                                        >
                                            Register
                                        </Button>
                                    </Stack>

                                    <Stack
                                        component="form"
                                        direction="row"
                                        onSubmit={handleSubmit(onSubmit)}
                                        alignItems="center"
                                    >
                                        <Box sx={{ marginRight: '10px' }}>
                                            <Typography
                                                variant="body2"
                                                color="black"
                                                sx={{ textAlign: 'left', fontSize: '12px', fontWeight: '500' }}
                                            >
                                                {errors.username?.message}
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                sx={{ textAlign: 'left', fontSize: '12px', fontWeight: '500' }}
                                                color="black"
                                            >
                                                {errors.password?.message}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <TextField
                                                {...register('username')}
                                                autoFocus
                                                type="text"
                                                name="username"
                                                sx={{
                                                    backgroundColor: 'white.main',
                                                    px: 2,
                                                    py: 0.5,
                                                    borderRadius: 1.2,
                                                    autoComplete: 'new-password',
                                                }}
                                                variant="standard"
                                                placeholder="Username"
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Box>

                                        <Box mx={1}>
                                            <TextField
                                                {...register('password')}
                                                type="password"
                                                name="password"
                                                sx={{
                                                    backgroundColor: 'white.main',
                                                    px: 2,
                                                    py: 0.5,
                                                    borderRadius: 1.2,
                                                }}
                                                variant="standard"
                                                placeholder="Password"
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Box>

                                        <Button
                                            aria-label="Login"
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                            disabled={disableBtn}
                                            sx={{ paddingLeft: '20px', paddingRight: '20px' }}
                                            disableElevation
                                        >
                                            Login
                                        </Button>
                                    </Stack>
                                </Stack>

                                {/* <AfterLoginBar loggedIn={loggedIn} user={user} setUser={setUser} /> */}
                            </Item>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default TopHeader;
