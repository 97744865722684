import { CircularProgress, Typography } from '@mui/material';

import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastError } from '../../../services/helper';
import FilteredPreMatchOddsGriditem from './FilteredPreMatchOddsGriditem';
import { getFixtureByTextSearch } from '../../../redux/SportBook/sportBookMutation';
import { connect } from 'react-redux';

const SearchResultPreMatch = ({AllSportBookData}) => {
    const { initFixture, leagues, locations, sports, markets } = AllSportBookData
    const params = useParams();
    const [FilteredPreMatchOddsData, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        toast.dismiss();
        const fetchDataFromApi = async () => {
            try {
                setLoading(true); // Set loading before sending API request
                let searchData = await getFixtureByTextSearch({filter: params.searchText, initFixture, leagues, locations, sports, markets})
                setData(searchData);
                setLoading(false); // Stop loading
            } catch (error) {
                console.log('error2 :>> ', error);
                ToastError(error.response.data.message);
            }
        };
        fetchDataFromApi();
    }, [params.searchText]);
    return (
        <>
            <Box p={1}>
                <Box
                    variant="h6"
                    fontSize="15px"
                    p={1}
                    backgroundColor={grey[900]}
                    color="#fff"
                    display="flex"
                    alignItems="center"
                >
                    <span>
                        {FilteredPreMatchOddsData?.length || 0} RESULTS FOR{'   '}
                        <span style={{ color: '#efd63c', textTransform: 'uppercase' }}>"{params.searchText}"</span>
                    </span>
                </Box>
                {/* top title end */}
                <div className="scoreTableWrapper">
                    {loading ? (
                        <CircularProgress disableShrink />
                    ) : FilteredPreMatchOddsData.length ? (
                        FilteredPreMatchOddsData.map((item, index) => (
                            <FilteredPreMatchOddsGriditem key={index} item={item} />
                        ))
                    ) : (
                        <Typography>No Match found</Typography>
                    )}
                </div>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => ({
    AllSportBookData: state.sportsBook,
});

const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchResultPreMatch);
